import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

type ApiCallStateType<T, D = T> = {
  isLoading: boolean;
  error?: Error | AxiosError | { message: string; statusCode: number; path: string; timestamp: string } | null;
  data?: D;
};

type UseApiDataOptions<T, D = T, G = unknown> = {
  reFetchApi: (args?: G) => Promise<void>;
  lazyApiCall: (args?: G) => Promise<void>;
  resetApiCallState: () => void;
  state: ApiCallStateType<T, D>;
};

const useApiState = <T, D = T, G = unknown>({
                                              apiCall,
                                              mappingFn,
                                              isLazy = false
                                            }: {
  apiCall: (args?: G) => Promise<T>;
  mappingFn?: (data: T) => D;
  isLazy?: boolean;
}): UseApiDataOptions<T, D, G> => {
  const initialState = {
    data: undefined,
    isLoading: false,
    error: undefined
  };
  const [state, setState] = useState<ApiCallStateType<T, D>>(initialState);

  const resetApiCallState = () => setState(initialState);

  const apiCallHandler = async (args?: G) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      error: undefined,
      data: undefined
    }));

    try {
      let response: T;

      if (args) {
        response = await apiCall(args);
      } else {
        response = await apiCall();
      }
      const newData = mappingFn ? mappingFn(response) : response;

      setState((prevState) => ({ ...prevState, data: newData as D }));
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data) {
        // Capture the API error response
        const errorData = err.response.data as {
          message: string;
          statusCode: number;
          path: string;
          timestamp: string;
        };
        setState((prevState) => ({
          ...prevState,
          error: errorData
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          error: new Error('generic_error_message')
        }));
      }
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (!isLazy) {
      apiCallHandler();
    }
  }, []);

  return {
    state,
    reFetchApi: apiCallHandler,
    lazyApiCall: apiCallHandler,
    resetApiCallState
  };
};

export { useApiState };

import React from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useStatus from '../../../hooks/useStatus';

const InvoiceTypeForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Accounting');

  return (
    <>
      <CustomFormField
        name={'name'}
        label={`اسم نوع الفاتورة`}
        control={control}
        errors={errors}
        type={'text'}
        placeholder={`اسم نوع الفاتورة`}
        required={true}
      />

      <CustomFormField
        name={'code'}
        label={`كود نوع الفاتورة`}
        control={control}
        errors={errors}
        type={'text'}
        placeholder={`كود نوع الفاتورة`}
        required={true}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة نوع الفاتورة`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default InvoiceTypeForm;

import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import useFetch from '../../../hooks/useFetch';
import moment from 'moment';
import Receipt from '../Receipt/Receipt';

const AccountTransactionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transId } = location.state || {};
  const printRef = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState(false); // Control receipt visibility

  const { data: transData, isLoading: transIsLoading } = useFetch(`/AccountingTransaction/${transId}`);
  const handlePrint = () => {
    setIsPrinting(true); // Show the receipt
    setTimeout(() => {
      if (printRef.current) {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(); // Reload to reset the app state
      }
    }, 0); // Slight delay to ensure rendering before printing
  };
  return (
    <>
      {/* ======================================================the header of page ================================================= */}

      <div className='detailsheet__header'>
        <div className='detailsheet__header__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='detailsheet__header__right--text'> دفعة نقدية رقم: "{transData?.data?.code}"</span>
        </div>

        <div className='detailsheet__header__left'>
          <button className='tbl__addpriceList' onClick={handlePrint}>
            <img src='/images/printer.svg' alt={'طباعة'} />
            طباعة الفاتورة
          </button>
        </div>
      </div>

      {/* ======================================================the body of page ================================================= */}
      <div className='detailsheet__contentgeneral'>
        {transIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <div className='customerdetails__header__card'>
            <HeaderCardTS
              title={'مبلغ الدفعة النقدية'}
              num={transData?.data?.amount}
              imageUrl={'/images/dollar.svg'}
              color={'#F9D8D433'}
            />
            <HeaderCardTS
              title={'تاريخ الدفعة النقدية'}
              num={moment(transData?.data?.transDateTime)
                .locale('en-GB')
                .format('YYYY-MM-DD')}
              imageUrl={'/images/alt.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCardTS
              title={'المُستلم'}
              num={transData?.data?.destinationEntity}
              imageUrl={'/images/dropbox1.svg'}
              color={'#FFEDD0'}
            />
            <HeaderCardTS
              title={'المُرسل'}
              num={transData?.data?.sourceEntity}
              imageUrl={'/images/dropbox1.svg'}
              color={'#C050E838'}
            />
          </div>
        )}

        <div className='detailsheet__contentgeneral__grid'>
          {transIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={['تاريخ الانشاء', 'رقم الدفعة', 'مبلغ الدفعة', 'نوع الدفعة', 'ملاحظات']}
              contents={[
                moment(transData?.data?.transDateTime)
                  .locale('en-GB')
                  .format('YYYY-MM-DD'),
                transData?.data?.code,
                transData?.data?.amount,
                transData?.data?.transTypeName,
                transData?.data?.remarks
              ]}
              title={'بيانات الدفعة النقدية'}
            />
          )}
          {transIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={['الدفعة من', 'المُرسل']}
              contents={[transData?.data?.sourceType, transData?.data?.sourceEntity]}
              title={'بيانات المُرسل'}
            />
          )}
          {transIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={['الدفعة الى', 'المُستلم']}
              contents={[transData?.data?.destinationType, transData?.data?.destinationEntity]}
              title={'بيانات المُستلم'}
            />
          )}
        </div>
      </div>

      <div ref={printRef}>
        {isPrinting && (
          <div ref={printRef} style={{ display: 'none' }}>
            <Receipt data={transData?.data} />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountTransactionDetails;

import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle="عذرًا، ليس لديك صلاحية للوصول إلى هذه الصفحة."
      extra={<Button type="primary" onClick={() => navigate('/')}>العودة إلى الصفحة الرئيسية</Button>}
    />
  );
};

export default Unauthorized;

import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { useToast } from '../../../context/ToastContext';
import { InputText } from 'primereact/inputtext';
import FormField from './components/FormField';
import styles from './AddEditSalesOrder.module.scss';
import { Dropdown } from 'primereact/dropdown';

interface SalesOrderBasicInfoStepProps {
  isEditing: boolean;
}

const SalesOrderBasicInfoStep = ({ isEditing }: SalesOrderBasicInfoStepProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const _ = useToast();

  const customer = watch('customer');
  const discountAmount = watch('discountOnProducts');
  const items = watch('items');
  const isEdit = watch('isEdit');

  const [firstTime, setfirstTime] = useState(false);

  const [selectedDiscountType, setSelectedDiscountType] = useState(isEditing ?? isEdit ? 2 : 1);
  const types = [
    { name: 'نسبة مئوية', id: 1 },
    { name: 'مبلغ', id: 2 }
  ];

  useEffect(() => {
    if (items?.length > 0 && (isEditing ?? isEdit) && !firstTime) {
      setValue('discountOnProducts', items[0]?.discountAmount);
      setfirstTime(true);
    } else {
      setfirstTime(false);
    }
  }, [isEditing ?? isEdit, items, firstTime]);

  useEffect(() => {
    if (discountAmount && !selectedDiscountType) {
      setValue('discountType', 1);
    } else {
      setValue('discountType', selectedDiscountType);
    }
  }, [discountAmount, selectedDiscountType]);

  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        {customer ? (
          <>
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>بيانات المورد</p>}
              style={{
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <FormField label='الاسم كامل' error=''>
              <InputText name='CustomerName' value={customer.fullName} disabled className='surface-200 opacity-100' />
            </FormField>
            <FormField label='رقم الهاتف' error=''>
              <InputText name='Phone' value={customer.mobile} disabled className='surface-200 opacity-100' />
            </FormField>
          </>
        ) : null}

        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Right side - Basic Invoice Info */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>البيانات الاساسية للفاتورة(مطلوبة)</p>}
              style={{
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <CustomFormField
              control={control}
              name={'reference'}
              customStyle={'pt-4'}
              label={'الرقم المرجعي'}
              errors={errors}
              type={'text'}
              required
            />
          </div>

          {/* Left side */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            <>
              <Message
                severity='success'
                icon={null}
                content={<p className={'text-black-alpha-90 font-semibold'}>تطبيق خصم </p>}
                style={{
                  width: '100%',
                  backgroundColor: 'rgba(162, 251, 93, 0.2)'
                }}
              />

              <div className={styles.discountArea}>
                <CustomFormField
                  control={control}
                  customStyle={'pt-4'}
                  name={'discountOnProducts'}
                  label={'خصم على المنتجات المختارة'}
                  errors={errors}
                  type={'decimal'}
                />
                <Dropdown
                  value={selectedDiscountType}
                  onChange={(e) => {
                    setSelectedDiscountType(e.value);
                    setValue('discountType', e.value);
                  }}
                  options={types}
                  optionLabel='name'
                  optionValue='id'
                  className={styles.discountType}
                />
              </div>
            </>

            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold '}>اخرى</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'remarks'}
              label={'ملاحظات اخرى'}
              errors={errors}
              type={'textarea'}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default SalesOrderBasicInfoStep;

import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';
import useFetch from '../../../../hooks/useFetch';

const WorkingGroupMasterForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Employee');

  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');

  const { data: locationData, isLoading: isLocationLoading } = useFetch('/Location/dropdown');

  const WeekDaysEnum = [
    { id: 'السبت', name: 'السبت' },
    { id: 'الاحد', name: 'الاحد' },
    { id: 'الاثنين', name: 'الاثنين' },
    { id: 'الثلاثاء', name: 'الثلاثاء' },
    { id: 'الاربعاء', name: 'الاربعاء' },
    { id: 'الخميس', name: 'الخميس' },
    { id: 'الجمعة', name: 'الجمعة' }
  ];

  return (
    <>
      <CustomFormField
        name={'weekDay'}
        label={'اختر اليوم'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={WeekDaysEnum}
        disabled={isEditMode}
      />

      <CustomFormField
        name={'employeeId'}
        label={'اختر الموظف'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={employeeData?.data ?? []}
        disabled={isEditMode || isEmployeeLoading}
      />

      <CustomFormField
        name={'listOfLocationId'}
        label={'اختر المناطق'}
        control={control}
        errors={errors}
        optionValue='id'
        optionLabel='name'
        type={'multi-select'}
        options={locationData?.data ?? []}
        disabled={isLocationLoading}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر الحالة`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={`اختر الحالة`}
        options={statusData?.data ?? []}
        disabled={isStatusLoading}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default WorkingGroupMasterForm;

import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import useModal from '../../../hooks/useModal';
import GenericForm from '../../../components/Forms/GenericForm';
import { useToast } from '../../../context/ToastContext';
import GenericModal from '../../../components/components/Modal/GenericModal';

function AccountingList() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isCashBoxVistable,
    openModal: cashBoxOpenModal,
    closeModal: cashBoxCloseModal,
    control: cashBoxControl,
    handleSubmit: cashBoxSubmit,
    errors: cashBoxErrors,
    setValue: cashBoxSetValue,
    loading: cashBoxLoading,
    setLoading: cashBoxSetLoading,
    watch: cashBoxWatch
  } = useModal({ remarks: ' ', names: [] }, '/CashBox', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isExpensesTypeVistable,
    openModal: expensesTypeOpenModal,
    closeModal: expensesTypeCloseModal,
    control: expensesTypeControl,
    handleSubmit: expensesTypeSubmit,
    errors: expensesTypeErrors,
    setValue: expensesTypeSetValue,
    loading: expensesTypeLoading,
    setLoading: expensesTypeSetLoading,
    watch: expensesTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ExpensesType', fetchTabData, genericSchemeForNames, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'خزائن الشركة',
            tableHeader: 'انواع الخزائن',
            entityName: 'الخزينة',
            dataKey: 'Cashboxes',
            apiUrl: '/CashBox/list',
            columns: [
              { header: 'الخزائن', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع الخزائن الجديدة',
                onClick: () => {
                  cashBoxOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              cashBoxOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusId,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              cashBoxOpenModal();
            }
          },
          {
            label: 'انواع المصاريف',
            tableHeader: 'انواع المصاريف',
            entityName: 'المصاريف',
            dataKey: 'Expenses Types',
            apiUrl: '/ExpensesType/list',
            columns: [
              { header: 'انواع المصاريف', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع المصارف الجديدة',
                onClick: () => {
                  expensesTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              expensesTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusId,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              expensesTypeOpenModal();
            }
          }
        ]}
        headerTitle='بيانات الحسابات و المبيعات'
        headerIcon={'../images/database.svg'}
      />
      <GenericModal
        headerTitle={'إضافة انواع خزائن جديدة'}
        isVisible={isCashBoxVistable}
        onHide={cashBoxCloseModal}
        handleSubmit={cashBoxSubmit}
        toast={toast}
        loading={cashBoxLoading}
        isEditMode={cashBoxWatch('id') !== undefined}
      >
        <GenericForm
          key={cashBoxWatch('id')}
          control={cashBoxControl}
          watch={cashBoxWatch}
          errors={cashBoxErrors}
          setValue={cashBoxSetValue}
          extraProps={{
            entityName: 'نوع الخزينة'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع مصاريف جديدة'}
        isVisible={isExpensesTypeVistable}
        onHide={expensesTypeCloseModal}
        handleSubmit={expensesTypeSubmit}
        toast={toast}
        loading={expensesTypeLoading}
        isEditMode={expensesTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={expensesTypeWatch('id')}
          control={expensesTypeControl}
          watch={expensesTypeWatch}
          errors={expensesTypeErrors}
          setValue={expensesTypeSetValue}
          extraProps={{
            entityName: 'نوع المصروف'
          }}
        />
      </GenericModal>
    </>
  );
}

export default AccountingList;

// src/components/Receipt.tsx
import React from 'react';

interface Invoice {
  id: number;
  typeId: number;
  typeName: string;
  direction: number;
  invoiceNb: string;
  customerId: number;
  customerFullName: string;
  supplierId: number;
  supplierName: string;
  pointOfSalesId: number;
  pointOfSalesName: string;
  totalAmount: number;
  totalDiscount: number;
  totalNetAmount: number;
  creationDate: string; // ISO 8601 format
  printed: boolean;
  currencyId: number;
  currencyName: string;
  reference: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
  userPhoneNumber: string;
  statusId: number;
  statusName: string;
  remarks: string;
  items: InvoiceItem[];
}

interface InvoiceItem {
  id: number;
  invoiceId: number;
  itemType: string;
  itemId: number;
  qty: number;
  price: number;
  discountPercent: number;
  discountAmount: number;
  netAmount: number;
  statusId: number;
  statusName: string;
  remarks: string;
}

interface ReceiptProps {
  data: Invoice;
}

const Receipt: React.FC<ReceiptProps> = ({ data }) => {
  return (
    <div style={{ padding: '20px', border: '1px solid black', width: '100%' }}>
      {/* <h2>Receipt</h2>
      <p>Date: {data.typeName}</p>
      <p>Customer: {data.typeName}</p>
      <hr />
      <ul>
        {data.items.map((item, index) => (
          <li key={index}>
            {item.price} - ${item.price.toFixed(2)}
          </li>
        ))}
      </ul>
      <hr />
      <h3>Total: ${data.userId.toFixed(2)}</h3> */}
    </div>
  );
};

export default Receipt;

import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { axiosInstance } from '../../../global';
import { useToast } from '../../../context/ToastContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function PendingCashBoxTrans() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };
  function refreshPage() {
    window.location.reload();
  }
  const columnsTab1 = [
    {
      header: 'كود الحركة',
      field: 'code',
      isChip: false
    },
    {
      header: 'اسم المستلم',
      field: 'receivingEntityName',
      isChip: false
    },
    {
      header: 'اسم المرسل',
      field: 'issuingEntityName',
      isChip: false
    },
    {
      header: 'المبلغ',
      field: 'amount',
      isChip: false
    },
    {
      header: 'تاريخ الحركة',
      field: 'transDateTime',
      isDate: true
    }
  ];

  const handleAcceptCashBox = async (id: number) => {
    try {
      await axiosInstance.post(`/AccountingTransaction/acceptCashBoxTransaction/${id}`);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم قبول العملية`,
        life: 2000
      });
      fetchTabData();
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية قبول الحركة :${error}`,
        life: 3000
      });
    }
  };

  function showConfirmAcceptCashBox(id: number, amount: any, branch: string) {
    confirmDialog({
      message: `هل انت متأكد من استلام مبلغ  ${amount}  من فرع  ${branch} `,
      header: 'تأكيد القبول',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: () => handleAcceptCashBox(id),
      draggable: false,
      resizable: false
    });
  }
  return (
    <GenericPage
      ref={genericPageRef}
      tabs={[
        {
          isHidden: true,
          label: 'الفواتير المعلقة',
          tableHeader: 'قائمة التحويلات المعلقة',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/AccountingTransaction/list/pendingCashBoxAccountingTransactions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'الفواتير المعلقة',
          SeparetentityName: 'قبول',
          rowOnClick: (rowData) => {
            showConfirmAcceptCashBox(rowData.id, rowData.amount, rowData.issuingEntityName);
          },
          headerButtons: []
        }
      ]}
      headerTitle={'الحسابات'}
      headerIcon={'../images/coins.svg'}
    />
  );
}

export default PendingCashBoxTrans;

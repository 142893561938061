import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import useFetch from '../../../hooks/useFetch';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import styles from '../AddEditInvoice/AddEditInvoice.module.scss';
import Receipt from '../Receipt/Receipt';

const InvoiceDetails = () => {
  const Style1 = { color: 'black' };
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const { invoiceId, supplierId, customerId } = location.state || {};

  const { data: invoiceData, isLoading: invoiceIsLoading } = useFetch(`/Invoice/${invoiceId}`);
  const { data: supplierData, isLoading: supplierIsLoading } = useFetch(`/Supplier/${supplierId}`);
  const { data: customerData, isLoading: customerIsLoading } = useFetch(`/Customer/${customerId}`);
  const [isPrinting, setIsPrinting] = useState(false); // Control receipt visibility

  // const fetchActivityonVisit = async () => {
  //   const response = await axiosInstance.get(`/Supplier/${supplierId}`);
  //   return response.data;
  // };

  // const { lazyApiCall, state } = useApiState({ apiCall: fetchActivityonVisit, isLazy: true });

  // useEffect(() => {
  //   if (visitData) {
  //     lazyApiCall();
  //   }
  // }, [visitData]);

  const handlePrint = () => {
    setIsPrinting(true); // Show the receipt
    setTimeout(() => {
      if (printRef.current) {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(); // Reload to reset the app state
      }
    }, 0); // Slight delay to ensure rendering before printing
  };
  return (
    <>
      {/* ======================================================the header of page ================================================= */}

      <div className='detailsheet__header'>
        <div className='detailsheet__header__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='detailsheet__header__right--text'> فاتورة رقم: "{invoiceData?.data?.invoiceNb}"</span>
        </div>

        <div className='detailsheet__header__left'>
          {invoiceData?.data?.isUpdatable ? (
            <Button
              className='detailsheet__header__left__btn'
              onClick={() =>
                navigate('/finance/invoice/edit', {
                  state: { invoiceId: invoiceId, supplierId: supplierId, type: invoiceData.typeId }
                })
              }
              icon='pi pi-pencil'
              style={Style1}
              text
            >
              تعديل الفاتورة
            </Button>
          ) : null}

          <button
            className={styles.btn}
            onClick={() =>
              navigate('/finance/transaction/pay', {
                state: { invoiceData: invoiceData?.data, type: 'pay' }
              })
            }
          >
            <img src='/images/Newcoins.svg' alt={'فاتورة'} />
            سداد الفاتورة
          </button>
          <button className='tbl__addpriceList' onClick={handlePrint}>
            <img src='/images/printer.svg' alt={'طباعة'} />
            طباعة الفاتورة
          </button>
        </div>
      </div>

      {/* ======================================================the body of page ================================================= */}
      <div className='detailsheet__contentgeneral'>
        {invoiceIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <div className='customerdetails__header__card'>
            <HeaderCardTS
              title={'المتبقى للدفع'}
              num={invoiceData?.data?.totalNetAmount}
              imageUrl={'/images/dollar.svg'}
              color={'#F9D8D433'}
            />
            <HeaderCardTS
              title={'تاريخ الفاتورة'}
              num={moment(invoiceData?.data?.creationDate)
                .locale('en-GB')
                .format('YYYY-MM-DD')}
              imageUrl={'/images/alt.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCardTS
              title={'حالة الفاتورة'}
              num={<Chip label={invoiceData?.data?.statusName} />}
              imageUrl={'/images/briefcase.svg'}
              color={'#FFEDD0'}
            />
            <HeaderCardTS
              title={'منشئ الفاتورة'}
              num={invoiceData?.data?.userName}
              imageUrl={'/images/user1.svg'}
              color={'#C050E838'}
            />
          </div>
        )}

        <div className='detailsheet__contentgeneral__grid'>
          {supplierIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : supplierData ? (
            <DetailsSheetGrid
              headers={['الاسم', 'البلد', 'رقم الهاتف', 'البريد الالكترونى', 'الموقع الالكترونى', 'العنوان', 'ملاحظات']}
              contents={[
                supplierData?.data?.name,
                supplierData?.data?.country?.name,
                supplierData?.data?.phone,
                supplierData?.data?.email,
                supplierData?.data?.website,
                supplierData?.data?.address,
                supplierData?.data?.remarks
              ]}
              title={'بيانات المورد'}
            />
          ) : customerData ? (
            <DetailsSheetGrid
              headers={['الاسم', 'البلد', 'رقم الهاتف', 'العنوان', 'ملاحظات']}
              contents={[
                customerData?.data?.fullName,
                customerData?.data?.defaultAddress?.country?.name,
                customerData?.data?.phoneNb,
                customerData?.data?.defaultAddress?.name,
                customerData?.data?.remarks
              ]}
              title={'بيانات العميل'}
            />
          ) : null}
          {invoiceIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={[
                'رقم الفاتورة',
                'تاريخ الفاتورة',
                supplierData ? 'اسم المورد' : 'اسم العميل',
                'العملة',
                'إجمالى الفاتورة',
                'الخصم',
                'المبلغ المطلوب',
                'المبلغ المدفوع',
                'المتبقى',
                'ملاحظات'
              ]}
              contents={[
                invoiceData?.data?.invoiceNb,
                moment(invoiceData?.data?.creationDate)
                  .locale('en-GB')
                  .format('YYYY-MM-DD'), // invoiceData?.data,
                supplierData ? invoiceData?.data?.supplierName : invoiceData?.data?.customerFullName,
                invoiceData?.data?.currencyName,
                invoiceData?.data?.totalAmount,
                invoiceData?.data?.totalDiscount,
                invoiceData?.data?.totalNetAmount,
                invoiceData?.data?.totalSettledAmount,
                invoiceData?.data?.totalRemainingAmount,
                invoiceData?.data?.remarks
              ]}
              title={'بيانات الفاتورة'}
            />
          )}

          {/* ======================================================the table of page ================================================= */}
          <div className='tbl mt-5'>
            <DataTable
              value={invoiceData?.data?.items}
              className='tbl__table'
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column field='itemName' header='اسم المنتج'></Column>
              <Column field='price' header='سعر المنتج'></Column>
              <Column field='qty' header='الكمية'></Column>
              <Column field='amount' header='المبلغ'></Column>
              <Column field='discountPercent' header='الخصم(نسبة)'></Column>
              <Column field='discountAmount' header='الخصم(مبلغ)'></Column>
              <Column field='netAmount' header='السعر بعد الخصم'></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <div ref={printRef}>
        {isPrinting && (
          <div ref={printRef} style={{ display: 'none' }}>
            <Receipt data={invoiceData?.data} />
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceDetails;

import React, { Suspense, useEffect, useState } from 'react';
import { useToast } from '../../../context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import PageHeader from '../../../components/components/Header/PageHeader';
import styles from './AddEditPOS.module.scss';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from '../../../global';
import POSBasicInfoStep from './POSBasicInfoStep';
import POSLocationStep from './POSLocationStep';

import moment from 'moment';

const AddEditPointOfSales = () => {
  const toast = useToast();
  const location = useLocation();
  const { pointSalesId } = location.state || {};

  const navigate = useNavigate();
  // Define your Yup schema
  const PosInfoSchema = yup.object({
    id: yup.number().optional().nullable(),
    name: yup.string().required('اسم منفذ البيع مطلوب مطلوب'),
    responsibleEmployeeId: yup.number().required('الموظف المسؤول مطلوب مطلوب'),
    warehouseId: yup.number().required('المخزن مطلوب'),
    locationId: yup.number().required('المنطقة مطلوبة'),
    address: yup.string().required('عنوان منفذ البيع مطلوب مطلوب'),
    cashBoxId: yup.number().required('الخزنة مطلوبة'),
    phone: yup
      .string()
      .nullable()
      .matches(/^\+?[0-9\s\-()]{7,15}$/, 'صيغة الرقم غير صحيحة'),
    mobile: yup
      .string()
      .required('رقم المحمول مطلوب')
      .matches(/^\+?[0-9\s\-()]{7,15}$/, 'صيغة الرقم غير صحيحة'),
    remarks: yup.string().nullable(),
    statusId: yup.number().required('الحالة مطلوبة'),
    countryId: yup.number().nullable().default(1),
    governorateId: yup.number().nullable(),
    areaId: yup.number().nullable(),
    isEdit: yup.boolean().default(false)
  });

  // Infer FormData type from the schema
  type FormData = yup.InferType<typeof PosInfoSchema>;
  const [isEditing, setIsEditing] = useState(false);

  // Use FormData as the type parameter
  const methods = useForm<FormData>({
    resolver: yupResolver(PosInfoSchema),
    mode: 'all'
  });

  const { handleSubmit, trigger, reset, watch, setValue } = methods;

  useEffect(() => {}, [methods.formState.errors]);

  const handleSubmitOnClick = async (data: any) => {
    const PosParamDto = {
      id: data.id ?? null,
      name: data?.name,
      responsibleEmployeeId: data?.responsibleEmployeeId,
      cashBoxId: data.cashBoxId,
      phone: data.phone,
      mobile: data.mobile,
      locationId: data.locationId,
      address: data.address,
      warehouseId: data.warehouseId,
      statusId: data.statusId,
      remarks: data.remarks
    };

    try {
      isEditing
        ? await axiosInstance.put('/PointOfSale', PosParamDto)
        : await axiosInstance.post('/PointOfSale', PosParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} منفذ البيع بنجاح`,
        life: 2000
      });
      navigate('/Sales/PointofSales');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} منفذ البيع: ${error}`,
        life: 3000
      });
    }
  };

  const steps = [
    { title: 'بيانات منفذ البيع الاساسية', content: <POSBasicInfoStep isEditing={isEditing} /> },
    { title: 'تفاصيل العنوان والمنطقة', content: <POSLocationStep isEditing={isEditing} /> }
  ];

  const [current, setCurrent] = useState(0);

  const items = steps.map((item) => ({ key: item.title, label: item.title }));
  const stepFields: FieldPath<FormData>[][] = [
    ['name', 'responsibleEmployeeId', 'cashBoxId', 'warehouseId', 'statusId'],
    ['locationId', 'address']
  ];

  const handleOnClick = async () => {
    let isValid = true;
    if (current < stepFields.length) {
      isValid = await trigger(stepFields[current]);
    }
    if (isValid) {
      setCurrent((prevIndex) => (prevIndex >= steps.length - 1 ? prevIndex : prevIndex + 1));
    }
  };
  const handleBackClick = () => {
    setCurrent((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  useEffect(() => {
    const fetchPosData = async () => {
      if (pointSalesId) {
        try {
          const response = await axiosInstance.get(`/PointOfSale/${pointSalesId}`);
          const PosData = response.data.data;
          reset({
            id: PosData.id,
            name: PosData.name,
            responsibleEmployeeId: PosData.responsibleEmployeeId,
            cashBoxId: PosData.cashBoxId,
            phone: PosData.phone,
            mobile: PosData.mobile,
            countryId: 1,
            governorateId: PosData.governorateId,
            areaId: PosData.areaId,
            locationId: PosData.locationId,
            address: PosData.address,
            statusId: PosData.statusId,
            warehouseId: PosData.warehouseId,
            remarks: PosData.remarks,
            isEdit: true
          });
          setIsEditing(true);
        } catch (error: any) {
          toast.current.show({
            type: 'error',
            summary: 'خطأ',
            detail: `فشل في جلب بيانات منفذ البيع: ${error.message}`,
            life: 3000
          });
        }
      }
    };

    fetchPosData();
  }, [pointSalesId, reset]);

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة منفذ بيع جديد'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={current} />
          </Card>
          {<Suspense fallback={<ProgressSpinner />}>{steps[current].content}</Suspense>}
          <div className={`${styles.buttonContainer} mb-5`}>
            {current > 0 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className='btn__nextbtn' type='submit'>
                {isEditing ? 'حفظ التعديلات' : 'إضافة منفذ البيع'}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditPointOfSales;

import React, { useRef, useState } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import EmployeeLoanForm from './EmployeeLoanForm';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';
import { Modal } from 'antd';
import { axiosInstance } from '../../../global';

const loanSheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function Loan() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null); // State to hold the selected loan ID

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isEmployeeLoanVisitable,
    openModal: EmployeeLoanOpenModal,
    closeModal: EmployeeLoanCloseModal,
    control: EmployeeLoanControl,
    handleSubmit: EmployeeLoanSubmit,
    errors: EmployeeLoanErrors,
    setValue: EmployeeLoanSetValue,
    loading: EmployeeLoanLoading,
    setLoading: EmployeeLoanSetLoading,
    watch: EmployeeLoanWatch
  } = useModal({}, '/Loan', fetchTabData, loanSheme, toast, (data) => {
    const date = moment(new Date(data.firstInstallmentDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]');
    debugger;
    return {
      ...data,
      firstInstallmentDate: moment(new Date(data.firstInstallmentDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
    };
  });

  const handleOk = () => {
    if (selectedLoanId === null) return;
    try {
      axiosInstance.post(`/Loan/approve/${selectedLoanId}`);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: 'تم قبول السلفة للموظف',
        life: 2000
      });
      setIsModalOpen(false);
      fetchTabData();
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: 'فشل عملية قبول السلفة للموظف',
        life: 3000
      });
    }
  };

  const handleCancel = (isCancelButton = false) => {
    if (isCancelButton === false) {
      setIsModalOpen(false);
    } else {
      if (selectedLoanId === null) return;
      try {
        axiosInstance.post(`/Loan/reject/${selectedLoanId}`);
        toast.current.show({
          type: 'success',
          summary: 'عملية ناجحة',
          detail: 'تم رفض السلفة للموظف',
          life: 2000
        });
        setIsModalOpen(false);
        window.location.reload();
      } catch (error) {
        toast.current.show({
          type: 'error',
          summary: 'عملية خاطئة',
          detail: 'فشل عملية رفض السلفة للموظف',
          life: 3000
        });
      }
    }
  };
  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'السلف',
            entityName: 'سلفة',
            tableHeader: 'جدول السلف',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              EmployeeLoanOpenModal({
                id: rowData.id,
                code: rowData.code,
                reference: rowData.reference,
                employeeId: rowData.employeeID,
                amount: rowData.amount,
                nbOfInstallments: rowData.nbOfInstallments,
                installmentValue: rowData.installmentValue,
                statusId: rowData.statusID,
                firstInstallmentDate: new Date(rowData.firstInstallmentDate),
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Loan/list',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              { header: 'المرجع', field: 'reference', isChip: false },
              { header: 'المبلغ', field: 'amount', isChip: false },
              { header: 'عدد الاقساط', field: 'nbOfInstallments', isChip: false },
              { header: 'قيمة القسط', field: 'installmentValue', isChip: false },
              { header: 'معاد القسط الاول', field: 'firstInstallmentDate', isDate: true },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            dataKey: 'EmployeeLoan',
            emptyStateButtonClick: () => {
              EmployeeLoanOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة سلفة جديده',
                onClick: () => {
                  EmployeeLoanOpenModal();
                }
              }
            ]
          },
          {
            label: 'الموافقة على السلف',
            entityName: 'سلفة',
            tableHeader: 'جدول الموافقة على السلف',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              setSelectedLoanId(rowData.id); // Set the selected loan ID
              setIsModalOpen(true);
            },
            apiUrl: '/Loan/list/pending',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              { header: 'المرجع', field: 'reference', isChip: false },
              { header: 'المبلغ', field: 'amount', isChip: false },
              { header: 'عدد الاقساط', field: 'nbOfInstallments', isChip: false },
              { header: 'قيمة القسط', field: 'installmentValue', isChip: false },
              { header: 'معاد القسط الاول', field: 'firstInstallmentDate', isDate: true },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            dataKey: 'EmployeeLoanPending',
            SeparetentityName: 'موافقة/رفض'
          }
        ]}
        headerTitle={'الموظفين'}
        headerIcon={'/images/database.svg'}
      />

      <GenericModal
        headerTitle={EmployeeLoanWatch('id') === undefined ? 'اضافه سلفة جديدة' : 'تعديل سلفة'}
        isVisible={isEmployeeLoanVisitable}
        onHide={EmployeeLoanCloseModal}
        handleSubmit={EmployeeLoanSubmit}
        toast={toast}
        loading={EmployeeLoanLoading}
        isEditMode={EmployeeLoanWatch('id') !== undefined}
      >
        <EmployeeLoanForm
          control={EmployeeLoanControl}
          errors={EmployeeLoanErrors}
          setValue={EmployeeLoanSetValue}
          watch={EmployeeLoanWatch}
          extraProps={{ entityName: 'سلفة' }}
        />
      </GenericModal>
      <Modal
        title='اتخاذ قرار على السلفة'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => handleCancel(false)}
        okText='الموافقة على السلفة'
        cancelText='رفض السلفة'
        okButtonProps={{
          style: { backgroundColor: '#98f500', color: '#333', border: '#333' }
        }}
        cancelButtonProps={{
          onClick: () => handleCancel(true)
        }}
      ></Modal>
    </>
  );
}

export default Loan;

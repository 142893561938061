import React, { useCallback, useEffect, useState } from 'react';
import { axiosInstance } from '../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../style/Tables.scss';
import { Knob } from 'primereact/knob';
import GenericDataTable from '../../../components/components/GenericDataTable/GenericDataTable';
import { useDebounce } from '../../../components/components/DataTableCard/DataTableCard';
import { useApiState } from '../../../hooks/useApiState';
import { Modal } from 'antd';
import AddCustodyForm from './Form/AddCustodyForm';

function CustodyExpandedTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [myList, setMyList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotalCount, setPageTotalCount] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiseData, setInvoiceData] = useState([]);
  const [repCashBox, setRepCashBox] = useState<number>(0);
  const [refreshData, setRefreshData] = useState(false);

  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const fetchData = useCallback(
    async (params = { page, size: pageSize, search: debounceSearchTerm, sortField, sortOrder }) => {
      const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

      const response = await axiosInstance.get(`/Invoice/list/financeCovenant`, {
        params: { ...params, status: 'Active', sort: sortParam }
      });
      return response.data;
    },
    [page, pageSize, debounceSearchTerm, sortField, sortOrder]
  );

  const {
    lazyApiCall: fetchTabData,
    state: { isLoading, error, data }
  } = useApiState({
    isLazy: true,
    apiCall: fetchData
  });

  useEffect(() => {
    fetchTabData();
  }, [page, pageSize, sortOrder, sortField, debounceSearchTerm, refreshData]);

  useEffect(() => {
    if (data) {
      setMyList(data?.data ?? []);
    }
  }, [data]);

  useEffect(() => {
    setPage(0);
    setPageSize(10);
    setSortField(undefined);
    setSortOrder(undefined);
  }, [searchTerm]);

  function handleSearchNameChange(e: any) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const allowExpansion = (rowData: any) => {
    return rowData?.invoices?.length > 0;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className='p-3'>
        <DataTable value={data.invoices}>
          <Column field='invoiceNb' header='رقم الفاتورة'></Column>
          <Column field='invoiceReference' header='المرجع'></Column>
          <Column field='customerName' header='العميل'></Column>
          <Column field='invoiceDate' header='تاريخ الفاتورة'></Column>
          <Column field='amount' header='المبلغ'></Column>
          <Column
            header='خيارات'
            body={(rowData) => (
              <Button
                label={`تسوية`}
                icon='pi pi-exclamation-circle'
                text
                onClick={() => {
                  setRepCashBox(data?.cashBoxId);

                  const invoiceIds = Array.isArray(rowData.invoiceId)
                    ? rowData.map((invoice: any) => invoice.invoiceId)
                    : [rowData.invoiceId];

                  setInvoiceData(invoiceIds);
                  setIsModalOpen(true);
                }}
                style={{ color: 'blue' }}
              />
            )}
          />
        </DataTable>
      </div>
    );
  };

  const columns = [
    {
      header: 'الموظف',
      field: 'employeeName',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'empStatusName',
      isChip: true
    },
    // {
    //   header: 'الفرع',
    //   field: 'branchName',
    //   isChip: true
    // },
    {
      header: 'المبلغ الكلى للعهدة',
      field: 'totalAmount',
      isChip: false
    },
    {
      header: 'عدد الفواتير',
      field: 'numberOfInvoices',
      isChip: false
    }
  ];

  return (
    <div className='Connection'>
      <div className='tbl ml-3 mb-4 mr-3'>
        <div className='tbl__visit__header'>
          <div className='flex flex-row gap-4'>
            <span className='header__title'> قائمة العهد النقدية </span>
          </div>

          <div className='header__visit__tools'>
            <span className='p-input-icon-left'>
              <i className='pi pi-search' />
              <InputText
                value={searchTerm}
                className='header__tools__search w-38rem'
                placeholder='بحث'
                onChange={handleSearchNameChange}
                type='search'
              />
            </span>
          </div>
        </div>
        <div className='tbl__table'>
          <GenericDataTable
            data={myList}
            columns={columns}
            page={page}
            pageSize={pageSize}
            totalRecords={pageTotalCount}
            isLoading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onPageChange={(e: any) => {
              if (e.page !== undefined) {
                setPage(e.page);
              }
              if (e.rows !== undefined) {
                setPageSize(e.rows);
              }
            }}
            onSort={(e: any) => {
              setSortField(e.sortField);
              const order = e.sortOrder === 1 ? 'asc' : 'desc';
              setSortOrder(order);
              setPage(0);
            }}
            onRowClick={(rowData) => {
              // const invoiceIds = rowData?.invoices?.map((invoice: any) => invoice?.invoiceId);
              const invoiceIds = Array.isArray(rowData.invoices)
                ? rowData.invoices.map((invoice: any) => invoice.invoiceId)
                : [rowData.invoices.invoiceId];
              setInvoiceData(invoiceIds);
              setRepCashBox(rowData?.cashBoxId);
              setIsModalOpen(true);
            }}
            edit={false}
            entityName='الفاتورة'
            emptyStateUrl=''
            allowExpansion={allowExpansion}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            SeparetentityName={'تسوية'}
          />
        </div>
      </div>
      <AddCustodyForm
        visible={isModalOpen}
        // setVisible={setIsModalOpen}
        setVisible={(isVisible: boolean) => {
          setIsModalOpen(isVisible);
          if (!isVisible) {
            setRefreshData((prev) => !prev); // Toggle refresh state
          }
        }}
        invoicesData={invoiseData}
        cashBoxId={repCashBox}
      />
    </div>
  );
}

export default CustodyExpandedTable;

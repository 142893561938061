import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'antd';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '../../../context/ToastContext';

const SupplierChooserStep = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [externalSearchTerm, setExternalSearchTerm] = useState('');

  const handleSearchClick = () => {
    setExternalSearchTerm(searchText);
  };

  // Memoize the selectedTab object
  const selectedTab = useMemo(
    () => ({
      rowOnClick: (rowData: any) => {
        setValue('supplier', rowData);
      },
      emptyStateButtonClick: () => {},
      label: 'Supplier Table',
      tableHeader: 'Supplier Table',
      entityName: 'المورد',
      dataKey: 'supplier',
      columns: [
        { header: 'المورد', field: 'name', isChip: false },
        { header: 'رقم الهاتف', field: 'phone', isChip: false },
        { header: 'البريد الالكتروني', field: 'email', isChip: false },
        { header: 'الموقع الالكتروني', field: 'website', isChip: false },
        { header: 'العنوان الرئيسي', field: 'address', isChip: false },
        { header: 'طريقة التوصيل', field: 'deliveryTermName', isChip: false },
        { header: 'الحالة', field: 'statusName', isChip: true }
      ],
      apiUrl: 'Supplier/list',
      emptyStateUrl: '',
      edit: false,
      extraParams: {
        status: 'Active'
      },
      rowClickable: true
    }),
    []
  );

  return (
    <main>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <div style={{ padding: '1rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <label style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'IBM Plex Sans Arabic' }}>
              بحث عن المورد
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            </label>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <InputText style={{ width: '100%' }} onChange={(e) => setSearchText(e.target.value)} />
            <Button
              style={{
                backgroundColor: '#A2FB5D',
                width: '122px',
                border: '1px solid #333',
                borderRadius: '6px'
              }}
              onClick={handleSearchClick}
            >
              بحث
            </Button>
          </div>
          <label
            style={{
              fontSize: '20px',
              fontFamily: 'IBM Plex Sans Arabic',
              fontWeight: 'bold',
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
              display: 'block'
            }}
          >
            اختر مورد من قائمة الموردين
          </label>

          <DataTableCard selectedTab={selectedTab} withoutHeader={true} externalSearchTerm={externalSearchTerm} />
        </div>
      </Card>
    </main>
  );
};

export default SupplierChooserStep;

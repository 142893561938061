import React, { useEffect } from 'react';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';

import { useApiState } from '../../../../hooks/useApiState';
import { axiosInstance } from '../../../../global';
import CustomFormField from '../../../../components/components/Form/CustomFormField';

interface CustomerComplaintFormProps {
  control: Control<any>;
  errors: FieldErrors;
  setValue: (name: string, value: any) => void;
  watch: UseFormWatch<any>;
  isDisabled?: boolean;
}

const CustomerComplaintForm: React.FC<CustomerComplaintFormProps> = ({
  control,
  errors,
  setValue,
  watch,
  isDisabled
}) => {
  const selectedCustomer = watch('customerId');

  const fetchCustomer = async () => {
    const response = await axiosInstance.get(`/Customer/dropdown`);
    return response.data;
  };

  const fetchStatus = async () => {
    const response = await axiosInstance.get(`/Status/dropdown/Client`);
    return response.data;
  };

  const fetchComplaintsFamily = async () => {
    const response = await axiosInstance.get(`/ComplaintsFamily/list`);
    return response.data;
  };

  const fetchCustomersDevice = async () => {
    const response = await axiosInstance.get(`/CustomersDevice/dropdown/allByCustomer/${selectedCustomer}`);
    return response.data;
  };

  const {
    state: { isLoading: isCustomerLoading, error: customerError, data: customerData },
    lazyApiCall: getCustomerData
  } = useApiState({ apiCall: fetchCustomer });

  const {
    state: { isLoading: isStatusLoading, error: statusError, data: statusData },
    lazyApiCall: getStatusData
  } = useApiState({ apiCall: fetchStatus });

  const {
    state: { isLoading: isComplaintsFamilyLoading, error: complaintsFamilyError, data: complaintsFamilyData },
    lazyApiCall: getComplaintsFamilyData
  } = useApiState({ apiCall: fetchComplaintsFamily });

  const {
    state: { isLoading: isCustomersDeviceLoading, error: customersDeviceError, data: customersDeviceData },
    lazyApiCall: getCustomersDeviceData
  } = useApiState({ isLazy: true, apiCall: fetchCustomersDevice });

  useEffect(() => {
    if (selectedCustomer) {
      getCustomersDeviceData();
    }
  }, [selectedCustomer]);

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        control={control}
        name={'customerId'}
        label={'اسم العميل'}
        errors={errors}
        type={'dropdown'}
        options={customerData?.data ?? []}
        disabled={isDisabled || isCustomerLoading}
      />
      <CustomFormField
        control={control}
        name={'customerDeviceId'}
        label={'جهاز العميل'}
        errors={errors}
        type={'dropdown'}
        options={customersDeviceData?.data ?? []}
        disabled={isCustomersDeviceLoading}
      />
      <CustomFormField
        control={control}
        name={'complaintCategoryId'}
        label={'تصنيف الشكوى'}
        errors={errors}
        type={'dropdown'}
        options={complaintsFamilyData?.data ?? []}
        disabled={isComplaintsFamilyLoading}
      />
      <CustomFormField control={control} name={'remarks'} label={'تفاصيل الشكوى'} errors={errors} type={'textarea'} />
      <CustomFormField
        control={control}
        name={'issuingDate'}
        label={'تاريخ المشكلة'}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField
        control={control}
        name={'complaintDate'}
        label={'تاريخ الشكوى'}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField
        control={control}
        name={'statusId'}
        label={'اختر الحالة'}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
        disabled={isStatusLoading}
      />
    </div>
  );
};

export default CustomerComplaintForm;

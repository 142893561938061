import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import CustodyExpandedTable from './CustodyExpandedTable';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
const CustodyScreen = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <div className='customerdetails__header__card2' style={{ padding: '1rem' }}>
        <HeaderCardTS
          title={'اجمالى حساب الشركة'}
          num={'1455 ج.م'}
          imageUrl={'/images/coins.svg'}
          color={'#F9D8D4'}
          txtColor={'red'}
        />
        <HeaderCardTS
          title={'اجمالى عهد الموظفين'}
          num={'1500 ج.م'}
          imageUrl={'/images/officelife.svg'}
          color={'#6D54DE33'}
        />
      </div>

      <CustodyExpandedTable />
    </div>
  );
};

export default CustodyScreen;

import React, { useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import { axiosInstance } from '../../../../global';
import { useApiState } from '../../../../hooks/useApiState';
import useStatus from '../../../../hooks/useStatus';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import { Message } from 'primereact/message';
import { differenceInDays } from 'date-fns';

const VacationRequestForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const fetchEmployee = async () => {
    const response = await axiosInstance.get(`/Employee/dropdown`);
    return response.data;
  };

  const fetchVacationType = async () => {
    const response = await axiosInstance.get(`/VacationsType/dropdown`);
    return response.data;
  };

  const {
    state: { isLoading: isEmployeeLoading, error: employeeError, data: employeeData },
    lazyApiCall: getEmployeeData
  } = useApiState({ apiCall: fetchEmployee });

  const {
    state: { isLoading: isVacationTypeLoading, error: vacationTypeError, data: vacationTypeData },
    lazyApiCall: getVacationTypeData
  } = useApiState({ apiCall: fetchVacationType });

  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Employee');

  const fromDate = watch('fromDate');
  const toDate = watch('toDates');

  useEffect(() => {
    if (fromDate && toDate) {
      const daysCount = differenceInDays(new Date(toDate), new Date(fromDate)) + 1;
      setValue('days', daysCount > 0 ? daysCount : 1);
    }
  }, [fromDate, toDate, setValue]);

  return (
    <>
      <Message severity='info' text={`يجب اختيار موظف اولا`} className='text-black-alpha-90 font-semibold' />
      <CustomFormField
        name={'employeeId'}
        label={'اختر الموظف'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={employeeData?.data ?? []}
        disabled={isEmployeeLoading}
      />

      <CustomFormField
        name={'vacationTypeId'}
        label={'اختر نوع الاجازة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={vacationTypeData?.data ?? []}
        disabled={isVacationTypeLoading}
      />
      <Message severity='info' text={`مدة الاجازة`} className='text-black-alpha-90 font-semibold' />
      <CustomFormField name={'fromDate'} label={'من تاريخ'} control={control} errors={errors} type={'calendar'} />
      <CustomFormField name={'toDates'} label={'الي تاريخ'} control={control} errors={errors} type={'calendar'} />

      <CustomFormField
        name={'days'}
        label={'عدد الأيام'}
        control={control}
        errors={errors}
        type={'decimal'}
        disabled={true}
      />

      <CustomFormField
        name={'statusId'}
        label={'اختر حالة طلب اجازة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default VacationRequestForm;

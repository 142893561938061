import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function SuppliersTransactionScreen() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'اجمالي المديونية',
      num: '1528888 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#F9D8D433'
    },
    {
      title: 'اجمالي الايرادات الكلية',
      num: '10000000 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#6D54DE33'
    },
    {
      title: 'الفرع',
      num: 'فرع 1',
      imageUrl: '/images/branch.svg',
      color: '#FFEDD0'
    }
  ];

  const columnsTab1 = [
    {
      header: 'الاسم',
      field: 'name',
      isChip: false
    },
    {
      header: 'التواصل',
      field: 'deliveryTermName',
      isChip: false
    },
    {
      header: 'رقم التليفون',
      field: 'phone',
      isChip: false
    },
    {
      header: 'البريد الألكترونى',
      field: 'email',
      isChip: false
    },
    {
      header: 'الموقع الألكترونى',
      field: 'website',
      isChip: false
    },
    { header: 'الحالة', field: 'statusName', isChip: true }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: '',
          tableHeader: 'قائمة الموردين',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: 'Supplier/list',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'المورد',
          rowOnClick: (rowData) => {
            navigate(`/finance/supplier/details`, {
              state: { supplierId: rowData.id, type: 2 }
            });
          },
          headerButtons: [
            {
              label: 'اضافة موردين ',
              onClick: () => {
                navigate('/finance/supplier/add');
              }
            },
            {
              label: 'إنشاء فاتورة',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 3
                  }
                });
              }
            }
          ]
        }
      ]}
      headerTitle={'الحسابات'}
      headerIcon={'../images/coins.svg'}
      analyticsCards={headerDetails}
      cardsNum='3'
    />
  );
}

export default SuppliersTransactionScreen;

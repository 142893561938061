import React from 'react';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

interface CustomMultiSelectProps extends SelectProps {
  options: { label: string; value: string | number }[];
  value: string[] | number[] | null;
  onChange: (e: MultiSelectChangeEvent) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  filter?: boolean;
  optionLabel?: string;
  optionValue?: string;
  showClear?: boolean;
  disabled?: boolean;
  display?: 'comma' | 'chip';
  maxSelectedLabels?: number;
}

const CustomMultiSelectDropdown = React.forwardRef<any, CustomMultiSelectProps>(
  (
    {
      options,
      value,
      onChange,
      placeholder,
      style,
      className,
      filter = true,
      optionLabel = 'name',
      optionValue = 'id',
      showClear = false,
      disabled = false,
      display = 'chip',
      ...rest
    },
    ref
  ) => {
    return (
      <Select
        mode={'multiple'}
        options={options}
        defaultValue={value}
        fieldNames={{
          value: optionValue,
          label: optionLabel
        }}
        {...rest}
        onChange={(value1, option) => {
          onChange(value1);
        }}
        allowClear
        size={'large'}
        maxTagCount='responsive'
        placeholder={placeholder || 'Select items'}
        style={{ fontFamily: 'IBM Plex Sans Arabic', width: '100%', ...style }}
        className={`${className} custom-font-multiselect`}
        disabled={disabled}
        ref={ref}
        showSearch={filter}
      />

      // <MultiSelect
      //   value={value}
      //   options={options}
      //   onChange={onChange}
      //   placeholder={placeholder || 'Select items'}
      //   style={{ fontFamily: 'IBM Plex Sans Arabic', ...style }}
      //   className={`${className} custom-font-multiselect`}
      //   filter={filter}
      //   optionLabel={optionLabel}
      //   showClear={showClear}
      //   disabled={disabled}
      //   display={display}
      //   ref={ref}
      //   {...rest}
      // />
    );
  }
);

export default CustomMultiSelectDropdown;

import React from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';

const AddEditEvaluationForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { data: revesionData, isLoading: isRevesionDataLoading } = useFetch('/RevisionsType/dropdown');
  const { data: evaluationsData, isLoading: isEvaluationsLoading } = useFetch('/EvaluationsTypesList/dropdown');

  return (
    <>
      <CustomFormField
        name={'typeId'}
        label={isEditMode ? 'اسم نوع المراجعة' : 'اختر نوع المراجعة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={revesionData?.data ?? []}
        disabled={true}
        required
      />
      <CustomFormField
        name={'evaluationTypeId'}
        label={'اختر التقييم'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={evaluationsData?.data ?? []}
        disabled={isEvaluationsLoading}
        required
      />
      <CustomFormField
        name={'reviewerComment'}
        label={'ملاحظات المراجع'}
        control={control}
        errors={errors}
        type={'textarea'}
        required
      />
      <CustomFormField name={'remarks'} label={'ملاحظات اخرى'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default AddEditEvaluationForm;

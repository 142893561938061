import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import GenericModal from '../../../components/components/Modal/GenericModal';
import ServiceMasterForm from '../Services/ServiceMasterForm';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import moment from 'moment/moment';
import { useToast } from '../../../context/ToastContext';

const addProductPriceListScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  governorateId: yup.number().required().default(null),
  currencyId: yup.number().required().default(null),
  date: yup.date().required('Date is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().default('')
});

function PriceList() {
  const navigate = useNavigate();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const toast = useToast();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, '/ProductsPricesListMaster', fetchTabData, addProductPriceListScheme, toast, (data) => {
      return {
        ...data,
        fromDate: moment(new Date(data.fromDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        toDates: moment(new Date(data.toDates)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      };
    });

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: '',
            emptyStateButtonClick: () => {
              // navigate('/product/services/add');
              openModal();
            },
            dataKey: '',
            columns: [
              { header: 'اسم المحافظه', field: 'governorateName', isChip: false },
              { header: 'اسم الدوله', field: 'countryName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'تاريخ الادخال', field: 'date', isDate: false },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            rowOnClick: (rowData) => {
              // navigate(`/product/services/edit/${rowData.id}`);
              navigate(`/product/priceList/${rowData.id}`);
            },
            emptyStateUrl: '',
            edit: false,
            isHidden: true,
            apiUrl: 'ProductsPricesListMaster/list',
            entityName: 'قائمة اسعار المنتجات',
            headerButtons: [
              {
                label: 'إضافة قائمة اسعار منتجات جديدة',
                onClick: () => {
                  // navigate('/product/services/add');
                  openModal();
                }
              }
            ],
            tableHeader: 'قائمة اسعار منتجات'
          }
        ]}
        headerTitle={'المنتجات'}
        headerIcon={'../images/productbox.svg'}
      />
      <GenericModal
        headerTitle={'إضافة قائمة الأسعار'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <ServiceMasterForm watch={watch} control={control} errors={errors} setValue={setValue} />
      </GenericModal>
    </>
  );
}

export default PriceList;

import React, { forwardRef, useState } from 'react';
import PageHeader from '../Header/PageHeader';
import { ColumnProps } from '../GenericDataTable/GenericDataTable';
import styles from './GenericPage.module.scss';
import { PageAnalyticsHeader } from './PageAnalyticsHeader';
import DataTableCard from '../DataTableCard/DataTableCard';

interface TabProps {
  rowOnClick: (rowData: any) => void;
  emptyStateButtonClick?: (() => void) | undefined;
  label: string;
  tableHeader: string;
  entityName: string;
  SeparetentityName?: string;
  dataKey: string;
  columns: ColumnProps[];
  apiUrl: string;
  emptyStateUrl: string;
  edit: boolean;
  isHidden?: boolean;
  extraParams?: Record<string, string>;
  headerButtons?: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
  tabContent?: React.ReactNode;
  withoutSearchBar?: boolean;
}

export interface AnalyticsCardHeaderProps {
  title: string;
  num: number | string;
  imageUrl: string;
  percent?: string;
  chartImage?: string;
  color: string;
  txtColor?: string;
}

interface GenericDataTableProps<T> {
  tabs: TabProps[];
  headerTitle: string;
  headerIcon: string;
  analyticsCards?: AnalyticsCardHeaderProps[];
  cardsNum?: string;
  headerIconOnClick?: () => void;
  content?: React.ReactNode;
  withHeader?: boolean;
}

const GenericPage = forwardRef(function GenericPage<T>(
  {
    tabs,
    headerTitle,
    analyticsCards,
    cardsNum,
    headerIconOnClick,
    headerIcon,
    content,
    withHeader = true
  }: GenericDataTableProps<T>,
  ref: React.Ref<{ fetchTabData: () => void }>
) {
  const [selectedTab, setSelectedTab] = useState<TabProps>(tabs[0]);

  const handleTabClick = (tab: TabProps) => {
    setSelectedTab(tab);
  };

  return (
    <div className={styles.genericPage}>
      {withHeader && (
        <PageHeader
          iconOnClick={headerIconOnClick}
          icon={headerIcon}
          buttons={selectedTab?.headerButtons}
          headerTitle={headerTitle}
        />
      )}
      <PageAnalyticsHeader analyticsCards={analyticsCards} cardsNum={cardsNum} />
      <div className={styles.container} style={{ margin: 0 }}>
        {content !== undefined && content !== null ? (
          content
        ) : (
          <>
            {tabs.some((tab) => !tab.isHidden) && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 25 }}>
                {tabs.map((tab) => (
                  <button
                    key={tab.dataKey}
                    className={selectedTab.dataKey === tab.dataKey ? 'tabs--selected' : 'tabs--unselected'}
                    style={{
                      marginLeft: '0.25rem',
                      marginBottom: '0.5rem',
                      display: tab.isHidden ? 'none' : 'inline-block'
                    }}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            )}
            {selectedTab.tabContent !== undefined && selectedTab.tabContent !== null ? (
              selectedTab.tabContent
            ) : (
              <DataTableCard selectedTab={selectedTab} ref={ref} withoutHeader={selectedTab.withoutSearchBar} /> )
            }
          </>
        )}
      </div>
    </div>
  );
});

export default GenericPage;

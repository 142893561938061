import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './EditSupplierDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function EditSupplier1({ supplier, setSupplier, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const params = useParams();
  const [countries, setCountries] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [deliveryTerms, setDeliveryTerms] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [selectedDeliveryTerm, setSelectedDeliveryTerm] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`/Supplier/${params.id}`)
      .then((response) => {
        const tempSupplier = response.data.data;
        const formattedSupplier = {
          id: tempSupplier.id,
          name: tempSupplier.name,
          countryId: tempSupplier.country.id,
          phone: tempSupplier.phone,
          email: tempSupplier.email,
          website: tempSupplier.website,
          paymentTermId: tempSupplier.paymentTerm.id,
          deliveryTermId: tempSupplier.deliveryTerm.id,
          contactPerson: tempSupplier.contactPerson,
          contactPersonMobile: tempSupplier.contactPersonMobile,
          address: tempSupplier.address,
          statusId: tempSupplier.status.id,
          transDateTime: tempSupplier.transDateTime,
          userId: tempSupplier.user.id,
          remarks: tempSupplier.remarks
        };
        setSupplier(formattedSupplier);
        setSelectedCountry(response.data.data.country.id);
        setSelectedPaymentTerm(response.data.data.paymentTerm.id);
        setSelectedDeliveryTerm(response.data.data.deliveryTerm.id);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Country/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setCountries(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/PaymentTerm/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setPaymentTerms(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/DeliveryTerm/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setDeliveryTerms(tempList);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const isValid =
      Object.entries(supplier).filter(([key, val]) => key !== 'remarks' && (val === '' || !val)).length === 0;
    setCheck(isValid);
  }, [supplier]);

  function handleEditInputChange(e) {
    setSupplier({ ...supplier, [e.target.name]: e.target.value || e.value });
  }

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <form className='supplier'>
        {!deliveryTerms || !paymentTerms || !countries || !supplier ? (
          <ProgressSpinner />
        ) : (
          <div className='supplier__content'>
            <div className='supplier__content__right'>
              <span className='Title'>البيانات الاساسية للمورد</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  اسم المورد<span style={Style}>*</span>
                </label>
                <InputText name='name' value={supplier.name} onChange={handleEditInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  بلد المورد<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='countryId'
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e.value);
                    handleEditInputChange(e);
                  }}
                  options={countries}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر بلد المورد'
                />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  عنوان المورد<span style={Style}>*</span>
                </label>
                <InputText name='address' value={supplier.address} onChange={handleEditInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  شروط الدفع<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='paymentTermId'
                  value={selectedPaymentTerm}
                  onChange={(e) => {
                    setSelectedPaymentTerm(e.value);
                    handleEditInputChange(e);
                  }}
                  options={paymentTerms}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر شروط الدفع'
                />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  طريقة الشحن<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='deliveryTermId'
                  value={selectedDeliveryTerm}
                  onChange={(e) => {
                    setSelectedDeliveryTerm(e.value);
                    handleEditInputChange(e);
                  }}
                  options={deliveryTerms}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر طريقة الشحن'
                />
              </div>
              <span className='Title'>بيانات التواصل مع المورد</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  رقم التواصل<span style={Style}>*</span>
                </label>
                <InputText name='phone' value={supplier.phone} onChange={handleEditInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  البريد الالكتروني<span style={Style}>*</span>
                </label>
                <InputText name='email' value={supplier.email} onChange={handleEditInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  الموقع الالكتروني<span style={Style}>*</span>
                </label>
                <InputText name='website' value={supplier.website} onChange={handleEditInputChange} />
              </div>
            </div>
            <div className='supplier__content__left'>
              <span className='Title'>بيانات الموفوض</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  اسم المفوض<span style={Style}>*</span>
                </label>
                <InputText name='contactPerson' value={supplier.contactPerson} onChange={handleEditInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  رقم التواصل مع المفوض<span style={Style}>*</span>
                </label>
                <InputText
                  name='contactPersonMobile'
                  value={supplier.contactPersonMobile}
                  onChange={handleEditInputChange}
                />
              </div>
              <span className='Title'>اخرى</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>ملاحظات</label>
                <InputTextarea
                  placeholder='ملاحظات عن المورد بشكل عام'
                  name='remarks'
                  value={supplier.remarks}
                  onChange={handleEditInputChange}
                  rows={3}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSupplier1);

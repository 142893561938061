import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Chip } from 'primereact/chip';
import Tabs from '../../../components/Tabs/Tabs';
import { axiosInstance } from '../../../global';

import ProductsDetailsContent from './ProductsDetailsContent';

import ProductsPricesTable from '../../../components/Tables/ProductsPricesTable';

import './ProductsDetails.scss';
import ProductStagesTable from '../../../components/Tables/ProductStagesTable';
import CustomButton from '../../../components/components/CustomButton';

import ProductInstallmentsType from './ProductInstallmentsType';
import { fill_product } from '../../../redux/product/productActions';
import { useToast } from '../../../context/ToastContext';

function ProductsDetails({ token, fill_product }) {
  const toast = useToast();

  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const [product, setProduct] = useState();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/Product/${params.id}`)
      .then((response) => {
        setProduct(response.data.data);
      })
      .catch((error) => {

      });
  }, []);

  function showConfirmDeleteProduct(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف المنتج؟',
      header: 'تأكيد حذف المنتج',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteProduct,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteProduct(e) {
    axiosInstance
      .delete(`/Product/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف المنتج',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/products'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف المنتج',
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <div className=' main w-full'>
      <div className='details__header1'>
        <div className='details__header1__right'>
          <CustomButton
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>تفاصيل المنتج</span>
          <Chip label={product?.status.name} />
        </div>

        <div className='details__header__left'>
          {params.type === 'device' && (
            <>
              <CustomButton
                className='details__header1__left__btn'
                onClick={() => {
                  fill_product(product);
                  navigate(`/products/addProduct/${params.id}`);
                }}
                // onClick={() => navigate(`/product/Product`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل البيانات المنتج
              </CustomButton>
              <CustomButton
                className='details__header1__left__btn'
                onClick={showConfirmDeleteProduct}
                icon='pi pi-trash'
                style={Style2}
                text
              >
                حذف المنتج
              </CustomButton>
            </>
          )}
          {params.type === 'spare' && (
            <>
              <CustomButton
                className='details__header1__left__btn'
                onClick={() => navigate(`/productSpares/editSpareParts/${params.id}`)}
                // onClick={() => navigate(`/product/Product`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل البيانات المنتج
              </CustomButton>
              <CustomButton
                className='details__header1__left__btn'
                onClick={showConfirmDeleteProduct}
                icon='pi pi-trash'
                style={Style2}
                text
              >
                حذف المنتج
              </CustomButton>
            </>
          )}
          {params.type === 'customerView' && (
            <CustomButton
              className='details__header1__left__btn'
              onClick={() => navigate(`/productSpares/editSpareParts/${params.id}`)}
              // onClick={() => navigate(`/product/Product`)}
              icon='pi pi-pencil'
              style={Style1}
              text
            >
              تعديل البيانات المنتج
            </CustomButton>
          )}
        </div>
      </div>
      <div className='mt-3'>
        {params.type === 'device' && (
          <Tabs
            headers={['تفاصيل المنتج', 'اسعار  المنتج', 'خطط تقسيط المنتج', 'مراحل المنتج']}
            contents={[
              <ProductsDetailsContent product={product} iscutomerView={false} />,
              <ProductsPricesTable
                columns={[
                  { header: 'المحافظة', field: 'governorate', isChip: false },
                  { header: 'العملة', field: 'currency', isChip: false },
                  { header: 'التاريخ ', field: 'date', isChip: false },
                  { header: 'السعر', field: 'price', isChip: false },
                  { header: 'العمولة', field: 'commission', isChip: false },
                  { header: 'ملاحظات ', field: 'remarks', isChip: false }
                ]}
                urlName='ProductsPricesListDetail'
                headerTitle='قائمة أسعار المنتج'
              />,
              <ProductInstallmentsType />,
              <ProductStagesTable />
            ]}
          />
        )}
        {params.type === 'spare'|| params.type === 'stage' && (
          <Tabs
            headers={['تفاصيل المنتج', 'اسعار  المنتج']}
            contents={[
              <ProductsDetailsContent product={product} iscutomerView={false} />,
              <ProductsPricesTable
                columns={[
                  { header: 'المحافظة', field: 'governorate.name', isChip: false },
                  { header: 'العملة', field: 'currency.name', isChip: false },
                  { header: 'التاريخ ', field: 'date', isChip: false },
                  { header: 'السعر', field: 'price', isChip: false },
                  { header: 'العمولة', field: 'commission', isChip: false },
                  { header: 'ملاحظات ', field: 'remarks', isChip: false }
                ]}
                urlName='ProductsPricesListDetail'
                headerTitle='قائمة أسعار المنتج'
              />
            ]}
          />
        )}
        {params.type === 'customerView' && (
          <>
            <ProductsDetailsContent
              product={product}
              iscutomerView={true}
              customerId={params.custId}
              deviceId={product?.id}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default connect(null, (dispatch) => ({
  fill_product: (product) => dispatch(fill_product(product))
}))(ProductsDetails);

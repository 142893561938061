import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

const useFetch = (endpoint: string, isLazy: boolean = false) => {
  const fetchStatus = async () => {
    const response = await axiosInstance.get(endpoint);
    return response.data;
  };

  const {
    state: { isLoading, error, data },
    lazyApiCall
  } = useApiState({ apiCall: fetchStatus, isLazy: isLazy });

  return {
    isLoading,
    error,
    data,
    lazyApiCall
  };
};

export default useFetch;

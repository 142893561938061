import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

interface POSDetailsTablesProps {
  PosId: string | undefined;
}

function POSDetailsTables({ PosId }: POSDetailsTablesProps) {
  const navigate = useNavigate();

  const SalesOrderColumns = [
    { header: ' كود امر البيع', field: 'code', isChip: false },
    { header: 'البائع', field: 'employeeName', isChip: false },
    { header: 'تاريخ البيع', field: 'creationDate', isDate: true },
    { header: 'نوع امر البيع', field: 'salesOrderTypeName', isChip: false },
    { header: 'الكمية المباعة', field: 'nbofItems', isChip: false },
    { header: 'مبلغ البيع الصافى', field: 'totalNetAmount', isChip: false }
  ];
  const WHSectionsColumns = [
    { header: ' تصنيف المنتج', field: 'productCategory', isChip: false },
    { header: 'اسم المنتج', field: 'product', isChip: false },
    { header: 'سعر المنتج', field: 'productPrice', isChip: false },
    { header: 'إجمالى الكمية فى القسم', field: 'stockQty', isChip: false }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: false,
          label: 'سجل مبيعات المنفذ',
          tableHeader: 'قائمة مبيعات منفذ البيع',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: `/SalesOrder/list/byPointOfSale/${PosId}`,
          edit: false,
          columns: SalesOrderColumns,
          emptyStateUrl: '',
          entityName: 'امر البيع',
          rowOnClick: (rowData) => {
            // navigate(`/warehouses/transactions/details/${rowData.id}`);
          },
          headerButtons: []
        },
        {
          isHidden: false,
          label: 'رصيد منتجات المنفذ',
          tableHeader: 'رصيد المنتجات داخل منفذ البيع',
          dataKey: 'tab2',
          emptyStateButtonClick: () => {},
          apiUrl: `/PointOfSale/pointOfSaleItems/${PosId}`,
          edit: true,
          columns: WHSectionsColumns,
          emptyStateUrl: '',
          entityName: 'قسم المخزن',
          rowOnClick: (rowData) => {
            // navigate(`/warehouses/transactions/details/${rowData.id}`);
          },
          headerButtons: []
        }
      ]}
      withHeader={false}
      headerTitle={''}
      headerIcon={''}
    />
  );
}

export default POSDetailsTables;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';

import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import { axiosInstance } from '../../../global';
import './TransactionDetails.scss';
import { fill_transaction } from '../../../redux/warehouse/warehouseActions';
import { useToast } from '../../../context/ToastContext';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';

function TransactionDetails({ token, fill_transaction }) {
  const toast = useToast();

  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/WarehouseTransactionsGen/${params.id}`)
      .then((responseGen) => {
        axiosInstance
          .get(`/WarehouseTransactionsDet/allByGen/${params.id}`)
          .then((responseDet) => {
            setTransaction({ ...responseGen.data.data, products: responseDet.data.data });
            setLoading(false);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);

  // useEffect(() => {
  //   if (!loading) fill_transaction(transaction);
  // }, [loading]);

  function showConfirmDeleteTransaction(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف الاذن؟',
      header: 'تأكيد حذف الاذن',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteTransaction,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteTransaction(e) {
    axiosInstance
      .delete(`/WarehouseTransactions/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف الاذن',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/warehouses/transactions'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف الاذن',
          detail: '',
          life: 3000
        });
      });
  }

  function getSrcDestName(rowData, type) {
    if (type === 1) {
      switch (rowData.sourceName) {
        case 'Stock':
          return rowData.destinationWarehouseName;
        case 'Supplier':
          return rowData.supplierName;
        case 'Customer':
          return rowData.customerFullName;
        case 'PhysicalCount':
          return 'ﻻ يوجد معلومات';
        case 'Waste':
          return 'ﻻ يوجد معلومات';
        default:
          return 'ﻻ يوجد معلومات';
      }
    } else {
      switch (rowData.destinationName) {
        case 'Stock':
          return rowData.warehouseName;
        case 'Supplier':
          return rowData.supplierName;
        case 'Customer':
          return rowData.customerFullName;
        case 'PhysicalCount':
          return 'ﻻ يوجد معلومات';
        case 'Waste':
          return 'ﻻ يوجد معلومات';
        default:
          return 'ﻻ يوجد معلومات';
      }
    }
  }

  return (
    <div className=' main w-full'>
      <div className='details__header1 bg-white'>
        <div className='details__header1__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>تفاصيل الاذن</span>
        </div>

        <div className='details__header__left'>
          <Link to={`/warehouses/transactions/edit/${params.id}`}>
            <Button className='details__header1__left__btn' icon='pi pi-pencil' style={Style1} text>
              تعديل الاذن
            </Button>
          </Link>
          <Button
            className='details__header1__left__btn'
            onClick={showConfirmDeleteTransaction}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف الاذن
          </Button>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <div className='mt-5'>
          <div className='details'>
            <div className='customerdetails__header__card'>
              <HeaderCardTS
                title={'تاريخ اﻻنشاء'}
                imageUrl={'/images/calendar.svg'}
                num={new Date(transaction.transDateTime).toLocaleDateString('en-GB')}
                color={'#13DE8230'}
              />
              <HeaderCardTS
                title={'نوع الاذن'}
                num={transaction.transTypeName}
                imageUrl={'/images/briefcase.svg'}
                color={'#F9D8D4'}
              />
              <HeaderCardTS
                title={'حالة الاذن'}
                num={transaction.approved ? 'تم اﻻستلام' : 'لم يتم اﻻستلام'}
                imageUrl={'/images/briefcase.svg'}
                color={'#F9D8D4'}
              />
              <HeaderCardTS
                title={'منشئ الاذن'}
                num={transaction.userFirstName || 'غير متوفر'}
                imageUrl={'/images/drop_box.svg'}
                color={'#F9D8D4'}
              />
            </div>
            <div className='transaction-details'>
              <DetailsSheetGrid
                headers={['تاريخ اﻻنشاء', 'رقم العملية', 'منشئ العملية']}
                contents={[
                  new Date(transaction.transDateTime).toLocaleDateString('en-GB'),
                  transaction.transNb,
                  transaction.userFirstName || 'غير متوفر'
                  //  revesionData.type.name,
                ]}
                title={'بيانات الحركة'}
              />
              <DetailsSheetGrid
                headers={[
                  'اسم اﻻذن',
                  'نوع اﻻذن',
                  'المخزن',
                  'نوع المستقبل',
                  'المستقبل',
                  'نوع المصدر',
                  'المصدر',
                  'ملاحظات اخرى'
                ]}
                contents={[
                  transaction.name,
                  transaction.transTypeName,
                  transaction.warehouseName,
                  transaction.destinationName,
                  getSrcDestName(transaction, 2),
                  transaction.sourceName,
                  getSrcDestName(transaction, 1),
                  transaction.remarks
                ]}
                title={'بيانات اﻻتصال'}
              />
              <span className='transaction-details__title mt-5'>بيان المنتجات</span>
              <DataTable
                value={transaction.products}
                paginator
                showGridlines
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                dataKey='id'
                className='w-full p-2'
                emptyMessage={
                  <div className='w-full flex justify-content-center'>
                    <span className='text-lg font-bold'>ﻻ يوجد منتجات لهذا الطلب</span>
                  </div>
                }
              >
                <Column header='اسم المنتج' field='product.name' />
                <Column header='الكمية' field='qty' />
                <Column header='سعر الوحدة' field='price' />
                <Column header='اﻻجمالي' field='amount' />
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_transaction: (transaction) => dispatch(fill_transaction(transaction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);

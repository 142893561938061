import React, { useEffect, useMemo, useState } from 'react';
import { DataTable, DataTableRowEditCompleteEvent, DataTableRowEditEvent } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import CustomDropdown from '../Dropdown/CustomDropDown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Spin } from 'antd';

interface DataTableValue {
  [key: string]: any;
}

export interface EditableColumnProps<T = DataTableValue> {
  field: keyof T & string;
  header: string;
  editable?: boolean;
  isHandleEdit?: boolean;
  DynamicEditable?: (rowData: T) => boolean;
  type?: 'text' | 'dropdown' | 'cellDropdown' | 'boolean' | 'number' | 'date' | 'checkBox';
  options?: { name: string; id: any }[];
  idField?: string;
  labelField?: string;
  width?: string;
  hidden?: boolean;
}

interface GenericEditableDataTableProps<T extends DataTableValue> {
  data: T[];
  columns: EditableColumnProps[];
  isLoading: boolean;
  onRowUpdate: (updatedData: T[]) => void;
  onRowRemove?: (index: number) => void;
  dynamicDisable?: boolean;
  allowDelete?: boolean;
}

const GenericEditableDataTable = <T extends DataTableValue>({
                                                              data,
                                                              columns,
                                                              isLoading,
                                                              onRowUpdate,
                                                              onRowRemove,
                                                              dynamicDisable = false,
                                                              allowDelete = true
                                                            }: GenericEditableDataTableProps<T>) => {
  const [tableData, setTableData] = useState<T[]>(data);

  useEffect(() => {
    // debugger;
    setTableData(data);
  }, [data]);

  // const handleEdit = (field: keyof T, value: any, e: DataTableRowEditEvent) => {
  //   // Update the specific cell value during editing
  //   const { index } = e;
  //   const updatedData = [...tableData];
  //   updatedData[index] = { ...updatedData[index], [field]: value };
  //   setTableData(updatedData);
  //   onRowUpdate(updatedData); // Trigger live update
  // };

  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    const { newData, index } = e;
    const updatedData = [...tableData] as T[];
    updatedData[index] = { ...updatedData[index], ...newData } as T;
    setTableData(updatedData);
    onRowUpdate(updatedData);
  };

  const removeRow = (index: number) => {
    // Call the parent component's onRowRemove function if provided
    if (onRowRemove) {
      onRowRemove(index); // Removes the row from parent state
    }
    // Update local tableData state
    const updatedData = tableData.filter((_, i) => i !== index);
    setTableData(updatedData);
    // If onRowUpdate is necessary here, call it to keep parent component in sync
    onRowUpdate(updatedData);
  };

  const textEditor = (options: ColumnEditorOptions) => (
    <InputText type="text" value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />
  );

  const dropdownEditor = (
    options: ColumnEditorOptions,
    value: string | undefined,
    label: string | undefined,
    columnOptions?: {
      name: string;
      id: any;
    }[]
  ) => (
    <CustomDropdown
      value={options.value}
      optionLabel={label ?? 'name'}
      optionValue={value ?? 'id'}
      options={columnOptions ?? []}
      onChange={(e) => options.editorCallback?.(e.value)}
      placeholder="Select"
    />
  );

  const dropdownCellEditor = (
    options: ColumnEditorOptions,
    value: string | undefined,
    label: string | undefined,
    columnOptions?: {
      name: string;
      id: any;
    }[]
  ) => (
    <CustomDropdown
      value={options.value}
      optionLabel={label ?? 'name'}
      optionValue={value ?? 'id'}
      options={columnOptions ?? []}
      onChange={(e) => {
        // handleEdit(options.field, e.value, { index: options.rowIndex } as DataTableRowEditEvent);
        options.editorCallback?.(e.value);
      }}
      placeholder="Select"
    />
  );

  const checkBoxEditor = (options: ColumnEditorOptions, columnOptions?: { name: string; id: any }[]) => (
    <Checkbox checked={options.value} onChange={(e) => options.editorCallback?.(e.checked)} />
  );

  const numberEditor = (options: ColumnEditorOptions) => (
    <InputNumber
      value={options.value}
      onValueChange={(e) => options.editorCallback?.(e.value)}
      style={{ display: 'flex', gap: '1rem' }}
    />
  );

  const dateEditor = (options: ColumnEditorOptions) => (
    <Calendar value={options.value} onChange={(e) => options.editorCallback?.(e.value)} dateFormat="yy-mm-dd" />
  );

  const renderEditor = (options: ColumnEditorOptions, column: EditableColumnProps<T>, rowData: T) => {
    // const isEditable = typeof column.editable === 'function' ? column.DynamicEditable(rowData) : column.editable;
    const isEditable = column.DynamicEditable ? column.DynamicEditable(rowData) : column.editable;
    if (isEditable) {
      switch (column.type) {
        case 'cellDropdown':
          return dropdownCellEditor(options, column.idField, column.labelField, column.options);
        case 'dropdown':
          return dropdownEditor(options, column.idField, column.labelField, column.options);
        case 'checkBox':
          return checkBoxEditor(options);
        case 'number':
          return numberEditor(options);
        case 'boolean':
          return (
            <Checkbox
              checked={options.value}
              onChange={(e) => {
                // handleEdit(options.field, e.checked, { index: options.rowIndex } as DataTableRowEditEvent);
                options.editorCallback?.(e.checked);
              }}
            />
          );
        case 'date':
          return dateEditor(options);
        case 'text':
        default:
          return textEditor(options);
      }
    } else {
      if (column.type === 'dropdown' && column.options) {
        const selectedOption = column.options.find((opt) => opt.id === options.value);
        return <span>{selectedOption ? selectedOption.name : options.value}</span>;
      }

      if (column.type === 'boolean') {
        return <Checkbox checked={options.value} disabled />;
      }

      return <span>{options.value}</span>;
    }
  };

  const memoizedColumns = useMemo(
    () =>
      columns
        .filter((col) => !col.hidden)
        .map((col) => (
          <Column
            key={col.field}
            field={col.field}
            body={(rowData) => {
              if ((col.type === 'dropdown' || col.type === 'cellDropdown') && col.options) {
                // Use idField and labelField with fallback defaults
                const idField = col.idField || 'id';
                const labelField = col.labelField || 'name';

                // Find the option that matches the rowData's value
                const selectedOption = col.options.find(
                  (opt) => opt[idField as keyof typeof opt] === rowData[col.field]
                );

                // Return the label if found, else show raw value
                return selectedOption ? selectedOption[labelField as keyof typeof selectedOption] : rowData[col.field];
              }

              if (col.type === 'boolean') {
                return <Checkbox checked={rowData[col.field]} disabled />;
              }

              // For non-dropdown columns, display the value directly
              return rowData[col.field];
            }}
            header={col.header}
            // editor={(options) => renderEditor(options, col)}
            editor={(options) => renderEditor(options, col, options.rowData)}
            style={{ width: col.width || 'auto', textAlign: 'right' }}
          />
        )),
    [columns]
  );

  return (
    <div>
      {isLoading ? (
        <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      ) : (
        <DataTable
          value={tableData}
          editMode="row"
          dataKey="id"
          lazy
          emptyMessage={'لا يوجد بيانات'}
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ tableLayout: 'fixed' }}
        >
          {memoizedColumns}
          {dynamicDisable === false && (
            <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} />
          )}
          {allowDelete === true && <Column
            body={(rowData, { rowIndex }) => (
              <Button
                icon="pi pi-trash"
                className="p-button-danger"
                type={'button'}
                onClick={() => removeRow(rowIndex)}
              />
            )}
            headerStyle={{ width: '5rem' }}
            bodyStyle={{ textAlign: 'center' }}
          />
          }
        </DataTable>
      )}
    </div>
  );
};

export default GenericEditableDataTable;

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import styles from './AddEditSalesOrder.module.scss';
import GenericEditableDataTable, {
  EditableColumnProps
} from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import useFetch from '../../../hooks/useFetch';
import CustomButton from '../../../components/components/Button/CustomButton';
import useProduct from '../../../hooks/useProduct';
import SalesOrderDetailsCard from './components/SalesOrderDetailsCard';
import { useToast } from '../../../context/ToastContext';

interface ProductRow {
  productId: number | null;
  productName: string | null;
  discountTypeTable: number | null;
  stockQty: number | null;
  qty: number | null;
  price: number | null;
  discountPercent: number | null;
  discountAmount: number | null;
  totalPrice: number | null;
}

interface SalesOrderItemsProps {
  isEditing: boolean;
}

const SalesOrderItems = ({ isEditing }: SalesOrderItemsProps) => {
  const toast = useToast();

  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const discountType = watch('discountType');
  const discountAmount = watch('discountOnProducts');
  const InvoiceCode = watch('invoiceReference');
  const ispayTotalInvoice = watch('payTotalInvoice');
  const totalAmountss = watch('totalAmount');
  const totalDiscountss = watch('mytotalDiscount');
  const reqAmount = watch('requstedAmount');
  const isEdit = watch('isEdit');
  const pointOfSaleId = watch('pointOfSalesId');
  const { data: availableProducts, isLoading: isavailableProducts } = useFetch(
    `/PointOfSale/dropdown/availableProducts/byPointOfSaleId/${pointOfSaleId}`
  );
  const [myFormattedData, setMyFormattedData] = useState([]);
  // const [myProductsData, setMyProductsData] = useState([]);

  useEffect(() => {
    if (availableProducts) {
      const formattedArray = availableProducts?.data.map((item: any) => ({
        id: item.productID,
        name: item.product
      }));

      setMyFormattedData(formattedArray);
    }
  }, [availableProducts]);

  const { productData: stageType, isProductLoading } = useProduct('Stages');

  const [productTableData, setProductTableData] = useState<ProductRow[]>([]);
  const [firstTime, setfirstTime] = useState(false);
  const types = [
    { name: 'نسبة مئوية', id: 1 },
    { name: 'مبلغ', id: 2 }
  ];

  const addNewProductRow = () => {
    const newRow: ProductRow = {
      productId: null,
      productName: null,
      discountTypeTable: discountType,
      qty: null,
      stockQty: null,
      price: null,
      discountPercent: discountType === 1 ? discountAmount : null,
      discountAmount: discountType === 2 ? discountAmount : null,
      totalPrice: null
    };
    setProductTableData((prevData) => [...prevData, newRow]);
  };

  const calculateTotalPrice = (row: ProductRow): number => {
    const totalPrice = (row.qty || 0) * (row.price || 0);
    if (row.discountTypeTable) {
      if (row.discountTypeTable === 1) {
        const percentage = row.discountPercent ? row.discountPercent / 100 : 0;
        const minusedAmount = totalPrice * percentage;
        return totalPrice - minusedAmount;
      } else {
        return totalPrice - (row.discountAmount ? row.discountAmount : 0);
      }
    } else {
      return totalPrice;
    }
  };

  const handleProductRowRemove = (index: number) => {
    setProductTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleProductRowUpdate = (updatedData: ProductRow[]) => {
    const updatedRows = updatedData.map((row) => {
      const options = availableProducts?.data ?? [];
      const selectedItem = options.find((option: any) => option.productID === row.productId);
      const MaxstockQty = selectedItem ? selectedItem.stockQty : 0;
      const myPrice = selectedItem ? selectedItem.productPrice : 0;
      if (myPrice) {
        row.price = myPrice;
      }
      const myQty = row.qty ? row.qty : 0;
      if (myQty > MaxstockQty) {
        row.qty = MaxstockQty;
        toast.current.show({
          type: 'error',
          summary: 'خطأ في البيانات',
          detail: 'لا يمكن اضافة كمية اكبر من الكمية الحالية فى المخزن',
          life: 5000
        });
      }

      return {
        ...row,
        productName: selectedItem ? selectedItem.product : null, // Assign label to itemName
        // price: selectedItem ? selectedItem.productPrice : 0,
        stockQty: selectedItem ? selectedItem.stockQty : 0,
        totalPrice: calculateTotalPrice(row) // Calculate total price
      };
    });
    const totalRequestedAmount = updatedRows.reduce((sum, row) => sum + row.totalPrice, 0);
    const totalAmount = updatedRows.reduce((sum, row) => sum + (row.qty || 0) * (row.price || 0), 0);
    setProductTableData(updatedRows);
    setValue('items', updatedRows);
    setValue('requstedAmount', totalRequestedAmount);
    setValue('totalAmount', totalAmount);
    setValue('mytotalDiscount', totalAmount - totalRequestedAmount);
  };

  useEffect(() => {
    if (ispayTotalInvoice) {
      setValue('paidAmount', reqAmount);
    } else {
      setValue('paidAmount', 0);
    }
  }, [ispayTotalInvoice, reqAmount]);

  useEffect(() => {
    const productsStock = watch('items');
    if (productsStock) {
      setProductTableData(productsStock);
    }
  }, [watch('items')]);

  const productsColumn: EditableColumnProps[] = [
    {
      field: 'productId',
      header: 'المنتج',
      editable: true,
      type: 'dropdown',
      options: myFormattedData ?? [],
      idField: 'id',
      labelField: 'name'
    },
    { field: 'qty', header: 'الكمية', editable: true, type: 'number' },
    { field: 'price', header: 'السعر', editable: false, type: 'number' },
    {
      field: 'discountPercent',
      header: 'الخصم المخصص (نسبة)',
      editable: false,
      type: 'number'
    },
    {
      field: 'discountAmount',
      header: 'الخصم المخصص (مبلغ)',
      editable: false,
      type: 'number'
    },
    {
      field: 'discountTypeTable',
      header: 'نوع الخصم',
      editable: false,
      type: 'dropdown',
      options: types ?? [],
      idField: 'id',
      labelField: 'name'
    },
    {
      field: 'totalPrice',
      header: 'السعر الكلى',
      editable: false,
      type: 'number'
    }
  ];

  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <Message
          severity='success'
          icon={null}
          content={<p className={'text-black-alpha-90 font-semibold'}>منتجات الفاتورة</p>}
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />

        <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
          <label className={styles.detailsTitle}>{`بيان منتجات الفاتورة`}</label>
          <CustomButton label={'إضف منتج'} type={'button'} onClick={addNewProductRow} />
        </div>

        <GenericEditableDataTable<ProductRow>
          data={productTableData}
          columns={productsColumn}
          isLoading={isProductLoading}
          onRowUpdate={handleProductRowUpdate}
          onRowRemove={handleProductRowRemove}
        />

        <SalesOrderDetailsCard
          code={InvoiceCode ?? InvoiceCode}
          date={new Date()}
          InvoiceReqAmount={reqAmount ? reqAmount : 0}
          InvoiceTotalAmount={totalAmountss ? totalAmountss : 0}
          totalDiscountAmount={totalDiscountss ? totalDiscountss : 0}
        />
      </Card>
    </>
  );
};

export default SalesOrderItems;

import useModal from '../../hooks/useModal';
import { genericSchemeForNames } from '../../components/components/Form/Scheme';
import GenericPage from '../../components/components/GenericPage/GenericPage';
import React, { useRef } from 'react';
import GenericForm from '../../components/Forms/GenericForm';
import GenericModal from '../../components/components/Modal/GenericModal';
import CustomFormField from '../../components/components/Form/CustomFormField';
import * as yup from 'yup';
import { useToast } from '../../context/ToastContext';
import InvoiceTypeForm from './Forms/InvoiceTypeForm';

const statusSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  isActive: yup.boolean(),
  remarks: yup.string().nullable()
});

function AdminLists() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isProductsTypeVisitable,
    openModal: productsTypeOpenModal,
    closeModal: productsTypeCloseModal,
    control: productsTypeControl,
    handleSubmit: productsTypeSubmit,
    errors: productsTypeErrors,
    setValue: productsTypeSetValue,
    loading: productsTypeLoading,
    setLoading: productsTypeSetLoading,
    watch: productsTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ProductsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isStatusVisitable,
    openModal: statusOpenModal,
    closeModal: statusCloseModal,
    control: statusControl,
    handleSubmit: statusSubmit,
    errors: statusErrors,
    setValue: statusSetValue,
    loading: statusLoading,
    setLoading: statusSetLoading,
    watch: statusWatch
  } = useModal({ remarks: ' ', names: [] }, '/Status', fetchTabData, statusSchema, toast);
  const isEditMode = statusWatch('id') != null;

  const {
    isVisible: isTransTypeVisitable,
    openModal: transTypeOpenModal,
    closeModal: transTypeCloseModal,
    control: transTypeControl,
    handleSubmit: transTypeSubmit,
    errors: transTypeErrors,
    setValue: transTypeSetValue,
    loading: transTypeLoading,
    setLoading: transTypeSetLoading,
    watch: transTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/TransType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isRevesionTypeVisitable,
    openModal: revesionTypeOpenModal,
    closeModal: revesionTypeCloseModal,
    control: revesionTypeControl,
    handleSubmit: revesionTypeSubmit,
    errors: revesionTypeErrors,
    setValue: revesionTypeSetValue,
    loading: revesionTypeLoading,
    setLoading: revesionTypeSetLoading,
    watch: revesionTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/RevisionsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isInvoiceTypeVisitable,
    openModal: invoiceTypeOpenModal,
    closeModal: invoiceTypeCloseModal,
    control: invoiceTypeControl,
    handleSubmit: invoiceTypeSubmit,
    errors: invoiceTypeErrors,
    setValue: invoiceTypeSetValue,
    loading: invoiceTypeLoading,
    setLoading: invoiceTypeSetLoading,
    watch: invoiceTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/InvoicesType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isSalesOrderVisitable,
    openModal: salesOrderOpenModal,
    closeModal: salesOrderCloseModal,
    control: salesOrderControl,
    handleSubmit: salesOrderSubmit,
    errors: salesOrderErrors,
    setValue: salesOrderSetValue,
    loading: salesOrderLoading,
    setLoading: salesOrderSetLoading,
    watch: salesOrderWatch
  } = useModal({ remarks: ' ', names: [] }, '/SalesOrdersType', fetchTabData, genericSchemeForNames, toast);
  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'بيانات انواع المنتجات',
            emptyStateButtonClick: () => {
              productsTypeOpenModal();
            },
            dataKey: 'ProductsType',
            columns: [
              { header: 'انواع المنتجات', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع منتجات',
                onClick: () => {
                  productsTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              productsTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ProductsType/list',
            entityName: 'نوع المنتج',
            tableHeader: 'جدول بيانات انواع المنتجات',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات الحالات',
            emptyStateButtonClick: () => {
              statusOpenModal();
            },
            dataKey: 'Status',
            columns: [
              { header: 'اسماء الحالات', field: 'name', isChip: false },
              { header: 'انواع الحالات', field: 'isActive', isBoolean: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع حالات',
                onClick: () => {
                  statusOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              statusOpenModal({
                id: rowData.id,
                name: rowData.name,
                isActive: rowData.isActive,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Status/all',
            entityName: 'نوع الحاله',
            tableHeader: 'جدول بيانات الحالات',
            emptyStateUrl: ''
          },
          {
            label: ' انواع اذونات الصرف',
            emptyStateButtonClick: () => {
              transTypeOpenModal();
            },
            dataKey: 'TransType',
            columns: [
              { header: 'اذونات الصرف', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع اذونات صرف',
                onClick: () => {
                  transTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              transTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'TransType/list',
            entityName: 'نوع إذن الصرف',
            tableHeader: 'جدول بيانات انواع اذونات الصرف',
            emptyStateUrl: ''
          },
          {
            label: ' انواع المراجعة',
            emptyStateButtonClick: () => {
              revesionTypeOpenModal();
            },
            dataKey: 'RevesionType',
            columns: [
              { header: 'انواع المراجعة', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع مراجعة',
                onClick: () => {
                  revesionTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              revesionTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'RevisionsType/list',
            entityName: 'نوع المراجعة',
            tableHeader: 'جدول بيانات انواع المراجعات',
            emptyStateUrl: ''
          },
          {
            label: ' انواع الفواتير',
            emptyStateButtonClick: () => {
              invoiceTypeOpenModal();
            },
            dataKey: 'InvoiceType',
            columns: [
              { header: 'انواع الفواتير', field: 'name', isChip: false },
              { header: 'الكود', field: 'code', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع فواتير',
                onClick: () => {
                  invoiceTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              invoiceTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                code: rowData.code,
                statusId: rowData.statusId,
                remarks: rowData.remarks
              });
            },
            apiUrl: '/InvoicesType/list',
            entityName: 'نوع الفاتورة',
            tableHeader: 'جدول بيانات انواع الفواتير',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات انواع أمر البيع',
            emptyStateButtonClick: () => {
              salesOrderOpenModal();
            },
            dataKey: 'salesOrder',
            columns: [
              { header: 'انواع أمر البيع', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع أمر بيع',
                onClick: () => {
                  salesOrderOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              salesOrderOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: '/SalesOrdersType/list',
            entityName: 'نوع أمر البيع',
            tableHeader: 'جدول بيانات انواع أمر البيع',
            emptyStateUrl: ''
          }
        ]}
        headerTitle={'البيانات الاساسيه'}
        headerIcon={'../images/productbox.svg'}
      />

      <GenericModal
        headerTitle={'إضافة انواع منتجات جديدة'}
        isVisible={isProductsTypeVisitable}
        onHide={productsTypeCloseModal}
        handleSubmit={productsTypeSubmit}
        toast={toast}
        loading={productsTypeLoading}
        isEditMode={productsTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={productsTypeWatch('id')}
          control={productsTypeControl}
          watch={productsTypeWatch}
          errors={productsTypeErrors}
          setValue={productsTypeSetValue}
          extraProps={{
            entityName: 'نوع المنتج'
          }}
        />
      </GenericModal>

      <GenericModal
        key={statusWatch('id')}
        headerTitle={'إضافة انواع منتجات جديدة'}
        isVisible={isStatusVisitable}
        onHide={statusCloseModal}
        handleSubmit={statusSubmit}
        toast={toast}
        loading={statusLoading}
        isEditMode={statusWatch('id') !== undefined}
      >
        <CustomFormField
          name={isEditMode ? 'name' : 'names'}
          label={isEditMode ? `اسم الحاله` : `اضف اسم/اسماء حالات الجديدة`}
          control={statusControl}
          errors={statusErrors}
          type={isEditMode ? 'text' : 'multi'}
          required={true}
          placeholder={isEditMode ? `اسم الحاله` : `اضف اسم/اسماء حالات الجديدة`}
        />

        <CustomFormField
          name={'isActive'}
          label={'نوع الحالة'}
          control={statusControl}
          errors={statusErrors}
          type={'toggle'}
        />
        <CustomFormField
          name={'remarks'}
          label={'ملاحظات'}
          control={statusControl}
          errors={statusErrors}
          type={'textarea'}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع إذن صرف جديدة'}
        isVisible={isTransTypeVisitable}
        onHide={transTypeCloseModal}
        handleSubmit={transTypeSubmit}
        toast={toast}
        loading={transTypeLoading}
        isEditMode={transTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={transTypeWatch('id')}
          control={transTypeControl}
          watch={transTypeWatch}
          errors={transTypeErrors}
          setValue={transTypeSetValue}
          extraProps={{
            entityName: 'نوع إذن الصرف'
          }}
        />
      </GenericModal>


      <GenericModal
        headerTitle={'إضافة انواع مراجعة جديدة'}
        isVisible={isRevesionTypeVisitable}
        onHide={revesionTypeCloseModal}
        handleSubmit={revesionTypeSubmit}
        toast={toast}
        loading={revesionTypeLoading}
        isEditMode={revesionTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={revesionTypeWatch('id')}
          control={revesionTypeControl}
          watch={revesionTypeWatch}
          errors={revesionTypeErrors}
          setValue={revesionTypeSetValue}
          extraProps={{
            entityName: 'نوع مراجعة'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع فواتير جديدة'}
        isVisible={isInvoiceTypeVisitable}
        onHide={invoiceTypeCloseModal}
        handleSubmit={invoiceTypeSubmit}
        toast={toast}
        loading={invoiceTypeLoading}
        isEditMode={invoiceTypeWatch('id') !== undefined}
      >
        <InvoiceTypeForm
          key={invoiceTypeWatch('id')}
          control={invoiceTypeControl}
          watch={invoiceTypeWatch}
          errors={invoiceTypeErrors}
          setValue={invoiceTypeSetValue}
          extraProps={{
            entityName: 'نوع الفاتورة'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع أمر بيع جديدة'}
        isVisible={isSalesOrderVisitable}
        onHide={salesOrderCloseModal}
        handleSubmit={salesOrderSubmit}
        toast={toast}
        loading={salesOrderLoading}
        isEditMode={salesOrderWatch('id') !== undefined}
      >
        <GenericForm
          key={salesOrderWatch('id')}
          control={salesOrderControl}
          watch={salesOrderWatch}
          errors={salesOrderErrors}
          setValue={salesOrderSetValue}
          extraProps={{
            entityName: 'نوع أمر بيع'
          }}
        />
      </GenericModal>
    </>
  );
}

export default AdminLists;

import { Button, Card, Col, Row } from 'antd';
import PageHeader from '../../../components/components/Header/PageHeader';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../context/ToastContext';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { useForm } from 'react-hook-form';
import useFetch from '../../../hooks/useFetch';
import GenericEditableDataTable from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { axiosInstance } from '../../../global';

const covenantSchema = yup.object({
  menuId: yup.number().notRequired(),
  userTypeId: yup.number().notRequired()
});

interface TableRow {
  userTypeId: number;
  userTypeName: string;
  menuId: number;
  menuName: string;
  hasAccess: boolean;
}

const MenuAccessScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [tableData, setTableData] = useState<TableRow[]>([]);

  const { data: menuData, error: menuError, isLoading: menuLoading } = useFetch('/Menu/dropdown');
  const { data: useTypeData, error: userTypeError, isLoading: userTypeLoading } = useFetch('/UsersType/dropdown');

  const methods = useForm({
    resolver: yupResolver(covenantSchema),
    mode: 'all',
    defaultValues: {}
  });

  const {
    reset,
    watch,
    control,
    formState: { errors }
  } = methods;

  const handleSearch = async () => {
    const menuId = watch('menuId');
    const userTypeId = watch('userTypeId');

    try {
      const response = await axiosInstance.get('UsersTypesMenusAccess/all', {
        params: {
          menuId,
          userTypeId
        }
      });

      if (response.data) {
        setTableData(response.data.data); // Update the table data
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      toast.current.show({ type: 'error', summary: 'Error fetching data', detail: error.message });
    }
  };

  const handleSave = async () => {
    try {
      const formattedData = {
        items: tableData.map(row => ({
          userTypeId: row.userTypeId,
          menuId: row.menuId,
          hasAccess: row.hasAccess
        }))
      };

      const response = await axiosInstance.post('UsersTypesMenusAccess', formattedData);

      if (response.data) {
        reset();
        setTableData([]);
        toast.current.show({ type: 'success', summary: 'Data saved successfully' });
      }
    } catch (error: any) {
      console.error('Error saving data:', error);
      toast.current.show({ type: 'error', summary: 'Error saving data', detail: error.message });
    }
  };

  return (
    <>
      <PageHeader
        icon={''}
        buttons={[]}
        headerTitle={'Menu Access'}
      />

      <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
        <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
          <Row align={'middle'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <CustomFormField
                control={control}
                name={'menuId'}
                label={'Menu'}
                errors={errors}
                type={'dropdown'}
                loading={menuLoading}
                options={menuData?.data ?? []}
              />
            </Col>
            <Col span={6}>
              <CustomFormField
                control={control}
                name={'userTypeId'}
                label={'User Type'}
                errors={errors}
                type={'dropdown'}
                loading={userTypeLoading}
                options={useTypeData?.data ?? []}
              />
            </Col>
            <Col span={4}>
              <Button
                htmlType="button"
                onClick={handleSearch}
                style={{ width: '100%', marginTop: '2rem' }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Card>
      </form>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1.5rem' }}>
          <Button htmlType="button" onClick={handleSave}>
            Save
          </Button>
        </div>

        <GenericEditableDataTable<TableRow>
          data={tableData}
          allowDelete={false}
          columns={[
            {
              field: 'userTypeId',
              header: 'المنتج',
              editable: false,
              hidden: true,
              type: 'text'
            },
            {
              field: 'userTypeName',
              header: 'User Type',
              editable: false,
              type: 'text'
            },
            {
              field: 'menuId',
              header: 'مكان التخزين',
              editable: false,
              hidden: true,
              type: 'text'
            },
            {
              field: 'menuName',
              header: 'Menu Name',
              editable: false,
              type: 'text'
            },
            {
              field: 'hasAccess',
              header: 'Has Access',
              editable: true,
              type: 'boolean'
            }
          ]}
          isLoading={false}
          onRowUpdate={(updatedRow: TableRow[]) => {
            setTableData((prevData: TableRow[]) =>
              prevData.map((row) => {
                const matchingRow = updatedRow.find(
                  (updatedRow) =>
                    row.menuId === updatedRow.menuId && row.userTypeId === updatedRow.userTypeId
                );
                return matchingRow ? { ...row, ...matchingRow } : row;
              })
            );
          }}
        />
      </Card>
    </>
  );
};

export default MenuAccessScreen;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function AccountingTransactionScreen() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'اجمالي حساب الشركة',
      num: '2000000 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#F9D8D433'
    },
    {
      title: 'اجمالي المديونيات',
      num: '450000 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#6D54DE33'
    },
    {
      title: 'اجمالي حركة اليوم',
      num: '50000',
      imageUrl: '/images/coins.svg',
      color: '#FFEDD0'
    }
  ];

  const columnsTab1 = [
    {
      header: 'رقم الدفعة',
      field: 'code',
      isChip: false
    },
    {
      header: 'تاريخ الدفعة',
      field: 'transDateTime',
      isDate: true
    },
    {
      header: 'نوع الدفعة',
      field: 'transTypeName',
      isChip: false
    },
    {
      header: 'المستلم',
      field: 'receivingEntityName',
      isChip: false
    },
    {
      header: 'المرسل',
      field: 'issuingEntityName',
      isChip: false
    },
    { header: 'المبلغ', field: 'amount', isChip: false }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: '',
          tableHeader: 'قائمة الحركات اليومية',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/AccountingTransaction/list',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'الدفعة',
          rowOnClick: (rowData) => {
            navigate('/Accounting/dailyTransactions/details', {
              state: { transId: rowData.id }
            });
          },
          headerButtons: [
            {
              label: 'اضافة دفعات نقدية ',
              onClick: () => {
                navigate('/finance/transaction/add');
              }
            }
          ]
        }
      ]}
      headerTitle={'الحسابات'}
      headerIcon={'../images/coins.svg'}
      analyticsCards={headerDetails}
      cardsNum='3'
    />
  );
}

export default AccountingTransactionScreen;

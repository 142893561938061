import React, { Suspense, useEffect, useState } from 'react';
import { useToast } from '../../../context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import PageHeader from '../../../components/components/Header/PageHeader';
import styles from './AddEditInvoice.module.scss';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from '../../../global';
import SupplierChooserStep from './SupplierChooserStep';
import InvoiceBasicInfoStep from './InvoiceBasicInfoStep';
import InvoiceItems from './InvoiceItems';
import InvoiceReveiwStep from './InvoiceReveiwStep';
import moment from 'moment';

const AddEditInvoice = () => {
  const toast = useToast();
  const location = useLocation();
  const { invoiceId, supplierId, type } = location.state || {};

  const navigate = useNavigate();
  // Define your Yup schema
  const invoiceInfoSchema = yup.object({
    id: yup.number().optional().nullable(),
    supplier: yup.object({
      id: yup.number()
    }),
    supplierId: yup.number().nullable(),
    typeId: yup.number().nullable().default(type),
    currencyId: yup.number().nullable().default(1),
    discountOnProducts: yup.number().nullable(),
    discountType: yup.number().nullable(),
    warehouseLocationId: yup.number().nullable(),
    cashBoxId: yup.number().nullable(),
    paidAmount: yup.number().nullable(),
    invoiceReference: yup.string().required('الرقم المرجعى للفتورة مطلوب'),
    paidReference: yup.string().nullable(),
    remarks: yup.string().nullable(),
    items: yup.array().required('المنتجات مطلوبة').default([]),
    totalAmount: yup.number().nullable(),
    requstedAmount: yup.number().nullable(),
    mytotalDiscount: yup.number().nullable(),
    isEdit: yup.boolean().default(false)
  });

  // Infer FormData type from the schema
  type FormData = yup.InferType<typeof invoiceInfoSchema>;
  const [isEditing, setIsEditing] = useState(false);

  // Use FormData as the type parameter
  const methods = useForm<FormData>({
    resolver: yupResolver(invoiceInfoSchema),
    mode: 'all'
  });

  const { handleSubmit, trigger, reset, watch, setValue } = methods;

  useEffect(() => {}, [methods.formState.errors]);

  const handleSubmitOnClick = async (data: any) => {
    const productTableData = watch('items') || [];

    const productErrors =
      productTableData !== null && productTableData.length === 0
        ? []
        : productTableData
            ?.map((product, index) => {
              const errors: string[] = [];
              if (!product.itemId) errors.push(type === 3 ? `المنتج مطلوب` : 'نوع المصروف مطلوب');
              if (product.qty == null || product.qty < 1)
                errors.push(`${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
              if (product.price == null || product.price < 0)
                errors.push(`${index + 1}: السعر مطلوب ولا يمكن أن يكون سالب او صفر`);
              return errors;
            })
            .flat();

    const allErrors = [...productErrors];

    if (allErrors.length > 0) {
      // Display all errors using the toast or console
      allErrors.forEach((error) => {
        toast.current.show({
          type: 'error',
          summary: 'خطأ في التحقق',
          detail: error,
          life: 3000
        });
      });
      return; // Prevent further processing if there are validation errors
    }

    const invoiceParamDto = {
      id: data.id ?? null,
      typeId: data?.typeId,
      supplierId: data?.supplier?.id,
      currencyId: data.currencyId,
      invoiceReference: data.invoiceReference,
      warehouseLocationId: data.warehouseLocationId,
      cashBoxId: data.cashBoxId,
      paidAmount: data.paidAmount,
      paidReference: data.paidReference,
      statusId: 1,
      remarks: data.remarks,
      items: productTableData.map((products) => ({
        itemId: products.itemId,
        qty: products.qty ?? 0,
        price: products.price ?? 0,
        discountPercent: products.discountPercent ?? 0,
        discountAmount: products.discountAmount ?? 0,
        remarks: products.remarks
      }))
    };

    try {
      isEditing
        ? await axiosInstance.put('/Invoice/full', invoiceParamDto)
        : await axiosInstance.post('/Invoice/full', invoiceParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} الفاتورة بنجاح`,
        life: 2000
      });
      type === 4 ? navigate('/Accounting/Expenses') : navigate('/Accounting/invoices');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الفاتورة: ${error}`,
        life: 3000
      });
    }
  };

  const steps = isEditing
    ? [
        { title: 'بيانات الفاتورة الاساسية', content: <InvoiceBasicInfoStep isEditing={isEditing} type={type} /> },
        { title: 'تعديل عناصر للفتورة', content: <InvoiceItems isEditing={isEditing} type={type} /> },
        { title: 'مراجعة الطلب', content: <InvoiceReveiwStep type={type} /> }
      ]
    : [
        { title: 'اختر المورد', content: <SupplierChooserStep /> },
        { title: 'بيانات الفاتورة الاساسية', content: <InvoiceBasicInfoStep isEditing={isEditing} type={type} /> },
        { title: 'إضافة عناصر للفتورة', content: <InvoiceItems isEditing={isEditing} type={type} /> },
        { title: 'مراجعة الطلب', content: <InvoiceReveiwStep type={type} /> }
      ];

  const [current, setCurrent] = useState(0);

  const items = steps.map((item) => ({ key: item.title, label: item.title }));
  const selectedSupplier = watch('supplier');
  const stepFields: FieldPath<FormData>[][] = [['supplier'], ['invoiceReference'], ['items']];

  const handleOnClick = async () => {
    let isValid = true;
    if (current < stepFields.length) {
      isValid = await trigger(stepFields[current]);
    }
    if (isValid) {
      if (current === 0 && !selectedSupplier) {
        toast.current?.show({
          severity: 'error',
          summary: 'خطآ',
          detail: 'اختر المورد اولا',
          life: 3000
        });
      } else {
        setCurrent((prevIndex) => (prevIndex >= steps.length - 1 ? prevIndex : prevIndex + 1));
      }
    }
  };
  const handleBackClick = () => {
    setCurrent((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (invoiceId) {
        try {
          const response = await axiosInstance.get(`/Invoice/${invoiceId}`);
          let supplierData1;
          const supplierResponse = await axiosInstance.get(`/Supplier/${supplierId}`);
          supplierData1 = supplierResponse?.data?.data;
          const invoiceData = response.data.data;
          reset({
            id: invoiceData.id,
            typeId: invoiceData.typeId,
            supplier: supplierData1,
            currencyId: invoiceData.currencyId,
            invoiceReference: invoiceData.reference,
            remarks: invoiceData.remarks,
            totalAmount: invoiceData.totalAmount,
            mytotalDiscount: invoiceData.totalDiscount,
            requstedAmount: invoiceData.totalNetAmount,
            isEdit: true,
            items: invoiceData?.items?.map(
              (product: {
                itemID: any;
                itemName: any;
                qty: any;
                price: any;
                discountPercent: any;
                discountAmount: any;
                netAmount: any;
              }) => ({
                itemId: product.itemID,
                itemName: product.itemName,
                qty: product.qty,
                price: product.price,
                discountPercent: product.discountPercent,
                discountAmount: product.discountAmount,
                totalPrice: product.netAmount,
                discountTypeTable: 2
              })
            )
          });
          setIsEditing(true);
        } catch (error: any) {
          toast.current.show({
            type: 'error',
            summary: 'خطأ',
            detail: `فشل في جلب بيانات الفاتورة: ${error.message}`,
            life: 3000
          });
        }
      }
    };

    fetchInvoiceData();
  }, [invoiceId, reset]);

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة فاتورة جديدة'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={current} />
          </Card>
          {<Suspense fallback={<ProgressSpinner />}>{steps[current].content}</Suspense>}
          <div className={`${styles.buttonContainer} mb-5`}>
            {current > 0 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className='btn__nextbtn' type='submit'>
                {isEditing ? 'حفظ التعديلات' : 'إضافة الفاتورة'}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditInvoice;

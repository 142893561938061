import React, { useCallback, useMemo, useRef, useState } from 'react';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import CustomerDeviceForm from '../CustomerLists/Forms/CustomerDeviceForm';
import { useNavigate } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import moment from 'moment';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useToast } from '../../../context/ToastContext';
import CustomerComplaintForm from '../CustomerLists/Forms/CustomerComplaintForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Knob } from 'primereact/knob';

const customerDeviceSheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  customerId: yup.number().required('Customer is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

const validationSchema = yup.object().shape({
  customerId: yup.string().required('اسم العميل مطلوب'),
  customerDeviceId: yup.string().required('جهاز العميل مطلوب'),
  complaintCategoryId: yup.string().required('تصنيف الشكوى مطلوب'),
  complaintDate: yup.date().required('تاريخ الشكوى مطلوب'),
  remarks: yup.string().required('تفاصيل الشكوى مطلوبة'),
  statusId: yup.string().required('حالة الشكوى مطلوبة')
});

const customerDeviceColumns = [
  { header: 'اسم العميل', field: 'customerName', isChip: false },
  { header: 'نوع الجهاز', field: 'deviceTypeName', isChip: false },
  { header: 'اسم الجهاز', field: 'deviceName', isChip: false },
  { header: 'الرقم الممبرين', field: 'membraneSerialNb', isChip: false },
  { header: 'رقم المتور', field: 'motorSerialNb', isChip: false },
  { header: 'تاريخ التركيب', field: 'installationDate', isChip: false },
  { header: 'تاريخ الانتهاء', field: 'expiryDate', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true }
];

const ContractColumns = [
  { header: 'اسم الجهاز', field: 'productName', isChip: false },
  { header: 'تاريخ التعاقد', field: 'contractDate', isChip: false },
  { header: 'تاريخ الانتهاء', field: 'expiryDate', isChip: false },
  { header: 'سعر البيع', field: 'sellingPrice', isChip: false },
  { header: 'إجمالى الاقساط', field: 'nbOfInstallments', isChip: false },
  { header: 'المدفوع', field: 'sumOfPaidInstallment', isChip: false },
  { header: 'المتبقى', field: 'sumOfRemainingInstallment', isChip: false },
  { header: 'حالة العقد', field: 'statusName', isChip: true }
];

const StagesColumns = [
  { header: 'كود العقد', field: 'contractCode', isChip: false },
  { header: 'اسم الجهاز', field: 'productName', isChip: false },
  { header: 'عدد المراحل', field: 'nbOfDeviceStages', isChip: false }
];

const InstallmentsColumns = [
  { header: 'اسم الجهاز', field: 'contractCode', isChip: false },
  { header: 'اسم الجهاز', field: 'productName', isChip: false },
  { header: 'عدد الاقساط', field: 'installmentTypeName', isChip: false }
];

const VisitsColumns = [
  { header: 'نوع الزيارة', field: 'visitTypeName', isChip: false },
  { header: 'الفنى', field: 'assignedRepName', isChip: false },
  { header: 'الاهمية', field: 'priorityName', isChip: false },
  { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true }
];

const ActivityColumns = [
  { header: 'اسم الجهاز', field: 'productName', isChip: false },
  // { header: 'فئة المنتج', field: 'product.productCategory.name', isChip: false },
  { header: 'نوع الزياره', field: 'activityName', isChip: false },
  { header: 'مستوى رضاء العميل', field: 'satisfactionLevel', isRate: true },
  { header: 'تاريخ الزيارة', field: 'activityDateTime', isChip: false }
];

const ComplaintsColumns = [
  { header: 'المنتج', field: 'productName', isChip: false },
  // { header: 'كود الشكوى', field: 'code', isChip: false },
  { header: 'فئة الشكوى', field: 'complaintCategoryName', isChip: false },
  // { header: 'الشكوى', field: 'complaintType', isChip: false },
  { header: 'مستلم الشكوى', field: 'complainedEmployeeName', isChip: false },
  { header: 'تاريخ الشكوى', field: 'complaintDate', isChip: false }
];
export default function CustomerDetailsTables({ customerID }: { customerID: string | undefined }) {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: iscustomerDeviceVisitable,
    openModal: customerDeviceOpenModal,
    closeModal: customerDeviceCloseModal,
    control: customerDeviceControl,
    handleSubmit: customerDeviceSubmit,
    errors: customerDeviceErrors,
    setValue: customerDeviceSetValue,
    loading: customerDeviceLoading,
    setLoading: customerDeviceSetLoading,
    watch: customerDeviceWatch
  } = useModal({}, '/CustomersDevice', fetchTabData, customerDeviceSheme, toast, (data) => {
    return {
      ...data,
      installationDate: moment(new Date(data.installationDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      expiryDate: moment(new Date(data.expiryDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
    };
  });

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, '/CustomersComplaint', () => {
    }, validationSchema, toast);

  const [installmentExpandedRows, setInstallmentExpandedRows] = useState<any[]>([]);
  const [stageExpandedRows, setStageExpandedRows] = useState<any[]>([]);

  const allowInstallmentExpansion = useCallback((rowData: any) => {
    return rowData?.items?.length > 0;
  }, []);

  const allowStageExpansion = useCallback((rowData: any) => {
    return rowData?.items?.length > 0;
  }, []);

  const installmentRowExpansionTemplate = useCallback((rowData: any) => {
    const installments = (rowData.items || []).sort((a: any, b: any) => a.installmentNb - b.installmentNb);
    return (
      <div className="p-3">
        <DataTable value={installments}>
          <Column field="installmentNb" header="رقم القسط"></Column>
          <Column field="installmentAmount" header="قيمة القسط"></Column>
          <Column field="remainingAmount" header="المتبقى من السداد"></Column>
          <Column field="paymentDueDate" header="تاريخ الاستحقاق"></Column>
          <Column field="collectionDate" header="تاريخ التحصيل"></Column>
          <Column field="statusName" header="الحالة"></Column>
        </DataTable>
      </div>
    );
  }, []);

  const stageRowExpansionTemplate = useCallback((rowData: any) => {
    const installments = (rowData.items || []).sort((a: any, b: any) => a.stageNb - b.stageNb);
    const renderMandatoryReplacement = (rowData: { mandatoryReplacement: boolean; }) => {
      return <Checkbox checked={rowData.mandatoryReplacement} disabled />;
    };
    return (
      <div className="p-3">
        <DataTable value={installments}>
          <Column field="stageNb" header="رقم المرحله"></Column>
          <Column field="instatledStageName" header="المرحله"></Column>
          <Column field="nextReplacementDate" header="ميعاد التجديد القادم"></Column>
          <Column header={'مؤشر التغيير'} body={(data, options) => {
            return (<Knob value={data.remainingDays} min={-365} max={365} size={50} valueColor="#EEBA00" />);
          }} />
          <Column
            header="يجب التغيير"
            body={renderMandatoryReplacement}
          ></Column>
        </DataTable>
      </div>
    );
  }, []);


  const CustomerTab = useMemo(
    () => ({
      label: 'الاجهزة',
      entityName: 'جهاز',
      tableHeader: 'جدول الاجهزة',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        customerDeviceOpenModal({
          id: rowData.id,
          code: rowData.code,
          customerId: rowData.customerID,
          statusId: rowData.statusID,
          typeId: rowData.deviceTypeID,
          deviceId: rowData.deviceID,
          customerAddressId: rowData.customerAddressID,
          membraneSerialNb: rowData.membraneSerialNb,
          motorSerialNb: rowData.motorSerialNb,
          installationDate: new Date(rowData.installationDate),
          warrantyMonths: rowData.warrantyMonths,
          expiryDate: new Date(rowData.expiryDate),
          remarks: rowData.remarks
        });
      },
      apiUrl: `CustomersDevice/list/ByCustomer/${customerID}`,
      columns: customerDeviceColumns,
      edit: true,
      dataKey: 'CustomersDevice',
      emptyStateButtonClick: () => {
        customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
      }
    }),
    [customerID]
  );

  const ContractTab = useMemo(
    () => ({
      label: 'العقود',
      entityName: 'العقد',
      tableHeader: 'جدول العقود',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        navigate(`/customer/contract/details/${rowData.contractID}/${customerID}/contracts`);
      },
      apiUrl: `Contract/list/allByCustomer/${customerID}`,
      columns: ContractColumns,
      edit: false,
      dataKey: 'CustomersContract',
      emptyStateButtonClick: () => {
        customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
      }
    }),
    [customerID]
  );

  const VisitsTab = useMemo(
    () => ({
      label: 'الزيارات',
      entityName: 'الزيارة',
      tableHeader: 'جدول الزيارات',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        navigate(`/customer/followUp/details/${rowData.visitID}/${customerID}/followUp`);
      },
      apiUrl: `Visit/list/allByCustomer/${customerID}`,
      columns: VisitsColumns,
      edit: false,
      dataKey: 'CustomersVisits',
      emptyStateButtonClick: () => {
        navigate(`/Activites/addVisitConnection`);
      }
    }),
    [customerID]
  );

  const InstallmentTab = useMemo(
    () => ({
      label: 'الاقساط',
      entityName: 'القسط',
      tableHeader: 'جدول الاقساط',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
      },
      apiUrl: `ContractsInstallment/list/groupByCustomer/${customerID}`,
      columns: InstallmentsColumns,
      isColumnHidden: true,
      edit: false,
      pageSize: 50,
      dataKey: 'CustomersInstallment',
      emptyStateButtonClick: () => {
        customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
      }
    }),
    [customerID]
  );

  const StageTab = useMemo(
    () => ({
      label: 'المراحل',
      entityName: 'المرحلة',
      tableHeader: 'جدول متابعة المراحل',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
      },
      apiUrl: `ContractsDeviceStage/list/groupByCustomer/${customerID}`,
      columns: StagesColumns,
      isColumnHidden: true,
      edit: false,
      dataKey: 'CustomersDeviceStages',
      pageSize: 50,
      emptyStateButtonClick: () => {
        navigate(`/Activites/addVisitConnection`);
      }
    }),
    [customerID]
  );

  const ActivityTab = useMemo(
    () => ({
      label: 'الاتصالات',
      entityName: 'الاتصال',
      tableHeader: 'جدول الاتصالات',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        navigate(`/customer/activity/details/${rowData.customerActivityID}/${customerID}/activity`);
      },
      apiUrl: `CustomersActivity/list/allByCustomer/${customerID}`,
      columns: ActivityColumns,
      edit: false,
      dataKey: 'CustomersActivity',
      emptyStateButtonClick: () => {
        navigate(`/Activites/addVisitConnection`);
      }
    }),
    [customerID]
  );

  const ComplaintsTab = useMemo(
    () => ({
      label: 'الشكاوى',
      entityName: 'شكوة',
      tableHeader: 'جدول الشكاوى',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        navigate(`/customer/complaint/details/${rowData.id}/${customerID}/complaint`);
      },
      apiUrl: `CustomersComplaint/list/allByCustomer/${customerID}`,
      columns: ComplaintsColumns,
      edit: false,
      dataKey: 'CustomersComplaints',
      emptyStateButtonClick: () => {
        openModal({ customerId: parseInt(customerID as string) });
      }
    }),
    [customerID]
  );

  return (
    <>
      <div className="customerdetails__content__down">
        <Tabs
          headers={[
            'أجهزة العميل',
            'العقود',
            'متابعة المراحل',
            'اقساط العميل',
            'سجل الزيارات',
            'سجل الاتصال',
            'سجل الشكاوى'
          ]}
          contents={[
            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة جهاز للعميل',
                  onClick: () => {
                    customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
                  }
                }
              ]}
              selectedTab={CustomerTab}
            />,
            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة جهاز للعميل',
                  onClick: () => {
                    customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
                  }
                }
              ]}
              selectedTab={ContractTab}
            />,
            <DataTableCard
              button={[
                {
                  label: 'إضافة زيارة للعميل',
                  onClick: () => {
                    navigate(`/Activites/addVisitConnection`);
                  }
                }
              ]}
              allowExpansion={allowStageExpansion}
              rowExpansionTemplate={stageRowExpansionTemplate}
              expandedRows={stageExpandedRows}
              onRowToggle={(e: any) => setStageExpandedRows(e.data)}
              selectedTab={StageTab}
            />,

            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة جهاز للعميل',
                  onClick: () => {
                    customerDeviceOpenModal({ customerId: parseInt(customerID as string) });
                  }
                }
              ]}
              allowExpansion={allowInstallmentExpansion}
              rowExpansionTemplate={installmentRowExpansionTemplate}
              expandedRows={installmentExpandedRows}
              onRowToggle={(e: any) => setInstallmentExpandedRows(e.data)}
              selectedTab={InstallmentTab}
            />,

            <DataTableCard selectedTab={VisitsTab} />,

            <DataTableCard
              button={[
                {
                  label: 'إضافة زيارة للعميل',
                  onClick: () => {
                    navigate(`/Activites/addVisitConnection`);
                  }
                }
              ]}
              selectedTab={ActivityTab}
            />,

            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة شكوى للعميل',
                  onClick: () => {
                    openModal({ customerId: parseInt(customerID as string) });
                  }
                }
              ]}
              selectedTab={ComplaintsTab}
            />
          ]}
        />
      </div>

      <GenericModal
        headerTitle={customerDeviceWatch('id') === undefined ? 'اضافه جهاز جديد' : 'تعديل جهاز'}
        isVisible={iscustomerDeviceVisitable}
        onHide={customerDeviceCloseModal}
        handleSubmit={customerDeviceSubmit}
        toast={toast}
        loading={customerDeviceLoading}
        isEditMode={customerDeviceWatch('id') !== undefined}
      >
        <CustomerDeviceForm
          control={customerDeviceControl}
          errors={customerDeviceErrors}
          setValue={customerDeviceSetValue}
          watch={customerDeviceWatch}
          extraProps={{ entityName: 'جهاز' }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة شكوى جديدة'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <CustomerComplaintForm control={control} errors={errors} setValue={setValue} watch={watch} isDisabled={true} />
      </GenericModal>
    </>
  );
}

import React, { useRef, useState } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
// import EmployeeLoanForm from '..//EmployeeLoanForm';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';
import { Modal, Form, Select } from 'antd';
import useFetch from '../../../hooks/useFetch';
import { axiosInstance } from '../../../global';

interface Values {
  cashBoxId: number;
}

function LoansScreen() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null); // State to hold the selected loan ID
  const [formValues, setFormValues] = useState<Values>();
  const [open, setOpen] = useState(false);
  const { data: cashBoxData, isLoading: iscashBoxLoading } = useFetch('/CashBox/dropdown');

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const onCreate = (values: Values) => {
    if (selectedLoanId === null) return;
    try {
      axiosInstance.post(`/AccountingTransaction/acceptLoanTransaction/${values?.cashBoxId}/${selectedLoanId}`);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: 'تم سداد السلفة للموظف',
        life: 5000
      });
      setOpen(false);
      fetchTabData();
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: 'فشل عملية سداد السلفة للموظف',
        life: 5000
      });
    }
  };
  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'الموافقة على السلف',
            entityName: 'سلفة',
            tableHeader: 'جدول الموافقة على السلف',
            emptyStateUrl: '',
            isHidden: true,
            rowOnClick: (rowData) => {
              setSelectedLoanId(rowData.id); // Set the selected loan ID
              setOpen(true);
            },
            apiUrl: '/Loan/list/approved',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              { header: 'المرجع', field: 'reference', isChip: false },
              { header: 'المبلغ', field: 'amount', isChip: false },
              { header: 'عدد الاقساط', field: 'nbOfInstallments', isChip: false },
              { header: 'قيمة القسط', field: 'installmentValue', isChip: false },
              { header: 'معاد القسط الاول', field: 'firstInstallmentDate', isDate: true },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            dataKey: 'AccountingLoanApproved',
            SeparetentityName: 'سداد السلفة'
          }
        ]}
        headerTitle={'الحسابات'}
        headerIcon={'../images/coins.svg'}
      />
      <Modal
        open={open}
        title='سداد للموظف'
        okText='سداد للموظف'
        cancelText='إلغاء'
        okButtonProps={{
          autoFocus: true,
          htmlType: 'submit',
          style: { backgroundColor: '#98f500', color: '#333', border: '#333' }
        }}
        onCancel={() => setOpen(false)}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            layout='vertical'
            form={form}
            name='form_in_modal'
            initialValues={{ modifier: 'public' }}
            clearOnDestroy
            onFinish={(values) => onCreate(values)}
          >
            {dom}
          </Form>
        )}
      >
        <Form.Item
          label='اختر الخزينة'
          name='cashBoxId' // Bind the Select to this name
          rules={[{ required: true, message: 'يرجى اختيار الخزينة' }]}
        >
          <Select
            placeholder='اختر خزينة'
            options={cashBoxData?.data.map((option: any) => ({
              label: option['name'],
              value: option['id']
            }))}
          />
        </Form.Item>
      </Modal>
    </>
  );
}

export default LoansScreen;

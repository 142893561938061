import { useState } from 'react';
import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

export type IdAndName = {
  id: number;
  name: string;
};

const useWarehouseHierarchy = () => {
  const [selectedWarehouse, setSelectedWarehouse] = useState<IdAndName | null>(null);
  const [selectedWarehouseSection, setSelectedWarehouseSection] = useState<IdAndName | null>(null);
  const [selectedWarehouseLocation, setSelectedWarehouseLocation] = useState<IdAndName | null>(null);

  const warehouseApiState = useApiState<IdAndName[]>({
    apiCall: () => axiosInstance.get('/Warehouse/dropdown').then((res) => res.data.data),
    isLazy: false // Always load Warehouse on initialization
  });

  const warehouseSectionApiState = useApiState<IdAndName[], IdAndName[], { warehouseId: number }>({
    apiCall: async (args) => {
      if (args && args.warehouseId) {
        const res = await axiosInstance.get(`/WarehousesSection/dropdown/ByWarehouse/${args.warehouseId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const warehouseLocationApiState = useApiState<IdAndName[], IdAndName[], { warehouseSectionId: number }>({
    apiCall: async (args) => {
      if (args && args.warehouseSectionId) {
        const res = await axiosInstance.get(
          `/WarehousesLocation/dropdown/ByWarehouseSection/${args.warehouseSectionId}`
        );
        return res.data.data;
      }
    },
    isLazy: true
  });

  const handleWarehouseSelect = async (warehouse: IdAndName) => {
    setSelectedWarehouse(warehouse);
    setSelectedWarehouseSection(null);
    setSelectedWarehouseLocation(null);
    await warehouseSectionApiState.lazyApiCall({ warehouseId: warehouse.id });
  };

  const handleWarehouseSectionSelect = async (warehouseSection: IdAndName) => {
    setSelectedWarehouseSection(warehouseSection);
    setSelectedWarehouseLocation(null);
    await warehouseLocationApiState.lazyApiCall({ warehouseSectionId: warehouseSection.id });
  };

  const handleWarehouseLocationSelect = async (warehouseLocation: IdAndName) => {
    setSelectedWarehouseLocation(warehouseLocation);
  };

  return {
    warehouseApiState,
    warehouseSectionApiState,
    warehouseLocationApiState,
    selectedWarehouse,
    selectedWarehouseSection,
    selectedWarehouseLocation,
    handleWarehouseSelect,
    handleWarehouseSectionSelect,
    handleWarehouseLocationSelect,
    resetActivityHierarchyState: () => {
      setSelectedWarehouse(null);
      setSelectedWarehouseSection(null);
      setSelectedWarehouseLocation(null);
      warehouseSectionApiState.resetApiCallState();
      warehouseLocationApiState.resetApiCallState();
    }
  };
};

export default useWarehouseHierarchy;

import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="500"
      title="500"
      subTitle="عذرًا، حدث خطأ ما."
      extra={<Button type="primary" onClick={() => navigate('/')}>العودة إلى الصفحة الرئيسية</Button>}
    />
  );
};


export default NotFound;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import { axiosInstance } from '../../../global';
import FollowUpScreenStatusTable from './FollowUpScreenStatusTable';

interface Status {
  id: string;
  name: string;
}

function FollowUpScreen() {
  const [status, setStatus] = useState<Status[]>([]);
  useEffect(() => {
    axiosInstance
      .get(`/Visit/allStatus`)
      .then((response) => {
        setStatus(response.data.data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <div className="main w-full">
      <span className="main__title__custom">
        <i className="main__title__custom--icon">
          <img src="../images/briefcase1.svg" alt="" />
        </i>
        <span>الزيارات</span>
      </span>
      <button className="tbl__addbtn">
        <Link className="tbl__addbtn__link" to={'/Activites/addVisitConnection'}>
          <img src="/images/plus.svg" alt={''} />
          <span>{'اضافة اتصال / زيارة جديدة'}</span>
        </Link>
      </button>

      <div className="mt-5 pr-4 pl-4">
        <div className="customerdetails__header__card mb-4">
          <HeaderCardTS
            title={'المتابعات المنتظرة'}
            num={'20'}
            imageUrl={'/images/sandclock.svg'}
            color={'#13DE8230'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCardTS
            title={'الزيارات المتأخرة'}
            num={'40'}
            imageUrl={'/images/altsleep.svg'}
            color={'#F9D8D4'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCardTS
            title={'تقيمات العملاء'}
            num={'60'}
            imageUrl={'/images/check.svg'}
            color={'#6D54DE33'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCardTS
            title={'الزيارات الغير محددة'}
            num={'30'}
            imageUrl={'/images/briefcase.svg'}
            color={'#FFEDD0'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
        </div>
        <div className="details__table">
          <Tabs
            headers={status.map((stat) => stat.name)}
            contents={status.map((stat) => (
              <FollowUpScreenStatusTable key={stat.id} statusID={stat.id} />
            ))}
          />
        </div>
      </div>
    </div>
  );
}

export default FollowUpScreen;

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Knob } from 'primereact/knob';
import { Rating } from 'primereact/rating';

import styles from '../GenericPage/GenericPage.module.scss';
import EmptyState from '../EmptyState/EmptyState';
import moment from 'moment';

interface DataTableValue {
  [key: string]: any;
}

export interface ColumnProps {
  field: string;
  header: string;
  isBoolean?: boolean;
  isChip?: boolean;
  isKnob?: boolean;
  isRate?: boolean;
  isDate?: boolean;
  isButton?: boolean;
  actionCallback?: (id: any) => void; // Add this prop for callback
  buttonIcon?: any;
  sortable?: boolean;
}

export interface GenericDataTableHandle {
  toggleRowExpansion: any;
  updateData: (newData: any[]) => void;
  updateRowData: (newData: any[]) => void;
}

interface GenericDataTableProps<T extends DataTableValue> {
  data: T[];
  columns: ColumnProps[];
  page: number;
  pageSize: number;
  totalRecords: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isLoading: boolean;
  onPageChange: (e: DataTableStateEvent) => void;
  onSort: (e: DataTableStateEvent) => void;
  onRowClick: (id: any) => void;
  edit: boolean;
  entityName: string;
  SeparetentityName?: string;
  emptyStateUrl: string;
  emptyStateButtonClick?: () => void;
  allowExpansion?: (rowData: T) => boolean;
  rowExpansionTemplate?: (data: T) => React.ReactNode;
  expandedRows?: T[];
  onRowToggle?: (e: any) => void;
  rowClickable?: boolean;
  isColumnHidden?: boolean;
  paginator?: boolean;
}

const BooleanColumn = ({ value }: { value: boolean }) => <span>{value ? 'Yes' : 'No'}</span>;

const ChipColumn = ({ value, field }: { value: string; field: string }) => {
  const isStatusField = field.toLowerCase().includes('status');
  const isActive = value === 'Active';
  const chipStyles = useMemo(
    () => ({
      backgroundColor: isStatusField ? (isActive ? '#E8F8F2' : '#F8E9E9') : '#dee2e6', // Gray for non-status fields
      border: isStatusField ? `2px solid ${isActive ? '#39B54A' : '#B92026'}` : 'none',
      borderRadius: '25px',
      padding: '0 10px',
      alignItems: 'center'
    }),
    [isActive, isStatusField]
  );

  return (
    <Chip
      style={chipStyles}
      icon={
        isStatusField ? (
          <span
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: isStatusField ? (isActive ? '#39B54A' : '#B92026') : '#dee2e6', // Gray icon for non-status fields
              borderRadius: '50%',
              marginLeft: '8px'
            }}
          />
        ) : null
      }
      label={value}
    />
  );
};

const ButtonColumn = ({
                        rowData,
                        callback,
                        icon
                      }: {
  rowData: DataTableValue;
  callback: (rowData: DataTableValue) => void;
  icon?: any;
}) => {
  return (
    <div className="flex w-full justify-content-between">
      <Button icon={icon || 'pi pi-plus'} onClick={() => callback(rowData)} outlined />
    </div>
  );
};

const GenericDataTable = forwardRef(function GenericDataTable<T extends DataTableValue>(
  {
    data,
    columns = [],
    page,
    pageSize,
    totalRecords,
    sortField,
    sortOrder,
    isLoading,
    onPageChange,
    onSort,
    onRowClick,
    edit,
    entityName,
    SeparetentityName,
    emptyStateUrl,
    emptyStateButtonClick,
    allowExpansion,
    rowExpansionTemplate,
    expandedRows: initialExpandedRows,
    onRowToggle,
    isColumnHidden,
    rowClickable = false,
    paginator = true
  }: GenericDataTableProps<T>,
  ref: React.Ref<GenericDataTableHandle> // Adjust the type as per your needs
) {
  const [dataTable, setDataTable] = useState<T[]>(data);
  const [expandedRows, setExpandedRows] = useState<T[] | undefined>(initialExpandedRows);

  const renderBody = useCallback((rowData: T, column: ColumnProps) => {
    const field = column.field.split('.');
    const value = field.length > 1 ? rowData[field[0]][field[1]] : rowData[column.field];

    if (column.isBoolean) {
      return <BooleanColumn value={value} />;
    } else if (column.isChip) {
      return <ChipColumn key={`${field}-${value}-${rowData.id}`} value={value} field={column.field} />;
    } else if (column.isKnob) {
      return <Knob value={value} min={-365} max={365} size={50} valueColor="#EEBA00" />;
    } else if (column.isRate) {
      return <Rating value={value} cancel={false} />;
    } else if (column.isButton && column.actionCallback) {
      return <ButtonColumn rowData={rowData} callback={column.actionCallback} icon={column.buttonIcon} />; // Pass the full row data
    } else if (column.isDate) {
      return <span>{moment(new Date(value)).locale('en-GB').format('YYYY-MM-DD')}</span>;
    } else {
      return <span>{value}</span>;
    }
  }, [data, dataTable]);

  const renderOptions = useCallback(
    (rowData: T) => (
      <Button
        label={SeparetentityName ? SeparetentityName : `${edit ? 'تعديل' : 'عرض'} ${entityName}`}
        icon={
          <img
            src={edit ? '/images/edit.svg' : '/images/information.svg'}
            alt={edit ? 'edit' : 'view'}
            style={{ paddingLeft: edit ? 0 : 4 }}
          />
        }
        className="button-options"
        severity="secondary"
        text
        onClick={() => onRowClick(rowData)}
      />
    ),
    [edit, entityName, onRowClick]
  );

  useImperativeHandle(ref, () => ({
    updateData: (newData: any[]) => {
      setDataTable(newData);
    },
    updateRowData: (newData: any[]) => {
      setExpandedRows(newData);
    },
    toggleRowExpansion: (rowData: T) => {
      setExpandedRows((prevExpandedRows) => {
        const isRowExpanded = prevExpandedRows?.includes(rowData);
        if (isRowExpanded) {
          return prevExpandedRows?.filter((row) => row !== rowData);
        } else {
          return [...(prevExpandedRows || []), rowData];
        }
      });
    }
  }));

  const memoizedColumns = useMemo(
    () =>
      columns.map((column) => (
        <Column
          key={column.field}
          field={column.field}
          header={column.header}
          sortable={column.sortable ?? true}
          style={{ textAlign: 'right' }}
          body={(rowData) => renderBody(rowData, column)}
        />
      )),
    [columns, renderBody, data, dataTable]
  );

  const [selectedRow, setSelectedRow] = useState<any | null>(null);

  useEffect(() => {
    if (data) {
      setDataTable(data);
      setSelectedRow(null);
    }
  }, [data]);

  useEffect(() => {
    if (expandedRows) {
      setExpandedRows(expandedRows);
    }
  }, [expandedRows]);

  const handleRowToggle = useCallback(
    (e: any) => {
      if (onRowToggle) onRowToggle(e); // Call parent handler if provided
      setExpandedRows(e.data); // Update internal state
    },
    [onRowToggle]
  );


  return (
    <div className={`${styles['data-container']} ${isLoading ? styles['flex-center'] : ''}`}>
      {isLoading ? (
        <ProgressSpinner />
      ) : dataTable.length === 0 ? (
        <EmptyState
          title={`لا يوجد ${entityName}`}
          description={`لم تقم بإضافة أي ${entityName} حتى الآن، قم بالإضافة الآن`}
          buttonText={`إضافة ${entityName}`}
          buttonLink={emptyStateUrl}
          onButtonClick={emptyStateButtonClick}
        />
      ) : (
        <DataTable
          value={dataTable}
          paginator={paginator}
          rows={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={totalRecords}
          lazy
          first={page * pageSize}
          onPage={onPageChange}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder === 'asc' ? 1 : -1}
          onSelectionChange={(e: { value: any }) => {
            onRowClick(e.value);
            setSelectedRow(e.value);
          }}
          onRowClick={
            rowClickable
              ? (e) => {
                onRowClick(e.data);
                setSelectedRow(e.data);
              }
              : undefined
          }
          selectionMode={rowClickable ? 'radiobutton' : undefined}
          selection={selectedRow}
          rowClassName={rowClickable ? () => ({ cursor: 'pointer' }) : undefined}
          expandedRows={expandedRows}
          onRowToggle={handleRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          {rowClickable && <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>}

          {allowExpansion && <Column expander={allowExpansion} style={{ width: '5rem' }} />}
          {memoizedColumns}
          {!isColumnHidden && <Column header="خيارات" style={{ textAlign: 'right' }} body={renderOptions} />}
        </DataTable>
      )}
    </div>
  );
});

export default React.memo(GenericDataTable);

import styles from '../AddEditSalesOrder.module.scss';

interface FormFieldProps {
  label: string;
  children: React.ReactNode;
  error?: string;
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({ label, children, error, required = false }) => {
  const requiredStyle = { color: 'red', padding: '0.7rem' };
  return (
    <div className={styles.formFiled}>
      <label className='form-field__label'>
        {label}
        {required && <span style={requiredStyle}>*</span>}
      </label>
      {children}
      {error && <span className='text-red-500'>{error}</span>}
    </div>
  );
};

export default FormField;

import { useToast } from '../../../context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import PageHeader from '../../../components/components/Header/PageHeader';
import React, { Suspense, useEffect, useState } from 'react';
import styles from './AddEditContract.module.scss';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import ProductChooserStep from './ProductChooserStep';
import ContractBasicInfoStep from './ContractBasicInfoStep';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from '../../../global';
import moment from 'moment/moment';
import ContractDetails from './ContractDetails';
import CustomerChooserStep from './CustomerChooserStep';
import ContractChooserStep from './ContractChooserStep';

// Define your Yup schema
const contractInfoSchema = yup.object({
  id: yup.number().optional().nullable(),
  product: yup
    .object({
      id: yup.number().required(),
      skuCode: yup.string(),
      deviceName: yup.string()
    })
    .required(),
  customer: yup.object({
    id: yup.number()
  }),
  customerDeviceId: yup.number().nullable().optional(),
  typeId: yup.number().required('نوع العقد مطلوب'),
  contractDate: yup.date().typeError('تاريخ التعاقد يجب أن يكون تاريخًا صالحًا').nullable(),
  expiryDate: yup.date().typeError('تاريخ الانتهاء يجب أن يكون تاريخًا صالحًا').nullable(),
  freeInstallation: yup.boolean().default(false),
  sellingPrice: yup.number().required('سعر البيع مطلوب'),
  installmentsTypeId: yup.number().required('نوع التقسيط مطلوب'),
  installmentAmount: yup.number(),
  downPayment: yup
    .number()
    .required('قيمة المقدم مطلوبة')
    .typeError('قيمة المقدم يجب أن تكون رقمًا')
    .min(0, 'قيمة المقدم يجب أن تكون أكبر من أو تساوي 0'),
  nbOfInstallments: yup
    .number()
    .required('عدد الاقساط مطلوب')
    .typeError('عدد الاقساط يجب أن يكون رقمًا')
    .min(1, 'عدد الاقساط يجب أن يكون 1 على الأقل')
    .max(24, 'عدد الاقساط يجب ألا يتجاوز 24'),
  beneficiaryEmployeeId: yup.string().nullable(),
  statusId: yup.number().required('حاله العقد مطلوبه'),
  reference: yup.string().required('المرجع مطلوب'),
  remarks: yup.string().nullable(),
  deviceStages: yup.array().default([]),
  productsStock: yup.array().default([]),
  servicesStock: yup.array().default([]),
  isEdit: yup.boolean().default(false)
});

// Infer FormData type from the schema
type FormData = yup.InferType<typeof contractInfoSchema>;

const AddEditContract = () => {
  const toast = useToast();
  const location = useLocation();
  const { contractId, contractType, customerId, deviceId } = location.state || {};
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  // Use FormData as the type parameter
  const methods = useForm<FormData>({
    resolver: yupResolver(contractInfoSchema),
    mode: 'all'
  });

  const { handleSubmit, trigger, reset, watch, setValue } = methods;

  useEffect(() => {}, [methods.formState.errors]);

  const handleSubmitOnClick = async (data: any) => {
    const product = watch('product');
    const customer = watch('customer');
    const deviceStages = watch('deviceStages') || [];
    const productTableData = watch('productsStock') || [];
    const serviceTableData = watch('servicesStock') || [];

    const deviceStagesErrors = deviceStages
      .map((stage, index) => {
        const errors: string[] = [];

        if (!stage.installedItemId) errors.push(`المرحلة ${stage.stageItemName}: نوع الشمعه مطلوب`);
        if (stage.mandatoryReplacement && (stage.replacementMonths == null || stage.replacementMonths < 1)) {
          errors.push(
            `المرحلة ${
              index + 1
            }: يجب تحديد عدد الشهور للاستبدال ويجب أن يكون على الأقل 1 عندما يكون الاستبدال إجباريًا`
          );
        }
        return errors;
      })
      .flat();

    // Validation for productsStock
    const productStockErrors =
      productTableData !== null && productTableData.length === 0
        ? []
        : productTableData
            ?.map((product, index) => {
              const errors: string[] = [];
              if (!product.itemId) errors.push(`المخزون ${index + 1}: معرف المنتج مطلوب`);
              if (product.qty == null || product.qty < 1)
                errors.push(`المخزون ${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
              if (product.remainingQty == null || product.remainingQty < 0)
                errors.push(`المخزون ${index + 1}: الكمية المتبقية مطلوبة ولا يمكن أن تكون سالبة`);
              return errors;
            })
            .flat();

    // Validation for servicesStock
    const serviceStockErrors =
      serviceTableData !== null && serviceTableData.length === 0
        ? []
        : serviceTableData
            ?.map((service, index) => {
              const errors: string[] = [];
              if (!service.serviceId) errors.push(`المخزون ${index + 1}: معرف الخدمة مطلوب`);
              if (service.qty == null || service.qty < 1)
                errors.push(`المخزون ${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
              if (service.remainingQty == null || service.remainingQty < 0)
                errors.push(`المخزون ${index + 1}: الكمية المتبقية مطلوبة ولا يمكن أن تكون سالبة`);
              return errors;
            })
            .flat();

    // Combine all validation errors
    const allErrors = [...deviceStagesErrors];

    if (allErrors.length > 0) {
      // Display all errors using the toast or console
      allErrors.forEach((error) => {
        toast.current.show({
          type: 'error',
          summary: 'خطأ في التحقق',
          detail: error,
          life: 3000
        });
      });
      return; // Prevent further processing if there are validation errors
    }
    const contractParamDto = {
      id: data.id ?? null,
      code: (Math.random() + 1).toString(36).substring(7),
      customerDeviceId: customer ? data.customerDeviceId : null,
      productId: !customer ? product.id : null,
      typeId: data.typeId,
      contractDate: moment(new Date(data.contractDate)).locale('en').format('YYYY-MM-DD'),
      expiryDate: moment(new Date(data.expiryDate)).locale('en').format('YYYY-MM-DD'),
      installmentsTypeId: data.installmentsTypeId,
      sellingPrice: data.sellingPrice,
      downPayment: data.downPayment,
      nbOfInstallments: data.nbOfInstallments,
      installmentAmount: data.installmentAmount,
      freeInstallation: data.freeInstallation,
      beneficiaryEmployeeId: data.beneficiaryEmployeeId,
      statusId: data.statusId,
      reference: data.reference,
      remarks: data.remarks,
      deviceStages: deviceStages.map((stage) => ({
        stageNb: stage.stageNb,
        productStageId: stage.productStageId,
        stageItemId: stage.stageItemId,
        installedItemId: stage.installedItemId,
        mandatoryReplacement: stage.mandatoryReplacement,
        replacementMonths: stage.replacementMonths,
        lastReplacementDate: stage.lastReplacementDate
          ? moment(new Date(stage.lastReplacementDate)).locale('en').format('YYYY-MM-DD')
          : null,
        remarks: stage.remarks
      })),
      productsStock: productTableData.map((product) => ({
        itemId: product.itemId,
        qty: product.qty,
        remainingQty: product.remainingQty,
        remarks: product.remarks
      })),
      servicesStock: serviceTableData.map((service) => ({
        serviceId: service.serviceId,
        qty: service.qty,
        remainingQty: service.remainingQty,
        remarks: service.remarks
      }))
    };

    try {
      await axiosInstance.post('Contract', contractParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} العقد بنجاح`,
        life: 2000
      });
      if (contractType === 'Company') {
        navigate('/MaintenanceContract');
      } else {
        navigate('/Contract');
      }
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} العقد: ${error}`,
        life: 3000
      });
    }
  };

  const steps = isEditing
    ? [
        { title: 'بيانات العقد الاساسية', content: <ContractBasicInfoStep /> },
        { title: 'تفاصيل العقد الجديد', content: <ContractDetails isEditing={isEditing} /> }
      ]
    : contractType === 'Client'
    ? [
        { title: 'اختر العميل', content: <CustomerChooserStep /> },
        { title: 'اختر الجهاز للعميل', content: <ProductChooserStep /> },
        { title: 'اختر عقد الصيانه', content: <ContractChooserStep /> },
        { title: 'بيانات العقد الاساسية', content: <ContractBasicInfoStep /> },
        { title: 'تفاصيل العقد الجديد', content: <ContractDetails isEditing={isEditing} /> }
      ]
    : [
        { title: 'اختر الجهاز للعميل', content: <ProductChooserStep /> },
        { title: 'بيانات العقد الاساسية', content: <ContractBasicInfoStep /> },
        { title: 'تفاصيل العقد الجديد', content: <ContractDetails isEditing={isEditing} /> }
      ];
  const [current, setCurrent] = useState(0);

  const items = steps.map((item) => ({ key: item.title, label: item.title }));
  const selectedProduct = watch('product');
  const selectedCustomer = watch('customer');

  // Define field names per step with appropriate types
  const stepFields: FieldPath<FormData>[][] = [
    [
      'typeId',
      'contractDate',
      'expiryDate',
      'installmentsTypeId',
      'downPayment',
      'nbOfInstallments',
      'beneficiaryEmployeeId',
      'remarks'
    ]
  ];

  const handleOnClick = async () => {
    let isValid = true;

    // Dynamically skip validation for `customer` if `contractType` is "Company"
    if (current === 0) {
      if (contractType === 'Company') {
        isValid = true; // Skip validation
      } else {
        isValid = await trigger('customer');
      }
    } else if (current === 1) {
      if (contractType === 'Client') {
        isValid = await trigger('product');
      } else {
        isValid = await trigger(stepFields[current]); // Validation for other contract types
      }
    } else if (current === 2 && contractType === 'Client') {
      // No validation needed as contract is optional
      isValid = true;
    } else {
      isValid = await trigger(stepFields[current]);
    }

    const errors = methods.formState.errors; // Retrieve the form errors

    if (isValid) {
      if (current === 0 && contractType !== 'Company' && !selectedCustomer) {
        toast.current?.show({
          severity: 'error',
          summary: 'خطآ',
          detail: 'اختر العميل اولا',
          life: 3000
        });
      } else if (current === 1 && contractType === 'Client' && !selectedProduct) {
        toast.current?.show({
          severity: 'error',
          summary: 'خطآ',
          detail: 'اختر المنتج اولا',
          life: 3000
        });
      } else {
        // Proceed to the next step
        setCurrent((prevIndex) => (prevIndex >= steps.length - 1 ? prevIndex : prevIndex + 1));
      }
    }
  };

  const handleBackClick = () => {
    setCurrent((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  useEffect(() => {
    if (contractType === 'Company') {
      setValue('typeId', 1);
    } else {
      setValue('typeId', 2);
    }
  }, [contractType]);

  useEffect(() => {
    const fetchContractData = async () => {
      if (contractId) {
        try {
          const response = await axiosInstance.get(`Contract/new/${contractId}`);
          let customerData;
          if (contractType === 'Client') {
            const customerResponse = await axiosInstance.get(`Customer/new/${customerId}`);
            customerData = customerResponse?.data?.data;
          }
          const contractData = response.data.data;
          const productResponse = await axiosInstance.get(`Product/new/${contractData.productId ?? deviceId}`);
          const productData = productResponse?.data?.data;

          reset({
            id: contractData.id,
            product: {
              id: contractData.productId ?? deviceId,
              deviceName: productData.name,
              skuCode: productData.skuCode
            },
            customerDeviceId: contractData.customerDeviceId,
            customer: customerData,
            typeId: contractData.typeId,
            contractDate: moment(new Date(contractData.contractDate)).toDate(),
            expiryDate: moment(new Date(contractData.expiryDate)).toDate(),
            freeInstallation: contractData.freeInstallation,
            sellingPrice: contractData.sellingPrice,
            installmentsTypeId: contractData.installmentsTypeId,
            installmentAmount: contractData.installmentAmount,
            downPayment: contractData.downPayment,
            nbOfInstallments: contractData.nbOfInstallments,
            beneficiaryEmployeeId: contractData.beneficiaryEmployeeId,
            statusId: contractData.statusId,
            reference: contractData.reference,
            remarks: contractData.remarks,
            deviceStages: contractData?.deviceStages?.map(
              (stage: {
                stageNb: any;
                productStageId: any;
                stageItemId: any;
                installedItemId: any;
                mandatoryReplacement: any;
                replacementMonths: any;
                lastReplacementDate: moment.MomentInput;
                remarks: any;
              }) => ({
                stageNb: stage.stageNb,
                productStageId: stage.productStageId,
                stageItemId: stage.stageItemId,
                installedItemId: stage.installedItemId,
                mandatoryReplacement: stage.mandatoryReplacement,
                replacementMonths: stage.replacementMonths,
                lastReplacementDate: stage.lastReplacementDate ? moment(new Date(stage.lastReplacementDate.toString())).toDate() : null,
                remarks: stage.remarks
              })
            ),
            productsStock: contractData?.productsStock?.map(
              (product: { itemId: any; qty: any; remainingQty: any; remarks: any }) => ({
                itemId: product.itemId,
                qty: product.qty,
                remainingQty: product.remainingQty,
                remarks: product.remarks
              })
            ),

            servicesStock: contractData?.servicesStock?.map(
              (service: { serviceId: any; qty: any; remainingQty: any; remarks: any }) => ({
                serviceId: service.serviceId,
                qty: service.qty,
                remainingQty: service.remainingQty,
                remarks: service.remarks
              })
            )
          });
          setIsEditing(true);
        } catch (error: any) {
          toast.current.show({
            type: 'error',
            summary: 'خطأ',
            detail: `فشل في جلب بيانات العقد: ${error.message}`,
            life: 3000
          });
        }
      }
    };

    fetchContractData();
  }, [contractId, reset]);

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة طلب عقد جديد'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={current} />
          </Card>
          {<Suspense fallback={<ProgressSpinner />}>{steps[current].content}</Suspense>}
          <div className={`${styles.buttonContainer} mb-5`}>
            {current > 0 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className='btn__nextbtn' type='submit'>
                {isEditing ? 'حفظ التعديلات' : 'إضافة العقد'}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditContract;

import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import moment from 'moment/moment';
import qs from 'qs';
import store from './redux/store';
import { logout } from './redux/user/userActions';

export const API_BASE_URL = true
  ? 'http://62.72.21.7:9090/api/v1'
  : 'http://localhost:9091/api/v1';

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 50000
});

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

type RetryFunction = (newToken: string) => void;

let isRefreshing = false;
let retryQueue: RetryFunction[] = [];

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.paramsSerializer = (params) =>
      qs.stringify(params, {
        serializeDate: (date) => moment(new Date(date)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      });

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;

    if (error.response) {
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url?.includes('/auth/login')
      ) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const response = await axios.post(
              `${API_BASE_URL}/auth/refresh-token`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('refresh_token')}`
                }
              }
            );
            const { access_token } = response.data.data;
            localStorage.setItem('token', access_token);
            retryQueue.forEach((retryFunction) => retryFunction(access_token));
            retryQueue = [];
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return axios(originalRequest);
          } catch (refreshError) {
            store.dispatch(logout() as any); // Ensure the action type matches
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve) => {
            const retryOriginalRequest: RetryFunction = (newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(axios(originalRequest));
            };
            retryQueue.push(retryOriginalRequest);
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

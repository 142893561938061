import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function ExpensesScreen() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'اجمالي المديونية',
      num: '1528888 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#F9D8D433'
    },
    {
      title: 'اجمالي الايرادات الكلية',
      num: '10000000 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#6D54DE33'
    },
    {
      title: 'الفرع',
      num: 'فرع 1',
      imageUrl: '/images/branch.svg',
      color: '#FFEDD0'
    }
  ];

  const columnsTab1 = [
    {
      header: 'رقم الفاتورة',
      field: 'invoiceNb',
      isChip: false
    },
    {
      header: 'تاريخ الفاتورة',
      field: 'creationDate',
      isDate: true
    },
    {
      header: 'الفاتورة موجهة الى',
      field: 'supplierName',
      isChip: false
    },
    {
      header: 'المبلغ الكلى للفتورة',
      field: 'totalNetAmount',
      isChip: false
    },
    {
      header: 'الكمية',
      field: 'totalItemsCount',
      isChip: false
    },
    {
      header: 'نسبة الخصم',
      field: 'totalDiscountPercent',
      isChip: false
    },
    {
      header: 'المبلغ المتبقى',
      field: 'totalRemainingAmount',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'المصروفات',
          tableHeader: 'قائمة المصروفات',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/4',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'المصروفات',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, supplierId: rowData.supplierID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة فاتورة مصروفات',
              onClick: () => {
                navigate('/finance/invoice/add', { state: { type: 4 } });
              }
            }
          ]
        }
      ]}
      headerTitle={'الحسابات'}
      headerIcon={'../images/coins.svg'}
      analyticsCards={headerDetails}
      cardsNum='3'
    />
  );
}

export default ExpensesScreen;

import React, { Suspense, useEffect, useState } from 'react';
import { useToast } from '../../../context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import PageHeader from '../../../components/components/Header/PageHeader';
import styles from './AddEditSalesOrder.module.scss';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from '../../../global';
import CustomerChooserStep from './CustomerChooserStep';
import SalesOrderBasicInfoStep from './SalesOrderBasicInfoStep';
import SalesOrderItems from './SalesOrderItems';
import SalesOrderReveiwStep from './SalesOrderReveiwStep';
import moment from 'moment';

const AddEditSalesOrder = () => {
  const toast = useToast();
  const location = useLocation();
  const { SalesOrderId, customerId } = location.state || {};
  const navigate = useNavigate();
  // Define your Yup schema
  const invoiceInfoSchema = yup.object({
    id: yup.number().optional().nullable(),
    customer: yup.object({
      id: yup.number()
    }),
    customerId: yup.number().nullable(),
    currencyId: yup.number().nullable().default(1),
    discountType: yup.number().nullable(),
    pointOfSalesId: yup.number().required('منفذ البيع مطلوب'),
    reference: yup.string().required('الرقم المرجعى للفتورة مطلوب'),
    remarks: yup.string().nullable(),
    items: yup.array().required('المنتجات مطلوبة').default([]),
    discountOnProducts: yup.number().nullable(),
    totalAmount: yup.number().nullable(),
    requstedAmount: yup.number().nullable(),
    mytotalDiscount: yup.number().nullable(),
    isEdit: yup.boolean().default(false)
  });

  // Infer FormData type from the schema
  type FormData = yup.InferType<typeof invoiceInfoSchema>;
  const [isEditing, setIsEditing] = useState(false);

  // Use FormData as the type parameter
  const methods = useForm<FormData>({
    resolver: yupResolver(invoiceInfoSchema),
    mode: 'all'
  });

  const { handleSubmit, trigger, reset, watch, setValue } = methods;

  useEffect(() => {}, [methods.formState.errors]);

  const handleSubmitOnClick = async (data: any) => {
    const productTableData = watch('items') || [];

    const productErrors =
      productTableData !== null && productTableData.length === 0
        ? []
        : productTableData
            ?.map((product, index) => {
              const errors: string[] = [];
              if (!product.productId) errors.push(`المنتج مطلوب`);
              if (product.qty == null || product.qty < 1)
                errors.push(`${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
              if (product.price == null || product.price < 0)
                errors.push(`${index + 1}: السعر مطلوب ولا يمكن أن يكون سالب او صفر`);
              return errors;
            })
            .flat();

    const allErrors = [...productErrors];

    if (allErrors.length > 0) {
      allErrors.forEach((error) => {
        toast.current.show({
          type: 'error',
          summary: 'خطأ في التحقق',
          detail: error,
          life: 3000
        });
      });
      return; // Prevent further processing if there are validation errors
    }

    const salesOrderParamDto = {
      id: data.id ?? null,
      customerId: data?.customer?.id,
      pointOfSalesId: data.pointOfSalesId,
      currencyId: 1,
      reference: data.reference,
      remarks: data.remarks,
      items: productTableData.map((products) => ({
        productId: products.productId,
        qty: products.qty ?? 0,
        discountPercent: products.discountPercent ?? 0,
        discountAmount: products.discountAmount ?? 0
      }))
    };

    try {
      isEditing
        ? await axiosInstance.put('/SalesOrder', salesOrderParamDto)
        : await axiosInstance.post('/SalesOrder', salesOrderParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} امر البيع بنجاح`,
        life: 2000
      });
      navigate('/Sales/SalesOrder');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} امر البيع: ${error}`,
        life: 3000
      });
    }
  };

  const steps = isEditing
    ? [
        { title: 'بيانات امر البيع الاساسية', content: <SalesOrderBasicInfoStep isEditing={isEditing} /> },
        { title: 'تعديل عناصر للفتورة', content: <SalesOrderItems isEditing={isEditing} /> },
        { title: 'مراجعة الطلب', content: <SalesOrderReveiwStep /> }
      ]
    : [
        { title: 'اختر العميل', content: <CustomerChooserStep /> },
        { title: 'بيانات امر البيع الاساسية', content: <SalesOrderBasicInfoStep isEditing={isEditing} /> },
        { title: 'إضافة عناصر للفتورة', content: <SalesOrderItems isEditing={isEditing} /> },
        { title: 'مراجعة الطلب', content: <SalesOrderReveiwStep /> }
      ];

  const [current, setCurrent] = useState(0);

  const items = steps.map((item) => ({ key: item.title, label: item.title }));
  const selectedCustomer = watch('customer');
  const stepFields: FieldPath<FormData>[][] = [['customer', 'pointOfSalesId'], ['reference'], ['items']];

  const handleOnClick = async () => {
    let isValid = true;
    if (current < stepFields.length) {
      isValid = await trigger(stepFields[current]);
    }
    if (isValid) {
      if (current === 0 && !selectedCustomer) {
        toast.current?.show({
          severity: 'error',
          summary: 'خطآ',
          detail: 'اختر العميل اولا',
          life: 3000
        });
      } else {
        setCurrent((prevIndex) => (prevIndex >= steps.length - 1 ? prevIndex : prevIndex + 1));
      }
    }
  };

  const handleBackClick = () => {
    setCurrent((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  useEffect(() => {
    const fetchInvoiceData = async () => {
      // debugger;
      if (SalesOrderId) {
        try {
          let customerData;
          const customerResponse = await axiosInstance.get(`Customer/new/${customerId}`);
          customerData = customerResponse?.data?.data;

          const response = await axiosInstance.get(`/SalesOrder/${SalesOrderId}`);
          const invoiceData = response.data.data;
          reset({
            id: invoiceData.id,
            customer: customerData,
            currencyId: invoiceData.currencyID,
            pointOfSalesId: invoiceData.pointOfSalesID,
            reference: invoiceData.reference,
            remarks: invoiceData.remarks,
            mytotalDiscount: invoiceData.totalDiscount,
            requstedAmount: invoiceData.totalNetAmount,
            isEdit: true,
            items: invoiceData?.items?.map(
              (product: {
                itemID: any;
                itemName: any;
                qty: any;
                price: any;
                discountPercent: any;
                discountAmount: any;
                netAmount: any;
              }) => ({
                productId: product.itemID,
                productName: product.itemName,
                qty: product.qty,
                price: product.price,
                discountPercent: product.discountPercent,
                discountAmount: product.discountAmount,
                totalPrice: product.netAmount,
                discountTypeTable: 2
              })
            )
          });
          setIsEditing(true);
        } catch (error: any) {
          toast.current.show({
            type: 'error',
            summary: 'خطأ',
            detail: `فشل في جلب بيانات امر البيع: ${error.message}`,
            life: 3000
          });
        }
      }
    };

    fetchInvoiceData();
  }, [SalesOrderId, reset]);

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة أمر بيع جديد'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={current} />
          </Card>
          {<Suspense fallback={<ProgressSpinner />}>{steps[current].content}</Suspense>}
          <div className={`${styles.buttonContainer} mb-5`}>
            {current > 0 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className='btn__nextbtn' type='submit'>
                {isEditing ? 'حفظ التعديلات' : 'إضافة امر البيع'}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditSalesOrder;

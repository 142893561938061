import styles from '../Header/Header.module.scss';
import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  key?: number;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
  customStyle?: string;
}

const CustomButton: React.FC<Props> = ({ key, label, onClick, type, customStyle }) => {
  return (
    <Button
      key={key ?? label}
      onClick={onClick}
      icon={<PlusOutlined />}
      className={styles.button}
      htmlType={type}
      style={{
        fontWeight: 'bold',
        marginRight: '.5rem',
        direction: 'rtl',
        minWidth: 'auto',
        marginTop: customStyle
      }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;

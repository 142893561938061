import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CustomArgsProps } from '../../../context/ToastContext';
import { Button, Col, Drawer, Row } from 'antd';

interface GenericModalProps {
  headerTitle: string;
  isVisible: boolean;
  onHide: () => void;
  handleSubmit: (data: any) => void;

  onDelete?: (data: any) => void;
  toast: { current: { show: (args: CustomArgsProps) => void } };
  children: React.ReactNode;
  loading: boolean;
  isEditMode?: boolean;
}

const GenericModal: React.FC<GenericModalProps> = ({
                                                     headerTitle,
                                                     isVisible,
                                                     onHide,
                                                     handleSubmit,
                                                     onDelete,
                                                     children,
                                                     loading,
                                                     isEditMode = false
                                                   }) => (
  <Drawer
    placement={'left'}
    title={headerTitle}
    open={isVisible}
    onClose={onHide}
  >
    {/* Forms Section */}
      <form onSubmit={handleSubmit} className="flex flex-column gap-5">
        {children}
        <div className="flex justify-content-end">
          {/*<Button label='حذف' onClick={onDelete} size='small' outlined type='button' severity={'danger'} />*/}
          <Button onClick={onHide} htmlType={'button'} variant={'text'} size="large">إلغاء</Button>
          <Button size="large" htmlType={'submit'} variant={'solid'} className='modals__btn'>{isEditMode ? 'تعديل' : 'إضافة'}</Button>
        </div>
      </form>

      {/* Overlay for loading */}
      {loading && (
        <div
          className="absolute top-0 left-0 w-full h-full flex justify-content-center align-items-center"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1 }}
        >
          <ProgressSpinner />
        </div>
      )}
  </Drawer>
);

export default GenericModal;

import React from 'react';
import { Col, Layout, Row, Typography, Divider, Grid } from 'antd';
import { UpOutlined } from '@ant-design/icons';

function Footer() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.md;

  const links = [
    { text: 'الرئيسية', href: '/' },
    { text: 'الشروط والأحكام', href: '#' },
    { text: 'سياسة الخصوصية', href: '#' },
    { text: 'تواصل معنا', href: '#' },
  ];

  return (
    <Layout.Footer
      style={{
        direction: 'rtl',
        background:
          'linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 70, 106, 1) 0%, rgba(0, 35, 30, 1) 100%)',
        height: 'auto',
        opacity: 1,
      }}
    >
      {/* Upper Row */}
      <Row
        justify="center"
        gutter={12}
        wrap
      >
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <Col>
              <Typography.Link style={{ color: '#fff' }} href={link.href}>
                {link.text}
              </Typography.Link>
            </Col>
            {!isMobile && index < links.length - 1 && (
              <Col>
                <Divider
                  type="vertical"
                  style={{ backgroundColor: '#fff', height: '1em' }}
                />
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>

      {/* Horizontal Divider */}
      <div style={{ width: '100%' }}>
        <Divider style={{ backgroundColor: '#9195b6', margin: '4px' }} />
      </div>
      {/* Lower Row */}
      <Row
        justify="space-around"
        gutter={12}
        wrap
      >
        <Col>
          <Typography.Link href="#" style={{ color: '#fff' }}>
            إذهب الى الاعلى
            <UpOutlined style={{ fontSize: '10px', marginRight: '5px' }} />
          </Typography.Link>
        </Col>
        <Col>
          <span style={{ color: '#fff' }}>Powered by OneClick</span>
        </Col>
        <Col>
          <span style={{ color: '#fff' }}>@Company name 2023</span>
        </Col>
      </Row>
    </Layout.Footer>
  );
}

export default React.memo(Footer);

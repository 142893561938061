import PageHeader from '../../../components/components/Header/PageHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, InputNumber, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from '../../../hooks/useFetch';
import moment from 'moment/moment';
import * as yup from 'yup';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import GenericEditableDataTable from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import { useToast } from '../../../context/ToastContext';
import styles from './AddConvenant.module.scss';
import CustomButton from '../../../components/components/Button/CustomButton';

const covenantSchema = yup.object({
  employeeId: yup.number().required('اسم المندوب مطلوب'),
  warehouseId: yup.number().required('اسم المخزن مطلوب'),
  fromDate: yup.date().required('من تاريخ مطلوب'),
  toDate: yup
    .date()
    .required('الي تاريخ مطلوب')
    .test('is-greater', 'التاريخ إلى يجب أن يكون أكبر من أو يساوي التاريخ من', function (value) {
      const { fromDate } = this.parent;
      return !fromDate || !value || value >= fromDate;
    }),
  items: yup.array().required('المنتجات مطلوبة').default([])
});

interface ProductRow {
  productId: number | null;
  availableQuantity: number | null;
  requestedQty: number | null;
  productName: string | null;
  warehouseLocationId: string | null;
  productCategoryName: string | null;
  warehouseLocation: string | null;
}

const AddCovenant = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [requestedQuantity, setRequestedQuantity] = useState<number>(0);

  const methods = useForm({
    resolver: yupResolver(covenantSchema),
    mode: 'all',
    defaultValues: {}
  });

  const {
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = methods;
  const fetchCustody = () => {
    return axiosInstance.get(
      `/Covenant/list/${watch('employeeId')}/${watch('warehouseId')}/${moment(new Date(watch('fromDate') ?? ''))
        .locale('en-GB')
        .format('YYYY-MM-DD')}/${moment(new Date(watch('toDate') ?? ''))
        .locale('en-GB')
        .format('YYYY-MM-DD')}}`
    );
  };
  const { data: warehouseData, isLoading: isWarehouseLoading } = useFetch('/Warehouse/dropdown');
  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');
  const fetchWarehouse = () => {
    return axiosInstance.get(`/Covenant/availableWarehousesLocationsWithProducts/${watch('warehouseId')}`);
  };

  const { lazyApiCall: customDyApiCall, state: custodyState } = useApiState({ apiCall: fetchCustody, isLazy: true });
  const { lazyApiCall: warehouseApiCall, state: warehouseState } = useApiState({
    apiCall: fetchWarehouse,
    isLazy: true
  });

  const handleSearchOnClick = async () => {
    const isValid = await trigger(['employeeId', 'fromDate', 'toDate', 'warehouseId']);
    const validationCollection = watch('employeeId') && watch('fromDate') && watch('toDate') && isValid;
    if (validationCollection) {
      try {
        await customDyApiCall();
      } catch (error) {
        toast.current.show({
          type: 'error',
          summary: 'خطأ في التحقق',
          detail: 'حدث خطأ أثناء البحث، يرجى المحاولة مرة أخرى',
          life: 5000
        });
      }
    } else {
      toast.current.show({
        type: 'error',
        summary: 'خطأ في التحقق',
        detail: 'يرجى التأكد من إدخال جميع الحقول المطلوبة',
        life: 5000
      });
    }
  };
  const [processedData, setProcessedData] = useState<ProductRow[]>([]);

  const memoizedData = useMemo(
    () =>
      (custodyState?.data?.data?.data ?? []).map(
        (
          item: {
            wh_Available_Qty: number | null;
            requestedQty: number | null;
            warehouseLocationID: number | null;
          },
          idx: any
        ) => ({
          ...item,
          id: idx,
          warehouseLocationId: item.warehouseLocationID,
          availableQuantity:
            item.wh_Available_Qty !== null && item.wh_Available_Qty !== 0 ? item.wh_Available_Qty : 'غير متاح',
          requestedQty: item.requestedQty
        })
      ),
    [custodyState?.data?.data?.data]
  );

  useEffect(() => {
    setProcessedData(memoizedData);
  }, [memoizedData]);

  useEffect(() => {
    trigger();
  }, [watch('warehouseId'), watch('fromDate'), watch('toDate'), watch('employeeId')]);

  const handleProductRowUpdate = (updatedData: ProductRow[]) => {
    const updatedRows = updatedData.map((row) => {
      const options = custodyState?.data?.data?.data ?? [];
      const selectedItem = options.find((option: any) => option.productId === row.productId);
      const MaxstockQty = selectedItem ? selectedItem.wh_Available_Qty : 0;
      let newRequestedQty = row.requestedQty || 0;

      if (newRequestedQty > MaxstockQty) {
        newRequestedQty = MaxstockQty;
        toast.current.show({
          type: 'error',
          summary: 'خطأ في البيانات',
          detail: 'لا يمكن اضافة كمية اكبر من الكمية الحالية فى المخزن',
          life: 5000
        });
      }

      if (newRequestedQty < 0) {
        newRequestedQty = 0;
        toast.current.show({
          type: 'error',
          summary: 'خطأ في البيانات',
          detail: 'لا يمكن اضافة كمية سالبة',
          life: 5000
        });
      }

      return {
        ...row,
        requestedQty: newRequestedQty
      };
    });

    setProcessedData(updatedRows);
    setValue('items', updatedRows);
  };

  const handleSubmitOnClick = async () => {
    try {
      const productTableData = (watch('items') || processedData)?.filter(
        (product) => product.availableQuantity !== 'غير متاح'
      );

      const productErrors =
        productTableData !== null && productTableData.length === 0
          ? []
          : productTableData
              ?.map((product, index) => {
                const errors: string[] = [];
                if (!product.productId) errors.push(`المنتج مطلوب`);
                if (
                  (product.requestedQty == null || product.requestedQty < 1) &&
                  product.availableQuantity !== 'غير متاح'
                )
                  errors.push(`${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
                return errors;
              })
              .flat();

      if (productErrors.length > 0) {
        productErrors.forEach((error) => {
          toast.current.show({
            type: 'error',
            summary: 'خطأ في التحقق',
            detail: error,
            life: 3000
          });
        });
        return;
      }
      const CovenantParamDto = {
        repId: watch('employeeId'),
        warehouseId: watch('warehouseId'),
        items: productTableData.map((products: any) => ({
          warehouseLocationId: products.warehouseLocationId ?? 0,
          productId: products.productId ?? 0,
          quantity: products.requestedQty ?? 0
        }))
      };
      await axiosInstance.post('/Covenant/warehouseRep', CovenantParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: 'تم إضافة العهدة بنجاح',
        life: 2000
      });
      navigate('/warehouses/transactions/');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: 'فشلت عملية إضافة العهدة',
        life: 3000
      });
    }
  };

  const handleModalOk = () => {
    if (!selectedWarehouseId) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'يرجى اختيار موقع التخزين',
        life: 3000
      });
      return;
    }

    if (!selectedProductId) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'يرجى اختيار المنتج',
        life: 3000
      });
      return;
    }

    if (!requestedQuantity || requestedQuantity < 1) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'يرجى إدخال كمية مطلوبة صحيحة',
        life: 3000
      });
      return;
    }

    // Check if requested quantity does not exceed available quantity
    if (requestedQuantity > availableQuantity) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'الكمية المطلوبة تتجاوز الكمية المتاحة',
        life: 3000
      });
      return;
    }

    // Get the selected product details
    const selectedProduct = warehouseState?.data?.data?.data
      .find((wh: { warehouseLocationId: any }) => wh.warehouseLocationId === selectedWarehouseId)
      ?.products.find((prod: { productId: any }) => prod.productId === selectedProductId);

    if (!selectedProduct) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'المنتج المحدد غير موجود',
        life: 3000
      });
      return;
    }

    // Check if the product is already in the grid
    const existingProduct = processedData.find((item) => item.productId === selectedProductId);
    if (existingProduct) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'المنتج موجود بالفعل في العهدة',
        life: 3000
      });
      return;
    }

    // Add the product to the grid
    const newRow: ProductRow = {
      productId: selectedProduct.productId,
      availableQuantity: selectedProduct.quantity,
      requestedQty: requestedQuantity,
      productName: selectedProduct.productName ?? '',
      productCategoryName: selectedProduct.productCategory,
      warehouseLocationId: warehouseState?.data?.data?.data.find(
        (wh: { warehouseLocationId: any }) => wh.warehouseLocationId === selectedWarehouseId
      )?.warehouseLocationId,
      warehouseLocation: warehouseState?.data?.data?.data.find(
        (wh: { warehouseLocationId: any }) => wh.warehouseLocationId === selectedWarehouseId
      )?.warehouseLocationName
    };

    setProcessedData([...processedData, newRow]);
    setValue('items', [...processedData, newRow]);

    setSelectedWarehouseId(null);
    setSelectedProductId(null);
    setAvailableQuantity(0);
    setRequestedQuantity(0);
    setIsModalOpen(false);
  };

  return (
    <>
      <PageHeader
        icon={'/images/back-arrow.svg'}
        buttons={[]}
        headerTitle={'إضافة عهدة للزيارة'}
        iconOnClick={() => navigate(-1)}
      />
      <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
        <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
          <Row align={'middle'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <CustomFormField
                control={control}
                name={'employeeId'}
                label={'اسم المندوب'}
                errors={errors}
                type={'dropdown'}
                loading={isEmployeeLoading}
                options={employeeData?.data ?? []}
              />
            </Col>
            <Col span={4}>
              <CustomFormField
                control={control}
                name={'fromDate'}
                label={'من تاريخ'}
                errors={errors}
                type={'calendar'}
              />
            </Col>
            <Col span={4}>
              <CustomFormField
                control={control}
                name={'toDate'}
                label={'الى تاريخ'}
                errors={errors}
                type={'calendar'}
              />
            </Col>
            <Col span={6}>
              <CustomFormField
                control={control}
                name={'warehouseId'}
                label={'المخزن'}
                errors={errors}
                type={'dropdown'}
                loading={isWarehouseLoading}
                options={warehouseData?.data ?? []}
              />
            </Col>
            <Col span={4}>
              <Button
                htmlType='button'
                onClick={handleSearchOnClick}
                className={styles.button}
                style={{ width: '100%' }}
              >
                بحث
              </Button>
            </Col>
          </Row>
        </Card>
      </form>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
          <Alert
            message='المنتجات'
            type='success'
            style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold' }}
          />
          <Alert
            message='جميع المنتجات الغير متاحة لن تضاف للعهدة و ستحذف تلقائيا'
            type='info'
            showIcon
            style={{ fontSize: '1rem', padding: '1.5rem' }}
          />
          <CustomButton
            label={'إضف منتج'}
            type={'button'}
            onClick={async () => {
              await warehouseApiCall();
              setIsModalOpen(true);
            }}
          />
          <GenericEditableDataTable
            data={processedData ?? []}
            columns={[
              {
                field: 'productName',
                header: 'المنتج',
                editable: false,
                type: 'text'
              },
              {
                field: 'productCategoryName',
                header: 'تصينف المنتج',
                editable: false,
                type: 'text'
              },
              {
                field: 'warehouseLocation',
                header: 'مكان التخزين',
                editable: false,
                type: 'text'
              },
              {
                field: 'availableQuantity',
                header: 'الكميه المتاحه',
                editable: false,
                type: 'text'
              },
              {
                field: 'requestedQty',
                header: 'الكميه المطلوبه',
                editable: true,
                type: 'number'
              }
            ]}
            isLoading={false}
            onRowUpdate={handleProductRowUpdate}
          />
        </Space>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1.5rem' }}>
          <Button className={styles.Submitbutton} htmlType='button' onClick={handleSubmitOnClick}>
            اضافة العهدة للموظف
          </Button>
        </div>
      </Card>

      <Modal
        title='اضافه منتج للعهده'
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={(e) => {
          setIsModalOpen(!isModalOpen);
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Select
              optionFilterProp='label'
              value={selectedWarehouseId}
              onChange={(value) => {
                setSelectedWarehouseId(value);
                setSelectedProductId(null); // Reset product selection
                setAvailableQuantity(0); // Reset available quantity
              }}
              options={warehouseState?.data?.data?.data.map(
                (option: { warehouseLocationName: any; warehouseLocationId: any }) => ({
                  label: option.warehouseLocationName,
                  value: option.warehouseLocationId
                })
              )}
              placeholder='اختر موقع التخزين'
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={24}>
            <Select
              optionFilterProp='label'
              value={selectedProductId}
              onChange={(value, option) => {
                setSelectedProductId(value);
                const selectedProduct = warehouseState?.data?.data?.data
                  .find((wh: { warehouseLocationId: null }) => wh.warehouseLocationId === selectedWarehouseId)
                  ?.products.find((prod: { productId: null }) => prod.productId === value);
                setAvailableQuantity(selectedProduct?.quantity || 0);
              }}
              placeholder='اختر المنتج'
              style={{ width: '100%' }}
              disabled={!selectedWarehouseId}
              options={
                selectedWarehouseId
                  ? warehouseState?.data?.data?.data
                      .find((wh: { warehouseLocationId: any }) => wh.warehouseLocationId === selectedWarehouseId)
                      ?.products.map((prod: { productName: any; quantity: any; productId: any }) => ({
                        label: `${prod.productName} (متاح: ${prod.quantity})`,
                        value: prod.productId
                      })) || []
                  : []
              }
            />
          </Col>
          <Col span={24}>
            <InputNumber
              min={1}
              max={availableQuantity ?? 0}
              value={requestedQuantity}
              onChange={(value) => setRequestedQuantity(value ?? 0)}
              placeholder='أدخل الكمية المطلوبة'
              style={{ width: '100%' }}
              disabled={!selectedProductId}
            />
            {availableQuantity !== null && (
              <div style={{ marginTop: '0.5rem', color: 'rgba(0,0,0,0.45)' }}>الكمية المتاحة: {availableQuantity}</div>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AddCovenant;

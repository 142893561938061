import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import useFetch from '../../../hooks/useFetch';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useToast } from '../../../context/ToastContext';
import { axiosInstance } from '../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const SalesOrderDetails = () => {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { SalesOrderIds } = location.state || {};

  const { data: salesOrderData, isLoading: salesOrderIsLoading } = useFetch(`/SalesOrder/${SalesOrderIds}`);

  function showConfirmDeleteSO(e: any) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف أمر البيع؟',
      header: 'تأكيد حذف أمر البيع',
      accept: () => {
        handleDeleteSO();
      },
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold',
      reject: () => {
        // Logic for rejection, if needed
      },
      rejectLabel: 'إلغاء',
      draggable: false,
      resizable: false,
      tagKey: '1'
    });
  }

  function handleDeleteSO() {
    axiosInstance
      .delete(`/SalesOrder/${SalesOrderIds}`)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'تم حذف أمر البيع', detail: '', life: 1500 });
        setTimeout(() => navigate('/Sales/PointofSales'), 1500);
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف أمر البيع', detail: '', life: 1500 });
      });
  }

  return (
    <>
      {/* ======================================================the header of page ================================================= */}

      <div className='detailsheet__header'>
        <ConfirmDialog tagKey={'1'} />
        <div className='detailsheet__header__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='detailsheet__header__right--text'>تفاصيل الاذن</span>
        </div>

        <div className='detailsheet__header__left'>
          {/* <Button
            className='supplier-details__header1__left__btn'
            onClick={() =>
              navigate(`/Sales/SalesOrder/edit`, {
                state: { SalesOrderId: SalesOrderIds, customerId: salesOrderData?.data?.customerID }
              })
            }
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            تعديل بيانات أمر البيع
          </Button> */}

          <Button
            className='supplier-details__header1__left__btn'
            onClick={showConfirmDeleteSO}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف أمر البيع
          </Button>
        </div>
      </div>

      {/* ======================================================the body of page ================================================= */}
      <div className='detailsheet__contentgeneral'>
        {salesOrderIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <div className='customerdetails__header__card'>
            <HeaderCardTS
              title={'تاريخ البيع'}
              num={moment(salesOrderData?.data?.creationDate)
                .locale('en-GB')
                .format('YYYY-MM-DD')}
              imageUrl={'/images/alt.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCardTS
              title={'المنفذ'}
              num={salesOrderData?.data?.pointOfSalesName}
              imageUrl={'/images/alt.svg'}
              color={'#F9D8D433'}
            />
            <HeaderCardTS
              title={'الكمية المباعة'}
              num={salesOrderData?.data?.nbofItems}
              imageUrl={'/images/alt.svg'}
              color={'#FFEDD0'}
            />
            <HeaderCardTS
              title={'البائع'}
              num={salesOrderData?.data?.creatorUserName}
              imageUrl={'/images/dropbox.svg'}
              color={'#C050E838'}
            />
          </div>
        )}

        <div className='detailsheet__contentgeneral__grid'>
          {salesOrderIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={[
                'كود أمر البيع',
                'تاريخ البيع',
                'المنفذ',
                'العميل',
                'المبلغ الكلي',
                'نسبة الخصم',
                'المجموع ',
                'ملاحظات'
              ]}
              contents={[
                salesOrderData?.data?.code,
                moment(salesOrderData?.data?.creationDate)
                  .locale('en-GB')
                  .format('YYYY-MM-DD'),
                salesOrderData?.data?.pointOfSalesName,
                salesOrderData?.data?.customerName,
                salesOrderData?.data?.totalAmount,
                salesOrderData?.data?.totalDiscountPercent,
                salesOrderData?.data?.totalNetAmount,
                salesOrderData?.data?.remarks
              ]}
              title={'بيانات امر البيع'}
            />
          )}
          {/* ======================================================the table of page ================================================= */}
          <div className='tbl mt-5'>
            <DataTable
              value={salesOrderData?.data?.items}
              className='tbl__table'
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column field='itemName' header='اسم المنتج'></Column>
              <Column field='qty' header='الكمية'></Column>
              <Column field='price' header='سعر المنتج'></Column>
              <Column field='amount' header='المبلغ'></Column>
              <Column field='discountPercent' header='الخصم(نسبة)'></Column>
              <Column field='discountAmount' header='الخصم(مبلغ)'></Column>
              <Column field='netAmount' header='السعر بعد الخصم'></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesOrderDetails;

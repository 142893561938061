import React from 'react';
// import ActivitesScreenTable from '../../../components/Tables/ActivitesScreenTable';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';
const columns = [
  { header: 'كود الزيارة', field: 'visitCode', isChip: false },
  { header: 'العميل', field: 'customerName', isChip: false },
  { header: 'نوع الزيارة', field: 'visitTypeName', isChip: false },
  { header: 'الفنى', field: 'assignedRepName', isChip: false },
  { header: 'الاهمية', field: 'priorityName', isChip: false },
  { header: 'تاريخ الزيارة', field: 'visitDate', isDate: true },
  { header: 'الحالة', field: 'statusName', isChip: true }
];

function VisitsScreen() {
  const navigate = useNavigate();

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'بيانات الزيارة',
          tableHeader: 'جدول الزيارات',
          dataKey: 'الزيارة',
          emptyStateButtonClick: () => {},
          apiUrl: 'Visit/list',
          edit: false,
          columns: columns,
          emptyStateUrl: '',
          entityName: 'بيانات الزيارة',
          rowOnClick: (rowData) => {
            navigate(`/visits/details/visit/${rowData.id}/${rowData.customerID}`);
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        }
      ]}
      headerTitle={'الزيارات'}
      headerIcon={'../images/briefcase1.svg'}
    />
  );
}

export default VisitsScreen;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { axiosInstance } from '../../../global';
import './SupplierDetails.scss';
import { useToast } from '../../../context/ToastContext';
import SuppliersDetailsTables from './SuppliersDetailsTables';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import { useApiState } from '../../../hooks/useApiState';

function SupplierDetails() {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };
  const toast = useToast();

  const params = useParams();
  const location = useLocation();
  const { supplierId, type } = location.state || {};

  const navigate = useNavigate();

  const fetchSuppliersVisit = async () => {
    const response = await axiosInstance.get(`/Supplier/${supplierId}`);
    return response.data;
  };

  const { lazyApiCall, state } = useApiState({ apiCall: fetchSuppliersVisit, isLazy: true });

  useEffect(() => {
    if (supplierId) {
      lazyApiCall();
    }
  }, [supplierId]);

  function showConfirmDeleteSupplier(e: any) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف المورد؟',
      header: 'تأكيد حذف المورد',
      accept: () => {
        handleDeleteSupplier();
      },
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold',
      reject: () => {
        // Logic for rejection, if needed
      },
      rejectLabel: 'إلغاء',
      draggable: false,
      resizable: false,
      tagKey: '1'
    });
  }

  function handleDeleteSupplier() {
    axiosInstance
      .delete(`/Supplier/${supplierId}`)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'تم حذف المورد', detail: '', life: 1500 });
        setTimeout(() => navigate('/warehouses/suppliers'), 1500);
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف المورد', detail: '', life: 1500 });
      });
  }

  return (
    <div className=' main w-full'>
      <div className='supplier-details__header1'>
        <ConfirmDialog tagKey={'1'} />
        <div className='supplier-details__header1__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='supplier-details__header1__right--text'>تفاصيل المورد "{state?.data?.data.name}"</span>
        </div>

        <div className='supplier-details__header__left'>
          <Button
            className='supplier-details__header1__left__btn'
            onClick={() => navigate(`/warehouses/supplier/edit/${supplierId}`)}
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            تعديل البيانات المورد
          </Button>

          <Button
            className='supplier-details__header1__left__btn'
            onClick={showConfirmDeleteSupplier}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف المورد
          </Button>
        </div>
      </div>
      <div className='supplier-details'>
        <div className='customerdetails__header__card3'>
          <HeaderCardTS
            title={'الاقساط المطلوبة'}
            num={'14 قسط'}
            imageUrl={'/images/coins.svg'}
            color={'#F9D8D4'}
            txtColor={'red'}
          />
          <HeaderCardTS
            title={'اجمالى المديونية'}
            num={'1500 ج.م'}
            imageUrl={'/images/officelife.svg'}
            color={'#6D54DE33'}
          />
          <HeaderCardTS
            title={'عدد الفواتير'}
            num={'5 فواتير'}
            imageUrl={'/images/user_box_light.svg'}
            color={'#FFEDD0'}
          />
        </div>
        <div className='customerdetails__content customerdetails__content--wrapper'>
          {/*=========================================== right content ================================================================= */}
          <div className='customerdetails__content__right'>
            <div className='customerdetails__content__right__customer__name'>{state?.data?.data?.name}</div>

            <div className='primary__data'>
              <span className='primary__data--label'> كود المورد: </span>
              <span className='primary__data--link'>{state?.data?.data?.id}</span>
            </div>

            <div className='primary__data'>
              <span className='primary__data--label'>حالة المورد: </span>
              <Chip label={state?.data?.data?.status?.name} />
            </div>

            <span className='primary__data--title mt-4 flex flex-row gap-2'>
              بيانات التواصل<img src='/images/2.svg'></img>
            </span>

            <div className='primary__data'>
              <span className='primary__data--label'> طريقة التواصل: </span>
              <span className='primary__data--text'>{state?.data?.data?.deliveryTerm?.name}</span>
            </div>

            <div className='primary__data'>
              <span className='primary__data--label'> رقم الهاتف: </span>
              <span className='primary__data--text flex flex-row gap-2'>
                {state?.data?.data?.phone}
                <img src='/images/copy.svg'></img>
              </span>
            </div>
          </div>

          {/*=========================================== left content ================================================================= */}
          <div className='customerdetails__content__left'>
            <span className='primary__data--title mt-4 flex flex-row gap-2'>
              بيانات المورد <img src='/images/2.svg'></img>
            </span>
            <div className='primary__data'>
              <span className='primary__data--label'>الـــــــــــــــــــــــدولة : </span>
              <span className='primary__data--text'>{state?.data?.data?.country?.name}</span>
            </div>

            <span className='primary__data--title mt-4 flex flex-row gap-2'>
              الملاحظات <img src='/images/2.svg'></img>
            </span>
            <span className='primary__data--text'>{state?.data?.data?.remarks}</span>
          </div>
        </div>

        <SuppliersDetailsTables supplierID={supplierId} types={type} />
      </div>
    </div>
  );
}

export default SupplierDetails;

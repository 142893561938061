import React from 'react';
import { Image, Space, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import CustomButton from '../Button/CustomButton';

const { Title } = Typography;

interface PageHeaderProps {
  icon: string;
  iconOnClick?: () => void;
  buttons?: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
  headerTitle: string;
  style?: React.CSSProperties;
  className?: string;
  extraStartContent?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({
                                                 icon,
                                                 iconOnClick,
                                                 buttons = [],
                                                 headerTitle,
                                                 style,
                                                 className,
                                                 extraStartContent
                                               }) => {
  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 999,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style
      }}
      className={className}
    >
      {/* Start Section */}
      <Space align="center">
        <Image
          preview={false}
          src={icon}
          alt=""
          style={{
            cursor: iconOnClick ? 'pointer' : 'default', objectFit: 'contain',
          }}
          onClick={iconOnClick}
        />
        <Title
          level={3}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {headerTitle}
        </Title>
        {extraStartContent}
      </Space>

      {/* End Section */}
      <Space>
        {buttons?.map((btn, index) => (
          <CustomButton key={index} label={btn.label} onClick={btn.onClick} />
        ))}
      </Space>
    </Header>
  );
};

export default PageHeader;

import React, { useRef, useState } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import GenericForm from '../../../components/Forms/GenericForm';
import ProductFamilyForm from './Forms/ProductFamilyForm';
import * as yup from 'yup';
import ProductCategoryForm from './Forms/ProductCategoryForm';
import GovernrateInstallmentsTypeForm from './Forms/GovernrateInstallmentsTypeForm';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { useToast } from '../../../context/ToastContext';

const productFamilyScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  productTypeId: yup.number().required('ProductType is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const productCategoryScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  productFamilyId: yup.number().required('ProductType is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

const govInstallmentScheme = yup.object().shape({
  governorateId: yup.number().nullable().default(null)
  // listOfInstallmentTypeId: yup.number().required('InstallmentsType is required')
});

function Products() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const [myrowData, setMyRowData] = useState<GovernorateData | {}>({});
  const [expandFormVisible, setExpandFormVisible] = useState(false);

  interface GovernorateData {
    governorateId: number;
    governorateName: string;
    installmentTypes: {
      id: number;
      name: string;
    }[];
  }

  const handleActionButtonClick = (rowData: any) => {
    setMyRowData(rowData);
    setExpandFormVisible(true);
  };

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isPaymentTypeVisitable,
    openModal: paymentTypeOpenModal,
    closeModal: paymentTypeCloseModal,
    control: paymentTypeControl,
    handleSubmit: paymentTypeSubmit,
    errors: paymentTypeErrors,
    setValue: paymentTypeSetValue,
    loading: paymentTypeLoading,
    setLoading: paymentTypeSetLoading,
    watch: paymentTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/PaymentType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isProductStagesTypeVisitable,
    openModal: productStagesTypeOpenModal,
    closeModal: productStagesTypeCloseModal,
    control: productStagesTypeControl,
    handleSubmit: productStagesTypeSubmit,
    errors: productStagesTypeErrors,
    setValue: productStagesTypeSetValue,
    loading: productStagesTypeLoading,
    setLoading: productStagesTypeSetLoading,
    watch: productStagesTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ProductsStagesType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isManufacturerVisitable,
    openModal: manufacturerOpenModal,
    closeModal: manufacturerCloseModal,
    control: manufacturerControl,
    handleSubmit: manufacturerSubmit,
    errors: manufacturerErrors,
    setValue: manufacturerSetValue,
    loading: manufacturerLoading,
    setLoading: manufacturerSetLoading,
    watch: manufacturerWatch
  } = useModal({ remarks: ' ', names: [] }, '/Manufacturer', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isServiceVisitable,
    openModal: serviceOpenModal,
    closeModal: serviceCloseModal,
    control: serviceControl,
    handleSubmit: serviceSubmit,
    errors: serviceErrors,
    setValue: serviceSetValue,
    loading: serviceLoading,
    setLoading: serviceSetLoading,
    watch: serviceWatch
  } = useModal({ remarks: ' ', names: [] }, '/Service', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isProductsFamilyVisitable,
    openModal: productsFamilyOpenModal,
    closeModal: productsFamilyCloseModal,
    control: productsFamilyControl,
    handleSubmit: productsFamilySubmit,
    errors: productsFamilyErrors,
    setValue: productsFamilySetValue,
    loading: productsFamilyLoading,
    setLoading: productsFamilySetLoading,
    watch: productsFamilyWatch
  } = useModal({ remarks: ' ', names: [] }, '/ProductsFamily', fetchTabData, productFamilyScheme, toast);

  const {
    isVisible: isProductsCategoryVisitable,
    openModal: productsCategoryOpenModal,
    closeModal: productsCategoryCloseModal,
    control: productsCategoryControl,
    handleSubmit: productsCategorySubmit,
    errors: productsCategoryErrors,
    setValue: productsCategorySetValue,
    loading: productsCategoryLoading,
    setLoading: productsCategorySetLoading,
    watch: productsCategoryWatch
  } = useModal({ remarks: ' ', names: [] }, '/ProductsCategory', fetchTabData, productCategoryScheme, toast);

  const {
    isVisible: isContractsTypeVisitable,
    openModal: contractsTypeOpenModal,
    closeModal: contractsTypeCloseModal,
    control: contractsTypeControl,
    handleSubmit: contractsTypeSubmit,
    errors: contractsTypeErrors,
    setValue: contractsTypeSetValue,
    loading: contractsTypeLoading,
    setLoading: contractsTypeSetLoading,
    watch: contractsTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ContractsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isBrandVisitable,
    openModal: brandOpenModal,
    closeModal: brandCloseModal,
    control: brandControl,
    handleSubmit: brandSubmit,
    errors: brandErrors,
    setValue: brandSetValue,
    loading: brandLoading,
    setLoading: brandSetLoading,
    watch: brandWatch
  } = useModal({ remarks: ' ', names: [] }, '/Brand', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isCustomersDevicesTypeVisitable,
    openModal: customersDevicesTypeOpenModal,
    closeModal: customersDevicesTypeCloseModal,
    control: customersDevicesTypeControl,
    handleSubmit: customersDevicesTypeSubmit,
    errors: customersDevicesTypeErrors,
    setValue: customersDevicesTypeSetValue,
    loading: customersDevicesTypeLoading,
    setLoading: customersDevicesTypeSetLoading,
    watch: customersDevicesTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/CustomersDevicesType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isGovInstallmentVisitable,
    openModal: govInstallmentOpenModal,
    closeModal: govInstallmentCloseModal,
    control: govInstallmentControl,
    handleSubmit: govInstallmentSubmit,
    errors: govInstallmentErrors,
    setValue: govInstallmentSetValue,
    loading: govInstallmentLoading,
    setLoading: govInstallmentSetLoading,
    watch: govInstallmentWatch
  } = useModal({}, '/GovernoratesRestrictedInstallmentsType', fetchTabData, govInstallmentScheme, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'بيانات التقسيط',
            emptyStateButtonClick: () => {
              paymentTypeOpenModal();
            },
            dataKey: 'PaymentType',
            columns: [
              { header: 'انواع الدفع', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع دفع جديدة',
                onClick: () => {
                  paymentTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              paymentTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'PaymentType/list',
            entityName: 'نوع القسط',
            tableHeader: 'جدول بيانات الدفع',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات المراحل',
            emptyStateButtonClick: () => {},
            dataKey: 'ProductsStagesType',
            columns: [
              { header: 'انواع المراحل', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع مراحل جديدة',
                onClick: () => {
                  productStagesTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              productStagesTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ProductsStagesType/list',
            entityName: 'بيانات مرحلة',
            tableHeader: 'جدول بيانات المراحل',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات الماركات',
            emptyStateButtonClick: () => {},
            dataKey: 'Brand',
            columns: [
              { header: 'انواع الماركات', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع ماركات جديدة',
                onClick: () => {
                  brandOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              brandOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Brand/list',
            entityName: 'نوع الماركة',
            tableHeader: 'جدول بيانات الماركات',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات المصنعين',
            emptyStateButtonClick: () => {},
            dataKey: 'Manufacturer',
            columns: [
              { header: 'اسماء المصنعين', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة اسماء المصنعين جديدة',
                onClick: () => {
                  manufacturerOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              manufacturerOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Manufacturer/list',
            entityName: 'نوع الماركة',
            tableHeader: 'جدول بيانات المصنعين',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات الخدمات',
            emptyStateButtonClick: () => {
              serviceOpenModal();
            },
            dataKey: 'Service',
            columns: [
              { header: 'انواع الخدمات', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع خدمات جديدة',
                onClick: () => {
                  serviceOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              serviceOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Service/list',
            entityName: 'نوع الخدمة',
            tableHeader: 'جدول بيانات الخدمات',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات تصنيف المنتجات',
            emptyStateButtonClick: () => {
              productsFamilyOpenModal();
            },
            dataKey: 'ProductsFamily',
            columns: [
              { header: 'تصنيف المنتجات', field: 'name', isChip: false },
              { header: 'انواع المنتجات', field: 'productTypeName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة تصنيف المنتجات',
                onClick: () => {
                  productsFamilyOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              productsFamilyOpenModal({
                id: rowData.id,
                name: rowData.name,
                productTypeId: rowData.productTypeId,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ProductsFamily/list',
            entityName: 'تصنيف المنتج',
            tableHeader: 'جدول بيانات تصنيف المنتجات',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات فئات المنتجات',
            emptyStateButtonClick: () => {
              productsCategoryOpenModal();
            },
            dataKey: 'ProductsCategory',
            columns: [
              { header: 'فئة المنتجات', field: 'name', isChip: false },
              { header: 'انواع المنتجات', field: 'productTypeName', isChip: false },
              { header: 'تصنيف المنتجات', field: 'productFamilyName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة فئات المنتجات',
                onClick: () => {
                  productsCategoryOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              productsCategoryOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks,
                productFamilyId: rowData.productFamilyId,
                productTypeId: rowData.productTypeId
              });
            },
            apiUrl: 'ProductsCategory/list',
            entityName: 'فئة المنتج',
            tableHeader: 'جدول بيانات فئات المنتجات',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات العقود',
            emptyStateButtonClick: () => {
              contractsTypeOpenModal();
            },
            dataKey: 'ContractsType',
            columns: [
              { header: 'انواع العقود', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع عقد جديد',
                onClick: () => {
                  contractsTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              contractsTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ContractsType/list',
            entityName: 'نوع العقد',
            tableHeader: 'جدول بيانات العقود',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات الاجهزة',
            emptyStateButtonClick: () => {
              customersDevicesTypeOpenModal();
            },
            dataKey: 'CustomersDevicesType',
            columns: [
              { header: 'انواع الاجهزة', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع اجهزة جديدة',
                onClick: () => {
                  customersDevicesTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              customersDevicesTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'CustomersDevicesType/list',
            entityName: 'نوع الجهاز',
            tableHeader: 'جدول بيانات انواع اجهزة العملاء',
            emptyStateUrl: ''
          },
          {
            label: 'بيانات خطط التقسيط المحظورة',
            emptyStateButtonClick: () => {
              govInstallmentOpenModal();
            },
            dataKey: 'GovernoratesRestrictedInstallmentsType',
            columns: [
              {
                header: 'المحافظة',
                field: 'governorateName',
                isChip: false
              },
              {
                header: 'خطط التقسيط',
                field: 'installmentsTypeName',
                isButton: true,
                actionCallback: (rowData) => handleActionButtonClick(rowData)
              }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة خطة تقسيط محظورة ',
                onClick: () => {
                  govInstallmentOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              const installmentTypeIds = rowData.installmentTypes.map((type: { id: number }) => type.id);
              govInstallmentOpenModal({
                governorateId: rowData.governorateId,
                listOfInstallmentTypeId: installmentTypeIds
              });
            },
            apiUrl: 'GovernoratesRestrictedInstallmentsType/list',
            entityName: 'نوع التقسيط',
            tableHeader: 'جدول بيانات خطط التقسيط المحظورة',
            emptyStateUrl: ''
          }
        ]}
        headerTitle={' بيانات المنتجات و الخدمات'}
        headerIcon={'../images/productbox.svg'}
      />
      <GenericModal
        headerTitle={'إضافة انواع دفع جديدة'}
        isVisible={isPaymentTypeVisitable}
        onHide={paymentTypeCloseModal}
        handleSubmit={paymentTypeSubmit}
        toast={toast}
        loading={paymentTypeLoading}
        isEditMode={paymentTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={paymentTypeControl}
          watch={paymentTypeWatch}
          errors={paymentTypeErrors}
          setValue={paymentTypeSetValue}
          extraProps={{
            entityName: 'نوع الدفع'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع مراحل جديدة'}
        isVisible={isProductStagesTypeVisitable}
        onHide={productStagesTypeCloseModal}
        handleSubmit={productStagesTypeSubmit}
        toast={toast}
        loading={productStagesTypeLoading}
        isEditMode={productStagesTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={productStagesTypeControl}
          watch={productStagesTypeWatch}
          errors={productStagesTypeErrors}
          setValue={productStagesTypeSetValue}
          extraProps={{
            entityName: 'بيانات مرحلة'
          }}
        />
      </GenericModal>
      <Dialog
        header={`${(myrowData as GovernorateData).governorateName}:`}
        visible={expandFormVisible}
        style={{ width: '50vw' }}
        onHide={() => {
          if (!expandFormVisible) return;
          setExpandFormVisible(false);
        }}
        className='dialog'
      >
        {myrowData && (myrowData as GovernorateData).installmentTypes ? (
          <div>
            {(myrowData as GovernorateData).installmentTypes.map((installment) => (
              <Chip key={installment.id} className='dialog__innerDta' label={installment.name} />
            ))}
          </div>
        ) : (
          <p>لا يوجد خطط تقسيط لهذه المحافظة</p>
        )}
      </Dialog>

      <GenericModal
        headerTitle={'إضافة انواع ماركات جديدة'}
        isVisible={isManufacturerVisitable}
        onHide={manufacturerCloseModal}
        handleSubmit={manufacturerSubmit}
        toast={toast}
        loading={manufacturerLoading}
        isEditMode={manufacturerWatch('id') !== undefined}
      >
        <GenericForm
          key={manufacturerWatch('id')}
          control={manufacturerControl}
          watch={manufacturerWatch}
          errors={manufacturerErrors}
          setValue={manufacturerSetValue}
          extraProps={{
            entityName: 'نوع ماركة'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع خدمات جديدة'}
        isVisible={isServiceVisitable}
        onHide={serviceCloseModal}
        handleSubmit={serviceSubmit}
        toast={toast}
        loading={serviceLoading}
        isEditMode={serviceWatch('id') !== undefined}
      >
        <GenericForm
          key={serviceWatch('id')}
          control={serviceControl}
          watch={serviceWatch}
          errors={serviceErrors}
          setValue={serviceSetValue}
          extraProps={{
            entityName: 'نوع الخدمة'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة تصنيف منتجات جديدة'}
        isVisible={isProductsFamilyVisitable}
        onHide={productsFamilyCloseModal}
        handleSubmit={productsFamilySubmit}
        toast={toast}
        loading={productsFamilyLoading}
        isEditMode={productsFamilyWatch('id') !== undefined}
      >
        <ProductFamilyForm
          key={productsFamilyWatch('id')}
          watch={productsFamilyWatch}
          control={productsFamilyControl}
          errors={productsFamilyErrors}
          setValue={productsFamilySetValue}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة فئة منتجات جديدة'}
        isVisible={isProductsCategoryVisitable}
        onHide={productsCategoryCloseModal}
        handleSubmit={productsCategorySubmit}
        toast={toast}
        loading={productsCategoryLoading}
        isEditMode={productsCategoryWatch('id') !== undefined}
      >
        <ProductCategoryForm
          key={productsCategoryWatch('id')}
          watch={productsCategoryWatch}
          control={productsCategoryControl}
          errors={productsCategoryErrors}
          setValue={productsCategorySetValue}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع عقود جديدة'}
        isVisible={isContractsTypeVisitable}
        onHide={contractsTypeCloseModal}
        handleSubmit={contractsTypeSubmit}
        toast={toast}
        loading={contractsTypeLoading}
        isEditMode={contractsTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={contractsTypeWatch('id')}
          control={contractsTypeControl}
          watch={contractsTypeWatch}
          errors={contractsTypeErrors}
          setValue={contractsTypeSetValue}
          extraProps={{
            entityName: 'نوع العقد'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع عقود جديدة'}
        isVisible={isCustomersDevicesTypeVisitable}
        onHide={customersDevicesTypeCloseModal}
        handleSubmit={customersDevicesTypeSubmit}
        toast={toast}
        loading={customersDevicesTypeLoading}
        isEditMode={customersDevicesTypeWatch('id') !== undefined}
      >
        <GenericForm
          key={customersDevicesTypeWatch('id')}
          control={customersDevicesTypeControl}
          watch={customersDevicesTypeWatch}
          errors={customersDevicesTypeErrors}
          setValue={customersDevicesTypeSetValue}
          extraProps={{
            entityName: 'نوع الجهاز'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع ماركات جديدة'}
        isVisible={isBrandVisitable}
        onHide={brandCloseModal}
        handleSubmit={brandSubmit}
        toast={toast}
        loading={brandLoading}
        isEditMode={brandWatch('id') !== undefined}
      >
        <GenericForm
          key={brandWatch('id')}
          control={brandControl}
          watch={brandWatch}
          errors={brandErrors}
          setValue={brandSetValue}
          extraProps={{
            entityName: 'نوع الماركه'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة خطة تقسيط محظورة'}
        isVisible={isGovInstallmentVisitable}
        onHide={govInstallmentCloseModal}
        handleSubmit={govInstallmentSubmit}
        toast={toast}
        loading={govInstallmentLoading}
        isEditMode={govInstallmentWatch('governorateId') !== undefined}
      >
        <GovernrateInstallmentsTypeForm
          key={govInstallmentWatch('id')}
          control={govInstallmentControl}
          watch={govInstallmentWatch}
          errors={govInstallmentErrors}
          setValue={govInstallmentSetValue}
          extraProps={{}}
        />
      </GenericModal>
    </>
  );
}

export default Products;

import useFetch from '../../../hooks/useFetch';
import PageHeader from '../../../components/components/Header/PageHeader';
import styles from '../../../components/components/GenericPage/GenericPage.module.scss';
import React from 'react';
import { Card, Col, Row } from 'antd';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import { Chart } from 'primereact/chart';
import { calculatePercentageChange } from '../../../utils/Utils';

const CustomerStatistics = () => {
  const { data } = useFetch('/Statistics');

  /*
  const statistics = [
    {
      title: 'العملاء',
      num: data?.customersTotal,
      percent: '2% from the last month',
      imageUrl: '/images/down2.svg',
      chartImage: '/images/down2.svg',
      color: '#F9D8D433',
      txtColor: '#cf1322'
    },
    {
      title: 'العقود',
      num: data?.contractsTotal,
      percent: '2%',
      imageUrl: '/images/file3.svg',
      chartImage: '/images/up.svg',
      color: '#6D54DE33',
      txtColor: '#0AB28B'
    },
    {
      title: 'الشكاوي',
      num: data?.customersComplaintsTotal,
      percent: '2%',
      imageUrl: '/images/down2.svg',
      chartImage: '/images/down2.svg',
      color: '#FFEDD0',
      txtColor: '#cf1322'
    },
    {
      title: 'الأقساط المطلوبة',
      num: data?.installmentsPendingNumberTotal,
      percent: '2%',
      imageUrl: '/images/down2.svg',
      chartImage: '/images/down2.svg',
      color: '#C050E838',
      txtColor: '#cf1322'
    }
  ];
   */

  const statistics = data
    ? [
      createStatistic(
        'العملاء',
        data.customersTotal,
        data.customersCreatedThisMonth,
        data.customersCreatedLastMonth,
        '/images/user.svg',
        '#F9D8D433'
      ),
      createStatistic(
        'العقود',
        data.contractsTotal,
        data.contractsThisMonth,
        data.contractsLastMonth,
        '/images/report.svg',
        '#6D54DE33'
      ),
      createStatistic(
        'الشكاوي',
        data.customersComplaintsTotal,
        data.customersComplaintsThisMonth,
        data.customersComplaintsLastMonth,
        '/images/check.svg',
        '#FFEDD0'
      ),
      createStatistic(
        'الأقساط المطلوبة',
        data.installmentsPendingNumberTotal,
        data.installmentsPendingNumberTotalThisMonth,
        data.installmentsPendingNumberTotalLastMonth,
        '/images/coins.svg',
        '#C050E838'
      )
    ]
    : [];

  const donutData = {
    labels: ['نشط', 'غير نشط', 'في الانتظار'],
    datasets: [
      {
        data: [
          data?.customersActive,
          data?.customersInActive,
          data?.customersWaiting
        ],
        backgroundColor: ['#4CAF50', '#f44336', '#FFC107'],
        borderWidth: 0
      }
    ]
  };

  const resolvedComplaints = data
    ? data?.customersComplaintsTotal - data?.customersComplaintsThisMonth
    : 0;

  const circleData = {
    labels: ['تم حلها', 'معلقة'],
    datasets: [
      {
        data: [resolvedComplaints, data?.customersComplaintsThisMonth],
        backgroundColor: ['#4CAF50', '#f44336'],
        borderWidth: 0
      }
    ]
  };

  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        position: 'bottom',
        rtl: true,
        labels: {
          usePointStyle: true,
          font: {
            size: 14
          }
        }
      }
    }
  };

  return (
    <main className={styles.genericPage}>
      <PageHeader icon={''} buttons={[]} headerTitle={'العملاء'} />

      <Card style={{ width: 'auto', margin: '1.5rem', borderRadius: '1.5rem' }} className={'tableCard'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '10px',
            marginBottom: '15px',
            alignItems: 'center',
            borderBottom: '1px solid #EEE',
            justifyContent: 'space-between'
          }}
        >
          {<h2 style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#313131' }}>احصائيات العملاء</h2>}
        </div>

        <Row gutter={[16, 16]} justify="space-between">
          {statistics.map((stat, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <HeaderCardTS
                title={stat.title}
                num={stat.num}
                imageUrl={stat.imageUrl}
                color={stat.color}
                chartImage={stat.chartImage}
                percent={stat.percent}
                txtColor={stat.txtColor}
              />
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 24]} className="mt-8">
          <Col xs={24} md={12}>
            <Card title="الزيارات" className="h-full">
              <Chart type="doughnut" data={donutData} options={options} className={'md:w-30rem'}/>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card title="الشكاوى" className="h-full">
              <Chart type="doughnut" data={circleData} options={options} className={'md:w-30rem'}/>
            </Card>
          </Col>
        </Row>
      </Card>
    </main>
  );
};

function createStatistic(title: any, totalNum: any, thisMonthNum: number, lastMonthNum: number, imageUrl: any, color: any) {
  const changeData = calculatePercentageChange(thisMonthNum, lastMonthNum);
  const chartImage =
    changeData.change === null
      ? '/images/up.svg'
      : changeData.change >= 0
        ? '/images/up.svg'
        : '/images/down2.svg';
  const txtColor =
    changeData.change === null
      ? '#0AB28B'
      : changeData.change >= 0
        ? '#0AB28B'
        : '#cf1322';
  return {
    title,
    num: totalNum,
    percent: changeData.percent,
    imageUrl,
    chartImage,
    color,
    txtColor
  };
}

export default CustomerStatistics;



import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useGeoLocation from '../../../hooks/useGeoLocation';
import useStatus from '../../../hooks/useStatus';
import { Message } from 'primereact/message';
import { Card } from 'antd';
interface POSBasicInfoStepProps {
  isEditing: boolean;
}
const POSLocationStep = ({ isEditing }: POSBasicInfoStepProps) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();

  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const selectedArea = watch('areaId');

  const {
    countryApiState,
    governorateApiState,
    areaApiState,
    locationApiState,
    handleCountrySelect,
    handleGovernorateSelect,
    handleAreaSelect
  } = useGeoLocation(true);

  useEffect(() => {
    handleCountrySelect({ id: 1, name: 'مصر' });
  }, []);

  useEffect(() => {
    if (selectedGovernorate) handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
  }, [selectedGovernorate]);

  useEffect(() => {
    if (selectedArea) handleAreaSelect({ id: selectedArea, name: selectedArea });
  }, [selectedArea]);

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <Message
        severity='success'
        icon={null}
        content={<p className={'text-black-alpha-90 font-semibold'}>بيانات العنوان</p>}
        style={{
          width: '100%',
          backgroundColor: 'rgba(162, 251, 93, 0.2)'
        }}
      />
      <div className='flex w-full flex-row gap-4 pt-4'>
        {/* <CustomFormField
          name={'countryId'}
          label={'الدوله'}
          control={control}
          errors={errors}
          type={'dropdown'}
          options={countryApiState.state.data ?? []}
        /> */}
        <CustomFormField
          name={'governorateId'}
          label={'المحافظة'}
          control={control}
          errors={errors}
          type={'dropdown'}
          options={governorateApiState.state.data ?? []}
        />
        <CustomFormField
          name={'areaId'}
          label={'المدينه'}
          control={control}
          errors={errors}
          type={'dropdown'}
          options={areaApiState.state.data ?? []}
        />
        <CustomFormField
          name={'locationId'}
          label={'المنطقه'}
          control={control}
          errors={errors}
          type={'dropdown'}
          options={locationApiState.state.data ?? []}
        />
      </div>

      <div style={{ width: '100%', paddingTop: '20px' }}>
        <CustomFormField name={'address'} label={' العنوان'} control={control} errors={errors} type={'text'} />

        {/* <CustomFormField
          name={'addressRemarks'}
          label={'ملاحظات اخرى'}
          control={control}
          errors={errors}
          type={'textarea'}
          customStyle='pt-4'
        /> */}
      </div>
    </Card>
  );
};

export default POSLocationStep;

import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from '../../../global';
import { Card } from 'primereact/card';
import { useToast } from '../../../context/ToastContext';
import PageHeader from '../../../components/components/Header/PageHeader';
import { Button } from 'primereact/button';
import styles from '../AddEditInvoice/AddEditInvoice.module.scss';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { Message } from 'primereact/message';
import useFetch from '../../../hooks/useFetch';
import { useApiState } from '../../../hooks/useApiState';

const transactionSchema = yup.object({
  id: yup.number().optional().nullable(),
  cashBoxId: yup.number().required('الخزينة مطلوبة'),
  transTypeId: yup.number().required('نوع الحركة مطلوب'),
  destinationSourceId: yup.number().nullable().required('نوع المرسل /المرسل الية مطلوب'),
  invoiceIds: yup
    .array()
    .of(yup.number().integer())
    .when('destinationSourceId', {
      is: (value: any) => {
        return ![6, 7].includes(value);
      },
      then: (schema) => schema.required('الفاتورة مطلوبة').min(1, 'على الاقل اختر فاتورة واحدة'),
      otherwise: (schema) => schema.notRequired()
    }),

  myInvoiceIds: yup
    .array()
    .of(
      yup
        .string()
        .required('الفاتورة مطلوبة')
        .test('isValidFormat', 'تنسيق غير صحيح', (value) => {
          if (!value) return false;
          const [id, price] = value.split(',');
          return (
            Number.isInteger(Number(id)) && // Validate `id` is an integer
            !isNaN(Number(price)) // Validate `price` is a number
          );
        })
    )
    .when('destinationSourceId', {
      is: (value: any) => ![6, 7].includes(value), // Same condition as invoiceIds
      then: (schema) => schema.required('الفاتورة مطلوبة').min(1, 'على الاقل اختر فاتورة واحدة'),
      otherwise: (schema) => schema.notRequired()
    }),
  entityId: yup.number().required('المرسل/المستلم مطلوب'),
  amount: yup.number().required('المبلغ مطلوب').min(0.1, 'يجب ان يكون المبلغ اكبر من صفر'),
  Maxamount: yup.number().nullable(),
  reference: yup.string().required('الرقم المرجعى للحركة مطلوب'),
  remarks: yup.string().nullable()
});

type FormData = yup.InferType<typeof transactionSchema>;

const AddEditAccountTransaction = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceData, type } = location.state || {};
  const [isEditing, setIsEditing] = useState(false);
  const [entityData, setEntity] = useState([]);
  const [invoicetransformedOptions, setinvoicetransformedOptions] = useState([]);
  const [entityTypeData, setEntityTypeData] = useState([]);

  const methods = useForm<FormData>({
    resolver: yupResolver(transactionSchema),
    mode: 'all',
    defaultValues: {
      // destinationSourceId: null,
    }
  });

  const {
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    control,
    formState: { errors }
  } = methods;

  const transType = watch('transTypeId');
  const EntityType = watch('destinationSourceId');
  const EntityId = watch('entityId');
  const invoice = watch('myInvoiceIds');
  const myMaxamount = watch('Maxamount');
  const invoiceids = watch('invoiceIds');
  const cashBoxId = watch('cashBoxId');
  const { data: cashBoxData, isLoading: cashBoxIsLoading } = useFetch(`/CashBox/dropdown`);
  const { data: transTypeData, isLoading: isTransTypeLoading } = useFetch('/TransType/dropdown');
  const { data: supplierData, isLoading: isSupplierLoading } = useFetch('/Supplier/dropdown');
  const { data: customerData, isLoading: isCustomerLoading } = useFetch('/Customer/dropdown');
  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');
  const fetchEntityType = async () => {
    const response = await axiosInstance.get(`/AccountingTransaction/availableDestinationSourcesDropdown/${transType}`);
    setEntityTypeData(response.data.data);
  };
  useEffect(() => {
    if (transType) {
      fetchEntityType();
    }
  }, [transType]);

  const fetchInvoicesData = async () => {
    const response = await axiosInstance.get(
      `/Invoice/dropdown/byDestinationsSourceId/byEntityId/${EntityType}/${EntityId}`
    );

    return response.data;
  };
  const { lazyApiCall, state } = useApiState({ apiCall: fetchInvoicesData, isLazy: true });
  useEffect(() => {
    if (EntityId) {
      setValue('amount', 0);
      setinvoicetransformedOptions([]);
      lazyApiCall();
    }
  }, [EntityId]);

  useEffect(() => {
    if (type === 'pay' && invoiceData) {
      try {
        const formattedData = {
          transTypeId: 1,
          destinationSourceId: 2,
          myInvoiceIds: [`${invoiceData.id},${invoiceData.totalRemainingAmount}`],
          entityId: invoiceData.supplierId || invoiceData.supplierID
        };
        reset(formattedData);
      } catch (error) {}
    }
  }, [type, invoiceData, invoicetransformedOptions]);

  useEffect(() => {
    if (state?.data?.data) {
      const transformedOptions = state.data.data.map(
        (option: { invoiceNb: string; id: any; totalRemainingAmount: any }) => ({
          ...option,
          value: `${option.id},${option.totalRemainingAmount}`, // Combine id and price
          invoiceNb: option.invoiceNb || `Invoice #${option.id}` // Add invoice label for display
        })
      );
      setinvoicetransformedOptions(transformedOptions);
    } else {
      setinvoicetransformedOptions([]); // Clear options if no data is available
    }
  }, [state?.data?.data]);

  useEffect(() => {
    switch (EntityType) {
      case 2:
        setEntity(supplierData?.data);
        break;
      case 3:
        setEntity(customerData?.data);
        break;
      case 6:
        setEntity(employeeData?.data);
        break;
      case 7:
        const formattedCashboxDropdown = cashBoxData?.data.filter((item: { id: any }) => item.id !== cashBoxId);
        setEntity(formattedCashboxDropdown);
        break;
      default:
        break;
    }
  }, [EntityType]);

  const handleSelectionChange = (selectedValues: string[]) => {
    setValue('amount', 0);
    const selectedData = selectedValues.map((value) => {
      const [id, price] = value.split(',');
      return { id: Number(id), price: Number(price) };
    });

    const totalPrice = selectedData.reduce((sum, item) => sum + item.price, 0);
    const ids = selectedData.map((item) => item.id);

    setValue('invoiceIds', ids);
    setValue('amount', totalPrice);
    setValue('Maxamount', totalPrice);
  };

  useEffect(() => {
    if (invoice) {
      handleSelectionChange(invoice);
    }
  }, [invoice]);

  const handleSubmitOnClick = async (data: any) => {
    try {
      const formattedData = {
        cashBoxId: data.cashBoxId,
        transTypeId: data.transTypeId,
        invoiceIds: data.invoiceIds,
        destinationSourceId: data.destinationSourceId,
        entityId: data.entityId,
        amount: data.amount,
        reference: data.reference,
        remarks: data.remarks
      };
      isEditing
        ? await axiosInstance.put('/AccountingTransaction', formattedData)
        : await axiosInstance.post('/AccountingTransaction', formattedData);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} الحركة بنجاح`,
        life: 2000
      });
      navigate('/Accounting/dailyTransactions');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الحركة: ${error}`,
        life: 3000
      });
    }
  };

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة حركة جديدة'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <div style={{ display: 'flex', gap: '24px' }}>
              <div style={{ width: '100%', paddingTop: '20px' }}>
                <>
                  <Message
                    severity='success'
                    icon={null}
                    content={<p className={'text-black-alpha-90 font-semibold'}>نوع الدفعة</p>}
                    style={{
                      width: '100%',
                      backgroundColor: 'rgba(162, 251, 93, 0.2)'
                    }}
                  />
                  <CustomFormField
                    control={control}
                    customStyle={'pt-4'}
                    name={'transTypeId'}
                    label={'نوع الدفعة'}
                    errors={errors}
                    type={'dropdown'}
                    options={transTypeData?.data ?? []}
                    disabled={isTransTypeLoading || type}
                    required
                  />
                  <CustomFormField
                    control={control}
                    customStyle={'pt-4'}
                    name={'cashBoxId'}
                    label={'اختر الخزينة'}
                    errors={errors}
                    type={'dropdown'}
                    options={cashBoxData?.data ?? []}
                    disabled={cashBoxIsLoading}
                    required
                  />
                </>
                {transType ? (
                  transType === 1 ? (
                    <>
                      <Message
                        severity='success'
                        icon={null}
                        content={<p className={'text-black-alpha-90 font-semibold'}>بيانات مستلم الدفعة</p>}
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          backgroundColor: 'rgba(162, 251, 93, 0.2)'
                        }}
                      />
                      <CustomFormField
                        control={control}
                        customStyle={'pt-4'}
                        name={'destinationSourceId'}
                        label={'الدفعة الى'}
                        errors={errors}
                        type={'dropdown'}
                        options={entityTypeData ?? []}
                        disabled={type}
                        required
                      />
                      <CustomFormField
                        control={control}
                        customStyle={'pt-4'}
                        name={'entityId'}
                        label={'مستلم الدفعة'}
                        errors={errors}
                        type={'dropdown'}
                        options={type === 'pay' ? supplierData?.data : entityData ?? []}
                        disabled={!EntityType || type}
                        required
                      />
                    </>
                  ) : (
                    <>
                      <Message
                        severity='success'
                        icon={null}
                        content={<p className={'text-black-alpha-90 font-semibold'}>بيانات مرسل الدفعة</p>}
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          backgroundColor: 'rgba(162, 251, 93, 0.2)'
                        }}
                      />
                      <CustomFormField
                        control={control}
                        customStyle={'pt-4'}
                        name={'destinationSourceId'}
                        label={'الدفعة من'}
                        errors={errors}
                        type={'dropdown'}
                        options={entityTypeData ?? []}
                        required
                      />
                      <CustomFormField
                        control={control}
                        customStyle={'pt-4'}
                        name={'entityId'}
                        label={'مرسل الدفعة'}
                        errors={errors}
                        type={'dropdown'}
                        options={entityData ?? []}
                        disabled={!EntityType}
                        required
                      />
                    </>
                  )
                ) : null}
              </div>

              {/* Left side */}
              <div style={{ width: '100%', paddingTop: '20px' }}>
                <Message
                  severity='success'
                  icon={null}
                  content={<p className={'text-black-alpha-90 font-semibold '}>المبلغ/الفاتورة</p>}
                  style={{
                    width: '100%',
                    backgroundColor: 'rgba(162, 251, 93, 0.2)'
                  }}
                />
                <CustomFormField
                  key={JSON.stringify(invoicetransformedOptions)} // Re-renders when options change
                  control={control}
                  customStyle='pt-4'
                  name='myInvoiceIds'
                  label='اختر الفاتورة/ الفواتير'
                  errors={errors}
                  type='multi-select'
                  options={invoicetransformedOptions}
                  optionValue='value'
                  optionLabel='invoiceNb'
                  disabled={!invoicetransformedOptions.length || type} // Disable if no options
                  required
                />
                <CustomFormField
                  control={control}
                  customStyle={'pt-4'}
                  name={'amount'}
                  label={'المبلغ'}
                  errors={errors}
                  type={'decimal'}
                  max={myMaxamount ?? undefined}
                  disabled={invoiceids ? invoiceids.length > 1 : false}
                />
                <Message
                  severity='success'
                  icon={null}
                  content={<p className={'text-black-alpha-90 font-semibold '}>اخرى</p>}
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    backgroundColor: 'rgba(162, 251, 93, 0.2)'
                  }}
                />
                <CustomFormField
                  control={control}
                  name={'reference'}
                  customStyle={'pt-4'}
                  label={'الرقم المرجعي'}
                  errors={errors}
                  type={'text'}
                  required
                />
                <CustomFormField
                  control={control}
                  customStyle={'pt-4'}
                  name={'remarks'}
                  label={'ملاحظات اخرى'}
                  errors={errors}
                  type={'textarea'}
                />
              </div>
            </div>
          </Card>
          <div className={`${styles.buttonContainer} mb-5`}>
            <Button className='btn__nextbtn' type='submit'>
              {isEditing ? 'حفظ التعديلات' : 'إضافة الحركة'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditAccountTransaction;

import PageHeader from '../../../components/components/Header/PageHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, InputNumber, Modal, Row, Select, Space } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from '../../../hooks/useFetch';
import * as yup from 'yup';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import GenericEditableDataTable from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import { useToast } from '../../../context/ToastContext';
import styles from './RecieveCovenant.module.scss';
import CustomButton from '../../../components/components/Button/CustomButton';

const covenantSchema = yup.object({
  employeeId: yup.number().required('اسم المندوب مطلوب'),
  warehouseId: yup.number().required('اسم المخزن مطلوب'),
  items: yup.array().required('المنتجات مطلوبة').default([])
});

interface ProductRow {
  productId: number | null;
  count: number | null;
  quantity: number | null;
  productName: string | null;
  warehouseLocationId: string | null;
}

const RecieveCovenant = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const methods = useForm({
    resolver: yupResolver(covenantSchema),
    mode: 'all',
    defaultValues: {}
  });

  const {
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = methods;
  const [processedData, setProcessedData] = useState<ProductRow[]>([]);
  const { data: warehouseData, isLoading: isWarehouseLoading } = useFetch('/Warehouse/dropdown');
  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');

  const { data: warehouseLocationData, isLoading: isWarehouseLocationLoading } =
    useFetch('/WarehousesLocation/dropdown');

  const fetchCustody = () => {
    return axiosInstance.get(`/Covenant/repProduct/list/byEmployeeId/${watch('employeeId')}`);
  };
  // const fetchWarehouse = () => {
  //   return axiosInstance.get(`/Covenant/availableWarehousesLocationsWithProducts/${watch('warehouseId')}`);
  // };

  const { lazyApiCall: customDyApiCall, state: custodyState } = useApiState({ apiCall: fetchCustody, isLazy: true });
  // const { lazyApiCall: warehouseApiCall, state: warehouseState } = useApiState({
  //   apiCall: fetchWarehouse,
  //   isLazy: true
  // });

  // useEffect(() => {
  //   debugger;
  //   if (watch('warehouseId')) {
  //     warehouseApiCall();
  //   }
  // }, [watch('warehouseId')]);

  const handleSearchOnClick = async () => {
    const isValid = await trigger(['employeeId', 'warehouseId']);
    if (isValid) {
      try {
        await customDyApiCall();
      } catch (error) {
        toast.current.show({
          type: 'error',
          summary: 'خطأ في التحقق',
          detail: 'حدث خطأ أثناء البحث، يرجى المحاولة مرة أخرى',
          life: 5000
        });
      }
    } else {
      toast.current.show({
        type: 'error',
        summary: 'خطأ في التحقق',
        detail: 'يرجى التأكد من إدخال جميع الحقول المطلوبة',
        life: 5000
      });
    }
  };

  const memoizedData = useMemo(
    () =>
      (custodyState?.data?.data?.data ?? []).map(
        (
          item: {
            count: number | null;
          },
          index: any
        ) => ({
          ...item,
          id: index,
          count: item.count
        })
      ),
    [custodyState?.data?.data?.data]
  );

  useEffect(() => {
    setProcessedData(memoizedData);
  }, [memoizedData]);

  const handleProductRowUpdate = (updatedData: ProductRow[]) => {
    const updatedRows = updatedData.map((row) => {
      const options = custodyState?.data?.data?.data ?? [];
      const selectedItem = options.find((option: any) => option.productId === row.productId);
      const MaxstockQty = selectedItem ? selectedItem.count : 0;
      let recivedCount = row.quantity || 0;

      if (recivedCount > MaxstockQty) {
        recivedCount = MaxstockQty;
        toast.current.show({
          type: 'error',
          summary: 'خطأ في البيانات',
          detail: 'لا يمكن اضافة كمية اكبر من الكمية الحالية مع المندوب',
          life: 5000
        });
      }

      return {
        ...row,
        quantity: recivedCount
      };
    });

    setProcessedData(updatedRows);
    setValue('items', updatedRows);
  };

  const handleSubmitOnClick = async () => {
    try {
      const productTableData = (watch('items') || processedData)?.filter(
        (product) => product.availableQuantity !== 'غير متاح'
      );

      const productErrors =
        productTableData !== null && productTableData.length === 0
          ? []
          : productTableData
              ?.map((product, index) => {
                const errors: string[] = [];
                if (!product.productId) errors.push(`المنتج مطلوب`);
                if (product.quantity == null || product.quantity < 1)
                  errors.push(`${index + 1}: الكمية مطلوبة ويجب أن تكون على الأقل 1`);
                if (!product.warehouseLocationId) errors.push(`قسم المخزن مطلوب`);
                return errors;
              })
              .flat();

      if (productErrors.length > 0) {
        productErrors.forEach((error) => {
          toast.current.show({
            type: 'error',
            summary: 'خطأ في التحقق',
            detail: error,
            life: 3000
          });
        });
        return;
      }
      const CovenantParamDto = {
        repId: watch('employeeId'),
        warehouseId: watch('warehouseId'),
        items: productTableData.map((products: any) => ({
          warehouseLocationId: products.warehouseLocationId ?? 0,
          productId: products.productId ?? 0,
          quantity: products.quantity ?? 0
        }))
      };
      await axiosInstance.post('/Covenant/repWarehouse', CovenantParamDto);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: 'تم ارسال الطلب بنجاح',
        life: 2000
      });
      navigate('/warehouses/transactions/');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: 'فشلت عملية إستلام العهدة',
        life: 3000
      });
    }
  };

  return (
    <>
      <PageHeader
        icon={'/images/back-arrow.svg'}
        buttons={[]}
        headerTitle={'إستلام العهدة من المندوب'}
        iconOnClick={() => navigate(-1)}
      />
      <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
        <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
          <Row align={'middle'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10}>
              <CustomFormField
                control={control}
                name={'employeeId'}
                label={'اسم المندوب'}
                errors={errors}
                type={'dropdown'}
                loading={isEmployeeLoading}
                options={employeeData?.data ?? []}
              />
            </Col>
            <Col span={10}>
              <CustomFormField
                control={control}
                name={'warehouseId'}
                label={'المخزن'}
                errors={errors}
                type={'dropdown'}
                loading={isWarehouseLoading}
                options={warehouseData?.data ?? []}
              />
            </Col>
            <Col span={4}>
              <Button
                htmlType='button'
                onClick={handleSearchOnClick}
                className={styles.button}
                style={{ width: '100%' }}
              >
                بحث
              </Button>
            </Col>
          </Row>
        </Card>
      </form>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
          <Alert
            message='المنتجات'
            type='success'
            style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold' }}
          />
          <GenericEditableDataTable
            data={processedData ?? []}
            columns={[
              {
                field: 'productName',
                header: 'المنتج',
                editable: false,
                type: 'text'
              },
              {
                field: 'warehouseLocationId',
                header: 'موقع التخزين',
                editable: true,
                type: 'dropdown',
                options: warehouseLocationData?.data ?? [],
                idField: 'id',
                labelField: 'name'
              },
              {
                field: 'count',
                header: 'الكميه المتاحه',
                editable: false,
                type: 'text'
              },
              {
                field: 'quantity',
                header: 'الكميه المطلوبه',
                editable: true,
                type: 'number'
              }
            ]}
            isLoading={false}
            onRowUpdate={handleProductRowUpdate}
          />
        </Space>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1.5rem' }}>
          <Button className={styles.Submitbutton} htmlType='button' onClick={handleSubmitOnClick}>
            استلام العهدة من المندوب
          </Button>
        </div>
      </Card>
    </>
  );
};

export default RecieveCovenant;

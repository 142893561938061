// Sidebar.tsx
import React, { memo, useEffect, useMemo } from 'react';
import { Grid, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../redux/user/userActions';

interface SidebarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const { useBreakpoint } = Grid;

const Sidebar: React.FC<SidebarProps> = memo(({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const screens = useBreakpoint();

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const items = useMemo(
    () =>
      user?.menus?.map((menu: { label: string; icon: string; items: any[] }) => ({
        key: menu.label,
        icon: <img src={`${process.env.PUBLIC_URL}/images/${menu.icon}`} alt={menu.label} />,
        label: menu.label,
        children: menu.items.map((item) => ({
          key: item.label,
          label: (
            <a
              href={item.link}
              onClick={(e) => {
                e.preventDefault();
                navigate(item.link);
                if (!screens.lg) {
                  setCollapsed(true);
                }
              }}
            >
              {item.label}
            </a>
          )
          // onClick: () => {
          //   navigate(item.link);
          //   // Collapse the sidebar on mobile
          //   if (!screens.lg) {
          //     setCollapsed(true);
          //   }
          // },
        }))
      })) || [],
    [user?.menus, navigate, screens.lg, setCollapsed]
  );

  return (
    <Menu
      mode="inline"
      theme="light"
      inlineCollapsed={collapsed}
      items={items}
      style={{ height: '100%', borderRight: 0 }}
    />
  );
});

export default Sidebar;

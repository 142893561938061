// Navbar.tsx
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Layout, Menu, Row, Space, Typography } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { logout } from '../../redux/user/userActions';
import './Navbar.scss';
import { AsyncImage } from 'loadable-image';
import Paragraph from 'antd/lib/typography/Paragraph';

function Navbar({ user, logout, collapsed, setCollapsed }: any) {
  const navigate = useNavigate();

  const menuItems = (
    <Menu>
      <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() => navigate('/admin/systemParams')}>
        الاعدادات
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        تسجيل خروج
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header
      style={{
        direction: 'rtl',
        display: 'inline-flex',
        backgroundColor: '#fff',
        padding: '0 1em',
        justifyContent: 'space-between',
        background: 'linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 70, 106, 1) 0%, rgba(0, 35, 30, 1) 100%)'

      }}>
      <Row justify="space-between" align="middle">
        {/* Logo and Toggle Button */}
        <Col>
          <Row align="middle">
            <Link to="/">
              <div className="nav__logo">
                <img src={'/images/logo.svg'} alt="Best Aqua Solution" />
              </div>
            </Link>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined style={{ color: '#fff' }} /> :
                <MenuFoldOutlined style={{ color: '#fff' }} />}
              onClick={() => setCollapsed(!collapsed)}
            />
          </Row>
        </Col>

        {/*/!* User Info and Actions *!/*/}
      </Row>
      <Col>
        <Row align="middle">
          <Col>
            <Row justify={'space-between'} align="middle"
                 style={{
                   backgroundColor: 'rgba(255, 255, 255, 0.19)',
                   minWidth: '200px',
                   borderRadius: '50px',
                   height: '41px'
                 }}>
              <Row align="middle">
                <AsyncImage
                  src={`http://bestaqua.oneclickapps.org/${user.imagePath}`}
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                  loader={<div style={{ background: '#888', width: '35px', height: '35px', borderRadius: '50%' }} />}
                  error={<img src={'/images/employee2.png'} />}
                />

                <Col style={{ width: '8px' }} />
                <Space direction="vertical" size={0} align="start">
                  <Paragraph
                    style={{
                      margin: 0,
                      lineHeight: '160%',
                      fontFamily: 'IBM Plex Sans Arabic',
                      color: '#fff'
                    }}
                  >
                    مرحباً،
                  </Paragraph>
                  <Paragraph
                    style={{
                      margin: 0,
                      fontFamily: 'IBM Plex Sans Arabic',
                      color: '#fff'
                    }}
                  >
                    {user.firstName} {user.lastName}
                  </Paragraph>
                </Space>
              </Row>
              <Button shape={'circle'} size={'large'} type="text"
                      icon={<NotificationOutlined style={{ color: '#fff' }} />}
              />

            </Row>
          </Col>
          <Col>
            <Button shape={'circle'} size={'large'} type="text" icon={<LogoutOutlined style={{ color: '#fff' }} />}
                    onClick={logout} />
          </Col>
        </Row>
      </Col>
    </Layout.Header>
  );
}

const mapStateToProps = (state: { user: { user: any; }; }) => ({
  user: state.user.user
});

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const token = useSelector((state) => state.user.token);
  return token ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;


// function ProtectedRoute({ children }) {
//   const token = useSelector((state) => state.user.token);
//   const menus = useSelector((state) => state.user.user?.menus || []);
//   const location = useLocation();
//
//   const accessiblePaths = menus.flatMap((menu) =>
//     menu.items.map((item) => item.link)
//   );
//
//   const isAuthorized = accessiblePaths.includes(location.pathname);
//
//   if (!token) {
//     return <Navigate to="/login" />;
//   }

//   if (!isAuthorized) {
//     return <Navigate to="/unauthorized" />;
//   }
//
//   return children;
//
// }
//
// export default ProtectedRoute;

import React, { useEffect, useRef, useState } from 'react';
// import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useToast } from '../../../../context/ToastContext';
import { axiosInstance } from '../../../../global';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useFetch from '../../../../hooks/useFetch';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Drawer, Button } from 'antd';

const custodySchema = yup.object({
  id: yup.number().optional().nullable(),
  selectedCashBoxId: yup.number().nullable(),
  cashBoxId: yup.number().nullable(),
  invoiceIds: yup.array().of(yup.number().integer()).nullable()
});

interface dailyAssProps {
  visible: boolean;
  setVisible: any;
  invoicesData: number[];
  cashBoxId: number;
}
function AddCustodyForm({ visible, setVisible, invoicesData, cashBoxId }: dailyAssProps) {
  const toast = useToast();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(custodySchema)
  });

  useEffect(() => {
    if (invoicesData) {
      setValue('invoiceIds', invoicesData);
    }
  }, [invoicesData]);

  const { data: CashBoxData, isLoading: CashBoxIsLoading } = useFetch(`/CashBox/dropdown`);

  function acceptClose() {
    reset();
    setVisible(false);
  }

  const handleClose = () => {
    confirmDialog({
      header: 'هل تريد الغاء عملية تسوية العهدة ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: () => {},
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      position: 'left',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: '4'
    });
  };

  function handleAddCustody(item: any) {
    debugger;
    const myInvoices = watch('invoiceIds');
    const formattedData = {
      selectedCashBoxId: item.selectedCashBoxId,
      repCashBoxId: cashBoxId,
      invoiceIds: myInvoices
    };
    axiosInstance
      .post(`/AccountingTransaction/financeCovenant`, formattedData)
      .then(() => {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'تم تسوية العهدة بنجاح',
          life: 3000
        });
        reset();
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء  تسوية العهدة', life: 3000 });
      });
  }

  return (
    <>
      <ConfirmDialog tagKey={'4'} />
      <Drawer placement={'left'} title={'تسوية العهدة'} open={visible} onClose={handleClose}>
        <form onSubmit={handleSubmit(handleAddCustody)}>
          <div className='flex flex-column gap-5'>
            <CustomFormField
              name={'selectedCashBoxId'}
              label={'اختر الخزينة'}
              control={control}
              errors={errors}
              type={'dropdown'}
              options={CashBoxData?.data ?? []}
              required={true}
              disabled={CashBoxIsLoading}
            />

            <div className='flex justify-content-end'>
              <Button onClick={handleClose} htmlType={'button'} variant={'text'} size='large'>
                إلغاء
              </Button>
              <Button size='large' htmlType={'submit'} variant={'solid'} className='modals__btn'>
                تسوية
              </Button>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
}

export default AddCustodyForm;

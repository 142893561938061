import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Typography } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { login, requestPasswordReset } from '../../redux/user/userActions';
import { useNavigate } from 'react-router-dom';
const { Title } = Typography;

interface LoginProps {
  login: (credentials: { email: string; password: string }) => void;
  loading: boolean;
  error: string | null;
}

const Login: React.FC<LoginProps> = ({ login, loading, error }) => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const token = useSelector((state: any) => state.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      message.error('هناك خطأ في البريد الإلكتروني، رقم الهاتف، أو اسم المستخدم أو كلمة السر');
    }
  }, [error]);

  const handleLogin = (values: { email: string; password: string }) => {
    login(values);
  };

  const handleResetRequest = (values: { email: string }) => {
    requestPasswordReset(values.email);
  };

  const formWrapperStyle = { maxWidth: '500px', width: '100%', direction: 'rtl' } as const;

  return (
    <Row style={{ height: '100vh', direction: 'ltr' }}>
      {/* Left Section */}
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        style={{
          background: 'linear-gradient(176deg, #00466a 0%, #00231e 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src="/images/logo.svg"
          alt="Best Aqua Solution"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Col>

      {/* Right Section */}
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        }}
      >
        <Form
          onFinish={isLoginForm ? handleLogin : handleResetRequest}
          style={formWrapperStyle}
        >
          <Title level={4}>مرحباً مجدداً،</Title>
          <Title level={2}>{isLoginForm ? 'تسجيل الدخول' : 'إعادة تعيين كلمة المرور'}</Title>


          {isLoginForm ? (
            <>
              <Form.Item
                name="userName"
                rules={[
                  { required: true, message: 'يرجى إدخال البريد الإلكتروني، رقم الهاتف، أو اسم المستخدم' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="البريد الإلكتروني، رقم الهاتف، أو اسم المستخدم"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'برجاء ادخال كلمة المرور' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="كلمة السر"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'يرجى إدخال البريد الإلكتروني' },
                { type: 'email', message: 'يرجى إدخال بريد إلكتروني صالح' },
              ]}
            >
              <Input
                size="large"
                placeholder="البريد الالكتروني"
                prefix={<MailOutlined />}
              />
            </Form.Item>
          )}

          {loading ? (
            <Spin style={{ display: 'block', margin: '1rem auto' }} />
          ) : (
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '100%',
                  color: '#000',
                  background: 'rgba(162, 251, 93, 1)',
                  borderColor: 'rgba(17, 17, 17, 1)',
                }}
              >
                {isLoginForm ? 'سجل الدخول' : 'إرسال طلب إعادة تعيين'}
              </Button>
            </Form.Item>
          )}

          <div style={{ textAlign: 'center' }}>
            {isLoginForm ? (
              <>
                تواجه مشكلة؟{' '}
                <a onClick={() => setIsLoginForm(false)}>طلب تغيير كلمة السر</a>
              </>
            ) : (
              <a onClick={() => setIsLoginForm(true)}>العودة لتسجيل الدخول</a>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = {
  login,
  requestPasswordReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

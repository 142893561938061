import React from 'react';
import styles from '../AddEditSalesOrder.module.scss';
import moment from 'moment';

interface SalesOrderDetailsCardProps {
  code: string;
  date: Date;
  InvoiceReqAmount: number;
  InvoiceTotalAmount: number;
  totalDiscountAmount: number;
}

const SalesOrderDetailsCard: React.FC<SalesOrderDetailsCardProps> = ({
  code,
  date,
  InvoiceReqAmount,
  InvoiceTotalAmount,
  totalDiscountAmount
}) => {
  return (
    <div className={styles.InvoiceDetailsCard}>
      <div className={styles.InvoiceDetailsCardSections}>
        <span>
          {`الرقم المرجعى :`} <span style={{ color: 'black' }}>{`${code || 'غير موجود'}`}</span>
        </span>
        <span>
          {`التاريخ :`}
          <span style={{ color: 'black' }}>{`${
            moment(date).locale('en-GB').format('YYYY-MM-DD') || 'غير موجود'
          }`}</span>
        </span>
        <span>
          {`العملة :`}
          <span style={{ color: 'black' }}>{'جنية مصرى'}</span>
        </span>
      </div>
      <div className={styles.InvoiceDetailsCardSections}>
        <span>
          {`القيمة الاجمالية للفاتورة :`}
          <span style={{ color: 'black' }}>{`${InvoiceTotalAmount || 0} ج.م`}</span>
        </span>
        <span>
          {`القيمة الاجمالية للخصم :`}
          <span style={{ color: 'black' }}>{`${totalDiscountAmount || 0} ج.م`}</span>
        </span>
        <span>
          {`اجمالي المطلوب دفعه :`}
          <span style={{ color: 'black' }}>{`${InvoiceReqAmount || 0} ج.م`}</span>
        </span>
      </div>
    </div>
  );
};

export default SalesOrderDetailsCard;

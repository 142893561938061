import {
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  CHANGE_EMPLOYEE_STATE,
  CHANGE_EMPLOYEE_BACKUP_STATE,
  CLEAR_EMPLOYEE_STATE
} from './employeeTypes';

const initialState = {
  loading: false,
  employee: {
    email: '',
    password: '',
    // code: '',
    name: '',
    nationalNb: '',
    phoneNumber: '',
    genderId: '',
    hiringDate: new Date(),
    departmentId: '',
    teamIds: [],
    positionId: '',
    directManagerId: '',
    attendanceTypeId: '',
    statusId: '',
    remarks: '',
    vacations: [],
    locationId: '',
    address: '',
    payrollList: []
  },
  employeeBackup: {
    email: '',
    password: '',
    code: '',
    name: '',
    nationalNb: '',
    phoneNumber: '',
    genderId: '',
    hiringDate: new Date(),
    departmentId: '',
    teamIds: [],
    positionId: '',
    directManagerId: '',
    attendanceTypeId: '',
    statusId: '',
    remarks: '',
    vacations: [],
    locationId: '',
    address: '',
    payrollList: []
  },
  error: ''
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_REQUEST:
    case ADD_EMPLOYEE_REQUEST:
    case EDIT_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: ''
      };
    case ADD_EMPLOYEE_SUCCESS:
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case FETCH_EMPLOYEE_FAILURE:
    case ADD_EMPLOYEE_FAILURE:
    case EDIT_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CHANGE_EMPLOYEE_STATE:
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action.payload
        }
      };
    case CHANGE_EMPLOYEE_BACKUP_STATE:
      return {
        ...state,
        employeeBackup: {
          ...state.employeeBackup,
          ...action.payload
        }
      };
    case CLEAR_EMPLOYEE_STATE:
      return {
        ...state,
        employee: initialState.employee,
        employeeBackup: initialState.employeeBackup
      };
    default:
      return state;
  }
};

export default employeeReducer;

import React, { useEffect, useRef, useState } from 'react';
// import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useToast } from '../../../../context/ToastContext';
import { axiosInstance } from '../../../../global';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useFetch from '../../../../hooks/useFetch';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Drawer, Button } from 'antd';

const transactionSchema = yup.object({
  id: yup.number().optional().nullable(),
  locationId: yup.array().of(yup.number().integer()).required('المنطقة مطلوبة').min(1, 'على الاقل اختر منطقة واحدة'),
  employeeId: yup.number().required('الموظف مطلوب'),
  date: yup.date().required('التاريخ مطلوب'),
  remarks: yup.string().nullable()
});

interface dailyAssProps {
  visible: boolean;
  setVisible: any;
}
function AddEmployeeDailyAssignment({ visible, setVisible }: dailyAssProps) {
  const toast = useToast();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(transactionSchema)
  });

  const { data: EmployeeData, isLoading: EmployeeIsLoading } = useFetch(`/Employee/dropdown`);
  const { data: LocationData, isLoading: LocationIsLoading } = useFetch(`/Location/dropdown`);


  function acceptClose() {
    reset();
    setVisible(false);
  }

  const handleClose = () => {
    confirmDialog({
      header: 'هل تريد الغاء عملية اضافة جدول اعمال للفني ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: () => {},
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      position: 'left',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: '4'
    });
  };

  function handleAddDailyAssignment(item: any) {
    axiosInstance
      .post(`/EmployeesDailyLocationsAssignment`, item)
      .then(() => {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'تم إضافة جدول اعمال',
          life: 3000
        });
        reset();
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء إضافة جدول اعمال', life: 3000 });
      });
  }

  return (
    <>
      <ConfirmDialog tagKey={'4'} />
      <Drawer placement={'left'} title={'إضافة مواقع للفني'} open={visible} onClose={handleClose}>
        <form onSubmit={handleSubmit(handleAddDailyAssignment)}>
          <div className='flex flex-column gap-5'>
            <CustomFormField
              name={'employeeId'}
              label={'بحث عن الموظف ب رقم الهاتف / الرقم القومي / الاسم / ايميل '}
              control={control}
              errors={errors}
              type={'dropdown'}
              options={EmployeeData?.data ?? []}
              required={true}
              disabled={EmployeeIsLoading}
            />

            <CustomFormField
              name={'locationId'}
              label={'اختر المواقع للفني'}
              control={control}
              errors={errors}
              optionValue='id'
              optionLabel='name'
              type={'multi-select'}
              options={LocationData?.data ?? []}
              required={true}
              disabled={LocationIsLoading}
            />

            <CustomFormField
              control={control}
              name={'date'}
              customStyle={'pt-4'}
              label={'سيقوم الموظف بالعمل على هذه المواقع في يوم؟'}
              errors={errors}
              type={'calendar'}
              required
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'remarks'}
              label={'ملاحظات اخرى'}
              errors={errors}
              type={'textarea'}
            />

            <div className='flex justify-content-end'>
              <Button onClick={handleClose} htmlType={'button'} variant={'text'} size='large'>
                إلغاء
              </Button>
              <Button size='large' htmlType={'submit'} variant={'solid'} className='modals__btn'>
                اضافة الى المجموعة
              </Button>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
}

export default AddEmployeeDailyAssignment;

import React from 'react';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const SalesOrderReveiwStep = () => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();
  const customer = watch('customer');
  const InvoiceCode = watch('reference');
  const totalAmount = watch('totalAmount');
  const paidAmount = watch('paidAmount');
  const totalDiscount = watch('mytotalDiscount');
  const reqAmount = watch('requstedAmount');
  const remainigAmount = reqAmount - paidAmount || 0;
  const remarks = watch('remarks');
  const items = watch('items');

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <DetailsSheetGrid
        headers={[
          'الرقم المرجعى',
          'تاريخ الفاتورة',
          'اسم العميل',
          'العملة',
          'إجمالى الفاتورة',
          'الخصم',
          'المبلغ المطلوب',
          'المبلغ المدفوع',
          'المتبقى',
          'ملاحظات'
        ]}
        contents={[
          InvoiceCode,
          moment(new Date()).locale('en-GB').format('YYYY-MM-DD'),
          <div className='flex flex-row gap-5'>
            {customer?.fullName}
            <Link to={`/customers/details/${customer?.id}`} className='primary__data--link text-blue-600'>
              عرض بيانات العميل{` > `}
            </Link>
          </div>,
          'جنية مصرى',
          totalAmount,
          totalDiscount,
          reqAmount,
          paidAmount,
          remainigAmount,
          remarks
        ]}
        title={'بيانات الفاتورة'}
      />

      <div className='tbl mt-5'>
        <DataTable value={items} className='tbl__table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
          <Column field='productName' header='اسم المنتج'></Column>
          <Column field='price' header='سعر المنتج'></Column>
          <Column field='qty' header='الكمية'></Column>
          <Column field='totalPrice' header='السعر الاجمالى'></Column>
        </DataTable>
      </div>
    </Card>
  );
};

export default SalesOrderReveiwStep;

import React, { useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import { Message } from 'primereact/message';
import useActivityHierarchy from '../../../../hooks/useActivityHierarchy';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';

const ActivityCategoryForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const activityType = watch('typeId');
  const activityFamily = watch('familyId');
  const activityCategory = watch('categoryId');

  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('General');

  const {
    activityTypeApiState,
    activityFamilyApiState,
    activityCategoryApiState,
    handleActivityTypeSelect,
    handleActivityFamilySelect,
    handleActivityCategorySelect
  } = useActivityHierarchy();

  useEffect(() => {
    if (activityType) {
      handleActivityTypeSelect({ id: activityType, name: activityType });
    }
  }, [activityType]);

  useEffect(() => {
    if (activityFamily) {
      handleActivityFamilySelect({ id: activityFamily, name: activityFamily });
    }
  }, [activityFamily]);

  useEffect(() => {
    if (activityCategory) {
      handleActivityCategorySelect({ id: activityCategory, name: activityCategory });
    }
  }, [activityCategory]);
  return (
    <>
      <Message
        severity='info'
        text={'يجب اختيار نوع الاتصال والتصنيف و الفئة اولاً حتى يكون بأمكانك اضافة فئة الاتصالات داخلها'}
        className='text-black-alpha-90 font-semibold'
      />

      <CustomFormField
        name={'typeId'}
        label={'اختر نوع الاتصال'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={activityTypeApiState.state?.data ?? []}
      />

      <CustomFormField
        name={'familyId'}
        label={'اختر تصنيف الاتصال'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={activityFamilyApiState.state?.data ?? []}
      />

      <CustomFormField
        name={'categoryId'}
        label={'اختر فئة الاتصال'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={activityCategoryApiState.state?.data ?? []}
      />

      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم  الاتصال` : `اضف اسم/اسماء  الاتصال الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        placeholder={isEditMode ? `اسم  الاتصال` : `اضف اسم/اسماء  الاتصال الجديدة`}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة  الاتصال`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default ActivityCategoryForm;

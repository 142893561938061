import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useNavigate, useParams } from 'react-router-dom';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { axiosInstance } from '../../../global';
import moment from 'moment/moment';
import { useToast } from '../../../context/ToastContext';

interface ProductDetail {
  productID: number;
  productName: string;
  price: number;
  commission: number;
  countryID: number;
  governorateID: number;
  statusID: number;
  date: string;
  remarks: string;
}

interface DropdownOption {
  id: number;
  name: string;
}

function ProductListDetailsTableTs() {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    selectedCountry: null as number | null,
    selectedGovernorate: null as number | null,
    selectedStatus: null as number | null,
    date: null as Date | null,
    remarks: '',
    products: [] as ProductDetail[]
  });
  const [dropdownData, setDropdownData] = useState({
    countries: [] as DropdownOption[],
    governorates: [] as DropdownOption[],
    statuses: [] as DropdownOption[]
  });
  const [searchTerm, setSearchTerm] = useState('');
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesResponse, statusesResponse, productResponse] = await Promise.all([
          axiosInstance.get('/Country/dropdown'),
          axiosInstance.get('/Status/dropdown/Product'),
          axiosInstance.get(`/ProductsPricesListDetail/list/${params.id}`)
        ]);

        const productData = productResponse.data.data || [];
        const firstProduct = productData[0] || {};

        setDropdownData({
          countries: countriesResponse.data.data,
          statuses: statusesResponse.data.data,
          governorates: firstProduct.countryID ? await fetchGovernorates(firstProduct.countryID) : []
        });

        setFormData({
          selectedCountry: firstProduct.countryID || null,
          selectedGovernorate: firstProduct.governorateID || null,
          selectedStatus: firstProduct.statusID || null,
          date: firstProduct.date ? new Date(firstProduct.date) : null,
          remarks: firstProduct.remarks || '',
          products: productData
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);

  const fetchGovernorates = async (countryID: number) => {
    const response = await axiosInstance.get(`/Governorate/allByCountryIdDropdown/${countryID}`);
    return response.data.data;
  };

  const handleChange = (key: keyof typeof formData, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveChanges = async () => {
    try {
      await axiosInstance.post('/ProductsPricesListDetail/post', {
        governorateId: formData.selectedGovernorate,
        date: moment(new Date(formData.date ?? '')).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        remarks: formData.remarks,
        statusId: formData.selectedStatus,
        details: formData.products.map(({ productID, price, commission }) => ({
          productId: productID,
          price,
          commission
        }))
      });
      toast.current?.show({ severity: 'success', summary: 'تم الحفظ بنجاح!', life: 3000 });
      navigate('/product/priceList');
    } catch (error) {
      toast.current?.show({ severity: 'error', summary: 'حدث خطأ أثناء الحفظ', life: 3000 });
    }
  };

  const handleSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = formData.products.filter((product) =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const onEditorValueChange = (props: any, field: keyof ProductDetail, value: any) => {
    const updatedProducts = [...formData.products];
    updatedProducts[props.rowIndex] = {
      ...updatedProducts[props.rowIndex],
      [field]: value
    };
    handleChange('products', updatedProducts);
  };

  const inputNumberEditor = (props: ColumnEditorOptions, field: keyof ProductDetail) => {
    return (
      <InputNumber
        value={props.rowData[field]}
        onValueChange={(e) => onEditorValueChange(props, field, e.value)}
        minFractionDigits={2}
        mode="decimal"
        useGrouping={false}
      />
    );
  };

  const priceEditor = (props: ColumnEditorOptions) => inputNumberEditor(props, 'price');
  const commissionEditor = (props: ColumnEditorOptions) => inputNumberEditor(props, 'commission');

  return (
    <div className="tbl ml-2 mb-4">

      <div className="tbl__header">
        <Calendar
          name="date"
          value={formData.date}
          onChange={(e) => handleChange('date', e.value)}
          dateFormat="mm/dd/yy"
          placeholder="اختر التاريخ"
        />
        <CustomDropdown
          name="selectedCountry"
          value={formData.selectedCountry}
          onChange={(e) => handleChange('selectedCountry', e.value)}
          options={dropdownData.countries}
          optionValue="id"
          optionLabel="name"
          placeholder="اختر البلد"
        />
        <CustomDropdown
          name="selectedGovernorate"
          value={formData.selectedGovernorate}
          onChange={(e) => handleChange('selectedGovernorate', e.value)}
          options={dropdownData.governorates}
          optionValue="id"
          optionLabel="name"
          placeholder="اختر المحافظة"
        />
        <CustomDropdown
          name="selectedStatus"
          value={formData.selectedStatus}
          onChange={(e) => handleChange('selectedStatus', e.value)}
          options={dropdownData.statuses}
          optionValue="id"
          optionLabel="name"
          placeholder="اختر الحالة"
        />
        <InputText value={searchTerm} placeholder="البحث في المنتجات" onChange={handleSearchNameChange} type="search" />
        <button className="tbl__addpriceList" onClick={handleSaveChanges}>
          <img src="/images/loading.svg" alt={'حفظ التعديلات و تحديث القائمة'} />
          حفظ قائمة المنتجات الجديدة
        </button>
      </div>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          value={filteredProducts}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="productID"
          className="tbl__table"
        >
          <Column field="productName" header="المنتج" />
          <Column field="price" header="السعر" editor={(props) => priceEditor(props)} />
          <Column field="commission" header="العمولة" editor={(props) => commissionEditor(props)} />
        </DataTable>
      )}
    </div>
  );
}

export default ProductListDetailsTableTs;

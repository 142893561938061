import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import _ from 'lodash';
import CustomMultiSelectDropdown from '../components/Dropdown/CustomMultiSelectDropdown';
import { useToast } from '../../context/ToastContext';
import { axiosInstance } from '../../global';
import { MultiSelect } from 'primereact/multiselect';
import { Modal } from 'antd';

function AddUsersToGroups({ visible, setVisible, groupId, selectedEmployees, onAddHandler }) {
  const toast = useToast();
  const [employees, setEmployees] = useState([]);
  const [preSelectedEmployees, setPreSelectedEmployees] = useState([]);
  function acceptClose() {
    setPreSelectedEmployees([]);
    setVisible(false);
  }

  function fetchItems() {
    axiosInstance
      .get(`/Employee/dropdown`)
      .then((response) => {
        setEmployees(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (visible) {
      const intersectionEmployees = _.intersectionWith(employees, selectedEmployees, _.isEqual);

      setPreSelectedEmployees(intersectionEmployees);
    }
  }, [visible]);

  function handleClose() {
    confirmDialog({
      header: 'هل تريد الغاء عملية اضافة مستخدمين الى مجموعة العمل ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 2
    });
  }

  return (
    <>
      <ConfirmDialog tagKey={2} />
      <Modal
        header='إضافة موظفين إلى المجموعة'
        open={visible}
        onHide={handleClose}
        className='modals'
        style={{ width: '50vw' }}
        footer={null}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر الموظف</label>
              <div className='flex gap-1'>
                <MultiSelect
                  display='chip'
                  options={employees}
                  filter
                  optionLabel='name'
                  style={{width:'100%'}}
                  value={preSelectedEmployees}
                  name='employees'
                  placeholder='اختر الموظف'
                  onChange={(e) => setPreSelectedEmployees(e.value)}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(preSelectedEmployees)}>
              اضافة الى المجموعة
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddUsersToGroups;

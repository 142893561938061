import React, { useRef, useState } from 'react';
import EmployeeDailyAssignmentTable from '../../../components/Tables/EmployeeDailyAssignmentTable';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../global';
import { useToast } from '../../../context/ToastContext';
import AddUsersToGroups from '../../../components/Modals/AddUsersToGroups';
import useFetch from '../../../hooks/useFetch';
import GenericModal from '../../../components/components/Modal/GenericModal';
import GenericForm from '../../../components/Forms/GenericForm';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import WorkingGroupMasterForm from './Forms/WorkingGroupMasterForm';
import AddEmployeeDailyAssignment from './Forms/AddEmployeeDailyAssignment';

const teamsSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  userId: yup.number().nullable().default(0),
  code: yup.string().nullable().default('TEAMCODE'),
  remarks: yup.string().nullable()
});

const WorkingGroupMasterSchema = yup.object().shape({
  weekDay: yup.string().required('weekDay is required'),
  employeeId: yup.number().integer('employeeId must be an integer').required('employeeId is required'),
  statusId: yup.number().integer('statusId must be an integer').required('statusId is required'),
  listOfLocationId: yup
    .array()
    .of(yup.number().integer('Each location ID must be an integer'))
    .required('listOfLocationId is required')
    .min(1, 'listOfLocationId must have at least one location ID'),
  remarks: yup.string().nullable()
});

function EmployeesManagement() {
  const toast = useToast();
  const navigate = useNavigate();
  const [addDailyAssignmentVisible, setAddDailyAssignmentVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [addUsersToGroupVisible, setAddUsersToGroupVisible] = useState(false);
  const [myrowData, setMyRowData] = useState<WorkigGroupMasterRowData | {}>({});
  const [expandFormVisible, setExpandFormVisible] = useState(false);
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  interface WorkigGroupMasterRowData {
    id: number;
    weekDay: string;
    employeeId: number;
    employeeName: string;
    statusId: number;
    statusName: string;
    remarks: string;
    locations: { id: number; name: string }[];
  }

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };
  const { data: teamData, isLoading: teamIsLoading } = useFetch(`/Team/all`);

  const {
    isVisible: isWorkingGroupVisitable,
    openModal: workingGroupOpenModal,
    closeModal: workingGroupCloseModal,
    control: workingGroupControl,
    handleSubmit: workingGroupSubmit,
    errors: workingGroupErrors,
    setValue: workingGroupSetValue,
    loading: workingGroupLoading,
    setLoading: workingGroupSetLoading,
    watch: workingGroupWatch
  } = useModal({ remarks: ' ', names: [] }, '/Team', fetchTabData, teamsSchema, toast);

  const {
    isVisible: isWorkingGroupMasterVisitable,
    openModal: workingGroupMasterOpenModal,
    closeModal: workingGroupMasterCloseModal,
    control: workingGroupMasterControl,
    handleSubmit: workingGroupMasterSubmit,
    errors: workingGroupMasterErrors,
    setValue: workingGroupMasterSetValue,
    loading: workingGroupMasterLoading,
    setLoading: workingGroupMasterSetLoading,
    watch: workingGroupMasterWatch
  } = useModal(
    { remarks: ' ', names: [] },
    '/EmployeesLocationsAssignmentMaster',
    fetchTabData,
    WorkingGroupMasterSchema,
    toast
  );

  const addWorkMaster = async () => {
    await axiosInstance.get(`/EmployeesLocationsAssignmentMaster/applyEmployeesLocationsAssignmentMaster`);
  };

  const handleWorkMaster = () => {
    addWorkMaster();
  };

  // function handleAddDailyAssignment(item: any) {
  //   axiosInstance
  //     .post(`/EmployeesDailyLocationsAssignment`, item)
  //     .then(() => {
  //       // toast.current.show({ severity: 'success', summary: 'تم إضافة جدول اعمال', life: 3000 });
  //       setAddDailyAssignmentVisible(false);
  //       fetchTabData();
  //     })
  //     .catch(() => {
  //       // toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء إضافة جدول اعمال', life: 3000 });
  //     });
  // }

  function handleShowAddUserToGroup(id: React.SetStateAction<null>) {
    setSelectedGroupId(id);
    setAddUsersToGroupVisible(true);
  }

  function handleAddUsersToGroup(item: any[]) {
    const employeesId = item.map((emp) => emp.id);

    axiosInstance
      .post(`/EmployeesTeamsMatrix/addEmployees`, { employeesId, teamId: selectedGroupId })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم إضافة المستخدمين الى مجموعة العمل', life: 3000 });
        fetchTabData();
        setAddUsersToGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء إضافة المستخدمين الى مجموعة العمل',
          life: 3000
        });
      });
  }

  const getSelectedEmployeeList = () =>
    teamData?.data?.find((item: { id: null }) => item.id === selectedGroupId)?.employeesTeamsMatrices;

  const handleActionButtonClick = (rowData: any) => {
    setMyRowData(rowData);
    setExpandFormVisible(true);
  };

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'مجموعات العمل',
            emptyStateButtonClick: () => {
              workingGroupOpenModal();
            },
            dataKey: 'WorkingGroups',
            columns: [
              { header: 'اسم المجموعة', field: 'teamName', isChip: false, sortable: false },
              {
                header: 'المستخدمين',
                field: 'employeesTeamsMatrices',
                isButton: true, sortable: false,
                actionCallback: (rowData: any) => handleShowAddUserToGroup(rowData.id)
              },
              { header: 'الحالة', field: 'statusName', sortable: false, isChip: true },
              {
                header: 'تاريخ الانشاء',
                field: 'teamCreationDate',
                sortable: false,
                isDate: true
              },
              {
                header: 'عرض التفاصيل',
                sortable: false,
                field: '',
                isButton: true,
                actionCallback: (rowData: any) =>
                  navigate(`/employees/management/working-groups/details/${rowData.id}`),
                buttonIcon: 'pi pi-eye'
              }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة مجموعات عمل',
                onClick: () => {
                  workingGroupOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              workingGroupOpenModal({
                id: rowData.id,
                name: rowData.teamName,
                code: rowData.teamCode,
                userId: rowData.userID,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Team/list',
            entityName: 'المجموعة',
            tableHeader: 'جدول  مجموعات العمل',
            emptyStateUrl: ''
          },
          {
            label: 'جدول الاعمال الرئيسى',
            emptyStateButtonClick: () => {
              workingGroupMasterOpenModal();
            },
            dataKey: 'WorkingGroupsMaster',
            columns: [
              { header: 'اليوم', field: 'weekDay', isChip: false },
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              {
                header: 'المناطق',
                field: 'locations',
                sortable: false,
                isButton: true,
                actionCallback: (rowData: any) => handleActionButtonClick(rowData)
              },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة جدول اعمال رئيسى',
                onClick: () => {
                  workingGroupMasterOpenModal();
                }
              },
              {
                label: 'تطبيق جدول الاعمال',
                onClick: async () => {
                  try {
                    await axiosInstance.get(
                      `/EmployeesLocationsAssignmentMaster/applyEmployeesLocationsAssignmentMaster`
                    );
                    toast.current.show({
                      type: 'success',
                      summary: 'عملية ناجحة',
                      detail: `تم تطبيق جدول الاعمال`,
                      life: 2000
                    });
                  } catch (error) {
                    toast.current.show({
                      type: 'error',
                      summary: 'عملية خاطئة',
                      detail: `فشلت عملية تطبيق جدول الاعمال :${error}`,
                      life: 3000
                    });
                  }
                }
              }
            ],
            rowOnClick: (rowData) => {
              const listOfLocationIds = rowData.locations.map((type: { id: number }) => type.id);
              workingGroupMasterOpenModal({
                id: rowData.id,
                employeeId: rowData.employeeId,
                weekDay: rowData.weekDay,
                listOfLocationId: listOfLocationIds,
                statusId: rowData.statusId,
                remarks: rowData.remarks
              });
            },
            apiUrl: '/EmployeesLocationsAssignmentMaster/list',
            entityName: 'المجموعة',
            tableHeader: 'جدول الاعمال الرئيسى',
            emptyStateUrl: ''
          },
          {
            label: 'جدول اﻻعمال',
            dataKey: 'WorkingTable',
            tabContent: <EmployeeDailyAssignmentTable />,
            rowOnClick: function(rowData: any): void {
              throw new Error('Function not implemented.');
            },
            tableHeader: '',
            entityName: '',
            columns: [],
            apiUrl: '',
            emptyStateUrl: '',
            edit: false,
            headerButtons: [
              {
                label: 'إضافة جدول اعمال',
                onClick: () => {
                  setAddDailyAssignmentVisible(true);
                }
              }
            ],
            withoutSearchBar: true
          }
        ]}
        headerTitle="الموظفين"
        headerIcon="/images/note1.svg"
        withHeader={true}
      />
      <AddEmployeeDailyAssignment
        visible={addDailyAssignmentVisible}
        setVisible={setAddDailyAssignmentVisible}
        // onAddHandler={handleAddDailyAssignment}
      />
      <AddUsersToGroups
        visible={addUsersToGroupVisible}
        setVisible={setAddUsersToGroupVisible}
        selectedEmployees={getSelectedEmployeeList()}
        groupId={selectedGroupId}
        onAddHandler={handleAddUsersToGroup}
      />
      <GenericModal
        headerTitle={'إضافة مجموعة عمل جديدة'}
        isVisible={isWorkingGroupVisitable}
        onHide={workingGroupCloseModal}
        handleSubmit={workingGroupSubmit}
        toast={toast}
        loading={workingGroupLoading}
        isEditMode={workingGroupWatch('id') !== undefined}
      >
        <GenericForm
          control={workingGroupControl}
          watch={workingGroupWatch}
          errors={workingGroupErrors}
          setValue={workingGroupSetValue}
          extraProps={{
            entityName: 'مجموعة عمل',
            isName: true
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة مجموعة عمل جديدة'}
        isVisible={isWorkingGroupMasterVisitable}
        onHide={workingGroupMasterCloseModal}
        handleSubmit={workingGroupMasterSubmit}
        toast={toast}
        loading={workingGroupLoading}
        isEditMode={workingGroupWatch('id') !== undefined}
      >
        <WorkingGroupMasterForm
          control={workingGroupMasterControl}
          watch={workingGroupMasterWatch}
          errors={workingGroupMasterErrors}
          setValue={workingGroupMasterSetValue}
          extraProps={{
            entityName: 'مجموعة عمل',
            isName: true
          }}
        />
      </GenericModal>
      <Dialog
        header={`${(myrowData as WorkigGroupMasterRowData).weekDay}`}
        visible={expandFormVisible}
        style={{ width: '50vw', direction: 'ltr' }}
        onHide={() => {
          if (!expandFormVisible) return;
          setExpandFormVisible(false);
        }}
        className="dialog"
      >
        {myrowData && (myrowData as WorkigGroupMasterRowData).locations ? (
          <div>
            {(myrowData as WorkigGroupMasterRowData).locations.map((location) => (
              <Chip key={location.id} className="dialog__innerDta" label={location.name} />
            ))}
          </div>
        ) : (
          <p>NO user Type Destination Sources for this User type</p>
        )}
      </Dialog>
    </>
  );
}

export default EmployeesManagement;

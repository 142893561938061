import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useNavigate, useParams } from 'react-router-dom';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { axiosInstance } from '../../../global';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

interface ServiceDetail {
  id: number;
  masterID: number;
  governorateID: number;
  governorateName: string;
  countryID: number;
  countryName: string;
  currencyID: number;
  currencyName: string;
  date: string;
  statusID: number;
  statusName: string;
  transDateTime: string;
  remarks: string;
  detailsID: number;
  serviceID: number;
  serviceName: string;
  cost: number;
  commetion: number;
}

function ServicesListDetailsTableTs() {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [selectedGovernorate, setSelectedGovernorate] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const params = useParams();
  const [services, setServices] = useState<ServiceDetail[]>([]);
  const [countries, setCountries] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [items, setItems] = useState<{
    date: Date | null;
    remarks: string;
  }>({
    date: null,
    remarks: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredServices, setFilteredServices] = useState<ServiceDetail[]>([]);
  const [serviceNameColumnWidth, setServiceNameColumnWidth] = useState<number>(150); // Default width

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries and statuses first
        const [countriesResponse, statusesResponse] = await Promise.all([
          axiosInstance.get('/Country/dropdown'),
          axiosInstance.get('/Status/dropdown/Product')
        ]);
        setCountries(countriesResponse.data.data);
        setStatuses(statusesResponse.data.data);

        // Now fetch service details
        const servicesResponse = await axiosInstance.get(`/ServicesCostsListDetail/list/${params.id}`);
        const servicesData = servicesResponse.data.data;
        setServices(servicesData);
        setLoading(false);

        // Calculate column width
        const maxLength = Math.max(
          ...servicesData.map((service: { serviceName: string | any[] }) => service.serviceName.length)
        );
        const estimatedWidth = Math.max(maxLength * 10, 150); // Minimum width is 150px
        setServiceNameColumnWidth(estimatedWidth);

        // Populate form fields from the first row of ServiceDetail if it exists
        if (servicesData.length > 0) {
          const firstService = servicesData[0];
          setItems({
            date: new Date(firstService.date),
            remarks: firstService.remarks
          });

          // Set selectedCountry, selectedGovernorate, and selectedStatus after options are fetched
          setSelectedCountry(firstService.countryID);
          setSelectedGovernorate(firstService.governorateID);
          setSelectedStatus(firstService.statusID);

          // Fetch governorates based on the country from the first service
          const governoratesResponse = await axiosInstance.get(
            `/Governorate/allByCountryIdDropdown/${firstService.countryID}`
          );
          setGovernorates(governoratesResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [params.id]);

  // Fetch governorates when the selected country changes
  useEffect(() => {
    if (selectedCountry) {
      axiosInstance
        .get(`/Governorate/allByCountryIdDropdown/${selectedCountry}`)
        .then((response) => {
          setGovernorates(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching governorates:', error);
        });
    } else {
      setGovernorates([]); // Reset governorates if no country is selected
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredServices(
        services.filter((service) => service.serviceName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    } else {
      setFilteredServices(services);
    }
  }, [searchTerm, services]);

  const handleSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e: any) => {
    setItems((prevItems) => ({
      ...prevItems,
      date: e.value
    }));
  };

  const handleCountryChange = (e: any) => {
    setSelectedCountry(e.value); // Update selected country
    setSelectedGovernorate(null); // Reset governorate selection when country changes
  };

  const handleGovernorateChange = (e: any) => {
    setSelectedGovernorate(e.value); // Update selected governorate
  };

  const handleStatusChange = (e: any) => {
    setSelectedStatus(e.value); // Update selected status
  };

  const handleSaveChanges = () => {
    const dataToSave = {
      governorateId: selectedGovernorate,
      date: moment(new Date(items.date??'')).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      remarks: items.remarks,
      statusId: selectedStatus,
      details: services.map(({ serviceID, cost, commetion }) => ({
        serviceId: serviceID,
        cost: cost,
        commission: commetion
      }))
    };

    axiosInstance
      .post(`/ServicesCostsListDetail/post`, dataToSave)
      .then(() => {
        toast.current?.show({ severity: 'success', summary: 'تم إضافة قائمة الخدمات بنجاح!', life: 3000 });
        navigate('/product/services')

      })
      .catch((error) => {
        toast.current?.show({ severity: 'error', summary: 'فشلت عملية إضافة قائمة خدمات جديدة', life: 3000 });
      });
  };

  const onEditorValueChange = (props: any, field: keyof ServiceDetail, value: any) => {
    const updatedServices = [...services];
    updatedServices[props.rowIndex] = {
      ...updatedServices[props.rowIndex],
      [field]: value as ServiceDetail[typeof field] // Ensure correct type for value
    };
    setServices(updatedServices);
  };

  const inputNumberEditor = (props: ColumnEditorOptions, field: keyof ServiceDetail) => {
    return (
      <InputNumber
        value={props.rowData[field]}
        onValueChange={(e) => onEditorValueChange(props, field, e.value)}
        mode='decimal'
        minFractionDigits={2}
        useGrouping={false}
      />
    );
  };

  const costEditor = (props: ColumnEditorOptions) => {
    return inputNumberEditor(props, 'cost');
  };

  const commetionEditor = (props: ColumnEditorOptions) => {
    return inputNumberEditor(props, 'commetion');
  };

  return (
    <div className='tbl ml-2 mb-4'>
      <div className='tbl__header'>
        <div className='flex flex-row gap-2'>

          <span className='header__title__price'>قائمة خدمات </span>
          <Calendar
            name='date'
            value={items.date}
            onChange={handleDateChange}
            dateFormat='mm/dd/yy'
            placeholder='اختر التاريخ'
            className='header__pricedate'
          />
          <CustomDropdown
            name='selectedCountry'
            value={selectedCountry}
            onChange={handleCountryChange}
            options={countries}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر البلد'
            className='header__pricecountry'
          />
          <CustomDropdown
            name='selectedGovernorate'
            value={selectedGovernorate}
            onChange={handleGovernorateChange}
            options={governorates}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المحافظة'
          />
          <CustomDropdown
            name='selectedStatus'
            value={selectedStatus}
            onChange={handleStatusChange}
            options={statuses}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر الحالة'
          />
        </div>
        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__search'
              placeholder='البحث في الخدمات'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
        <button className='tbl__addpriceList' onClick={handleSaveChanges}>
          <img src='/images/loading.svg' alt={'حفظ التعديلات و تحديث القائمة'} />
          حفظ قائمة الخدمات الجديدة
        </button>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={filteredServices}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column field='serviceName' header='الخدمة' style={{ width: `${serviceNameColumnWidth}px` }} />
          <Column field='cost' header='السعر' editor={(props) => costEditor(props)} />
          <Column field='commetion' header='العمولة' editor={(props) => commetionEditor(props)} />
        </DataTable>
      )}
    </div>
  );
}

export default ServicesListDetailsTableTs;

import React, { useCallback, useEffect, useState } from 'react';
import { axiosInstance } from '../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../style/Tables.scss';
import { Knob } from 'primereact/knob';
import GenericDataTable from '../../../components/components/GenericDataTable/GenericDataTable';
import { useDebounce } from '../../../components/components/DataTableCard/DataTableCard';
import { useApiState } from '../../../hooks/useApiState';

function SupplierExpandedTable({ supplierId }: { supplierId: any | undefined }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [myList, setMyList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotalCount, setPageTotalCount] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);

  const navigate = useNavigate();
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const fetchData = useCallback(
    async (params = { page, size: pageSize, search: debounceSearchTerm, sortField, sortOrder }) => {
      const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

      const response = await axiosInstance.get(`/Invoice/list/bySupplierId/${supplierId}`, {
        params: { ...params, status: 'Active', sort: sortParam }
      });
      return response.data;
    },
    [page, pageSize, debounceSearchTerm, sortField, sortOrder, supplierId]
  );

  const {
    lazyApiCall: fetchTabData,
    state: { isLoading, error, data }
  } = useApiState({
    isLazy: true,
    apiCall: fetchData
  });

  useEffect(() => {
    fetchTabData();
  }, [supplierId, page, pageSize, sortOrder, sortField, debounceSearchTerm]);

  useEffect(() => {
    if (data) {
      setMyList(data?.data ?? []);
    }
  }, [data]);

  useEffect(() => {
    setPage(0);
    setPageSize(10);
    setSortField(undefined);
    setSortOrder(undefined);
  }, [searchTerm]);

  function handleSearchNameChange(e: any) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const allowExpansion = (rowData: any) => {
    return rowData.transactions.length > 0;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className='p-3'>
        <DataTable value={data.transactions}>
          <Column field='code' header='كود الحركة'></Column>
          <Column field='receivingEntityName' header='اسم المستلم'></Column>
          <Column field='issuingEntityName' header='اسم المرسل'></Column>
          <Column field='amount' header='المبلغ'></Column>
          <Column field='transDateTime' header='تاريخ الحركة'></Column>
          <Column
            header='خيارات'
            body={(rowData) => (
              <Button
                label={`تفاصيل الحركة`}
                icon='pi pi-exclamation-circle'
                text
                onClick={() =>
                  navigate('/Accounting/dailyTransactions/details', {
                    state: { transId: rowData.id }
                  })
                }
                style={{ color: 'black' }}
              />
            )}
          />
        </DataTable>
      </div>
    );
  };

  const columns = [
    {
      header: 'رقم الفاتورة',
      field: 'invoiceNb',
      isChip: false
    },
    {
      header: 'تاريخ الفاتورة',
      field: 'creationDate',
      isDate: true
    },
    {
      header: 'المورد',
      field: 'supplierName',
      isChip: false
    },
    {
      header: 'المبلغ الكلى للفتورة',
      field: 'totalNetAmount',
      isChip: false
    },
    {
      header: 'الكمية',
      field: 'totalItemsCount',
      isChip: false
    },
    {
      header: 'نسبة الخصم',
      field: 'totalDiscountPercent',
      isChip: false
    },
    {
      header: 'المبلغ المتبقى',
      field: 'totalRemainingAmount',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    }
  ];

  return (
    <div className='Connection'>
      <div className='tbl ml-3 mb-4 mr-3'>
        <div className='tbl__visit__header'>
          <div className='flex flex-row gap-4'>
            <span className='header__title'> قائمة البحث بالفاتورة </span>
          </div>

          <div className='header__visit__tools'>
            <span className='p-input-icon-left'>
              <i className='pi pi-search' />
              <InputText
                value={searchTerm}
                className='header__tools__search w-38rem'
                placeholder='ابحث برقم الفاتورة'
                onChange={handleSearchNameChange}
                type='search'
              />
            </span>
          </div>
        </div>
        <div className='tbl__table'>
          <GenericDataTable
            data={myList}
            columns={columns}
            page={page}
            pageSize={pageSize}
            totalRecords={pageTotalCount}
            isLoading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onPageChange={(e: any) => {
              if (e.page !== undefined) {
                setPage(e.page);
              }
              if (e.rows !== undefined) {
                setPageSize(e.rows);
              }
            }}
            onSort={(e: any) => {
              setSortField(e.sortField);
              const order = e.sortOrder === 1 ? 'asc' : 'desc';
              setSortOrder(order);
              setPage(0);
            }}
            onRowClick={(rowData) => {
              navigate('/finance/transaction/pay', {
                state: { invoiceData: rowData, type: 'pay' }
              });
            }}
            edit={false}
            entityName='الفاتورة'
            emptyStateUrl=''
            allowExpansion={allowExpansion}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            SeparetentityName={'سداد الفاتورة'}
          />
        </div>
      </div>
    </div>
  );
}

export default SupplierExpandedTable;

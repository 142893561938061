// SearchSection.js
import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import CustomFormField from '../../../components/components/Form/CustomFormField';

function SalarySearchSection({ control, errors, handleSearchOnClick }: any) {
  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <Row align={'middle'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <CustomFormField
            control={control}
            name={'month'}
            label={'الشهر'}
            errors={errors}
            type={'calendar'}
            calenderPicker={'month'}
          />
        </Col>
        <Col span={4}>
          <Button
            onClick={handleSearchOnClick}
            htmlType="button"
            style={{ width: '100%', marginTop: '2rem' }}
          >
            بحث
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default SalarySearchSection;

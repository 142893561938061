import React, { useState } from 'react';

import './AddSupplier.scss';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../global';

import AddSupplier1 from './AddSupplier1';
import { useToast } from '../../../context/ToastContext';
import PageHeader from '../../../components/components/Header/PageHeader';

const AddSupplier = ({ token }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({
    name: '',
    countryId: '',
    phone: '',
    email: '',
    website: '',
    paymentTermId: '',
    deliveryTermId: '',
    contactPerson: '',
    contactPersonMobile: '',
    address: '',
    remarks: '',
    statusId: 1
  });
  const [isFormOk, setIsFormOk] = useState(false);

  function handleِAddOnClick() {
    try {
      axiosInstance
        .post('/Supplier', supplier)
        .then((res) => {
          toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم اضافة مورد بنجاح' });
          setTimeout(() => navigate('/warehouses/suppliers/'), 1500);
        })
        .catch((error) => {
          toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة مورد' });
        });
    } catch (errorAddSupplier) {
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة مورد' });
    }
  }

  return (
    <div className='screen'>
      <PageHeader
        icon={'/images/back-arrow.svg'}
        buttons={[]}
        headerTitle={'إضافة مورد جديد'}
        iconOnClick={() => navigate(-1)}
      />
      <AddSupplier1 setCheck={setIsFormOk} supplier={supplier} setSupplier={setSupplier} />
      <div className='btn mb-5'>
        <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
          تأكيد اضافة المورد
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);

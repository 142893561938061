import { PickerMode } from 'rc-picker/lib/interface';

export const generateInstallmentOptions = (maxInstallments: number) => {
  return Array.from({ length: maxInstallments }, (_, index) => ({
    id: index + 1,
    name: (index + 1).toString()
  }));
};

export function calculatePercentageChange(thisMonth: number, lastMonth: number) {
  if (lastMonth === 0) {
    if (thisMonth === 0) {
      return { percent: '0%', change: 0 };
    } else {
      return { percent: '100% من الشهر الماضي', change: null };
    }
  } else {
    const change = ((thisMonth - lastMonth) / lastMonth) * 100;
    const percent = change.toFixed(2) + '%' + ' من الشهر الماضي';
    return { percent, change };
  }
}


export const getFormat = (picker?: PickerMode) => {
  if(!picker) {
    return 'DD/MM/YYYY';
  }
  switch (picker) {
    case 'time':
      return 'HH:mm:ss';
    case 'date':
      return 'DD/MM/YYYY';
    case 'week':
      return 'YYYY-[W]WW'; // Week format
    case 'month':
      return 'MM/YYYY';
    case 'quarter':
      return 'Qo YYYY'; // Quarter format
    case 'year':
      return 'YYYY';
    default:
      return 'DD/MM/YYYY'; // Default format
  }
};

import React from 'react';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface InvoiceReveiwStepProps {
  type: number;
}

const InvoiceReveiwStep = ({ type }: InvoiceReveiwStepProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();
  const supplier = watch('supplier');
  const InvoiceCode = watch('invoiceReference');
  const reciveOnWarehouse = watch('reciveOnWarehouse');
  const totalAmount = watch('totalAmount');
  const paidAmount = watch('paidAmount');
  const totalDiscount = watch('mytotalDiscount');
  const reqAmount = watch('requstedAmount');
  const remainigAmount = reqAmount - paidAmount || 0;
  const remarks = watch('remarks');
  const items = watch('items');
  const typeID = watch('typeId');


  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      {type === 3 || typeID === 3 ? (
        <DetailsSheetGrid
          headers={[
            'الرقم المرجعى',
            'تاريخ الفاتورة',
            'الاستلام على مخزن',
            'اسم المورد',
            'العملة',
            'إجمالى الفاتورة',
            'الخصم',
            'المبلغ المطلوب',
            'المبلغ المدفوع',
            'المتبقى',
            'ملاحظات'
          ]}
          contents={[
            InvoiceCode,
            moment(new Date()).locale('en-GB').format('YYYY-MM-DD'),
            <span> {reciveOnWarehouse === true ? 'نعم' : 'لا'}</span>,
            <div className='flex flex-row gap-5'>
              {supplier?.name}
              <Link to={`/warehouses/supplier/details/${supplier?.id}`} className='primary__data--link text-blue-600'>
                عرض بيانات المورد{` > `}
              </Link>
            </div>,
            'جنية مصرى',
            totalAmount,
            totalDiscount,
            reqAmount,
            paidAmount,
            remainigAmount,
            remarks
          ]}
          title={'بيانات الفاتورة'}
        />
      ) : (
        <DetailsSheetGrid
          headers={[
            'الرقم المرجعى',
            'تاريخ الفاتورة',
            'العملة',
            'إجمالى الفاتورة',
            'الخصم',
            'المبلغ المطلوب',
            'المبلغ المدفوع',
            'المتبقى',
            'ملاحظات'
          ]}
          contents={[
            InvoiceCode,
            moment(new Date()).locale('en-GB').format('YYYY-MM-DD'),
            'جنية مصرى',
            totalAmount,
            totalDiscount,
            reqAmount,
            paidAmount,
            remainigAmount,
            remarks
          ]}
          title={'بيانات الفاتورة'}
        />
      )}

      {type === 3 || typeID === 3 ? (
        <div className='tbl mt-5'>
          <DataTable value={items} className='tbl__table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
            <Column field='itemName' header='اسم المنتج'></Column>
            <Column field='price' header='سعر المنتج'></Column>
            <Column field='qty' header='الكمية'></Column>
            <Column field='totalPrice' header='السعر الاجمالى'></Column>
          </DataTable>
        </div>
      ) : (
        <div className='tbl mt-5'>
          <DataTable value={items} className='tbl__table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
            <Column field='itemName' header='اسم المصروف'></Column>
            <Column field='price' header='سعر المصروف'></Column>
            <Column field='qty' header='الكمية'></Column>
            <Column field='totalPrice' header='السعر الاجمالى'></Column>
          </DataTable>
        </div>
      )}
    </Card>
  );
};

export default InvoiceReveiwStep;

import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import GenericModal from '../../../components/components/Modal/GenericModal';
import VacationRequestForm from './Form/VacationRequestForm';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

const addVacationScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  employeeId: yup.number().required().default(null),
  vacationTypeId: yup.number().required().default(null),
  fromDate: yup.date().required().default(null),
  toDates: yup.date().required().default(null),
  days: yup.number().required().default(null),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function VacationRequest() {
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const toast = useToast();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, '/VacationsRequest', fetchTabData, addVacationScheme, toast, (data) => {
      return {
        ...data,
        fromDate: moment(new Date(data.fromDate)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        toDates: moment(new Date(data.toDates)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      };
    });

  return (
    <>
      <GenericPage
        tabs={[
          {
            label: 'الموظفين',
            headerButtons: [
              {
                label: 'اضافه اجازه',
                onClick: () => {
                  openModal();
                }
              }
            ],
            tableHeader: 'اجازات الموظفين',
            isHidden: true,
            rowOnClick: (rowData) => {
              openModal({ ...rowData, fromDate: new Date(rowData.fromDate), toDates: new Date(rowData.toDates) });
            },
            apiUrl: 'VacationsRequest/list',
            emptyStateButtonClick: () => {
              openModal();
            },
            dataKey: 'VacationsRequest',
            edit: true,
            entityName: 'اجازه',
            emptyStateUrl: '',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              { header: 'نوع الاجازة', field: 'vacationTypeName', isChip: false },
              { header: 'مدة الاجازة', field: 'period', isChip: false },
              { header: 'عدد ايام الاجازة', field: 'days', isChip: false },
              { header: 'مقبولة من', field: 'approvedByName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ]
          }
        ]}
        headerTitle={'الموظفين'}
        headerIcon={'/images/employee.svg'}
        ref={genericPageRef}
      />

      <GenericModal
        isEditMode={watch('id') !== undefined}
        headerTitle={watch('id') === undefined ? 'اضافه اجازه' : 'تعديل اجازه'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <VacationRequestForm control={control} errors={errors} setValue={setValue} watch={watch} />
      </GenericModal>
    </>
  );
}

export default VacationRequest;

import React, { useCallback, useEffect, useState } from 'react';
import { axiosInstance } from '../../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../../style/Tables.scss';
import { Knob } from 'primereact/knob';
import GenericDataTable from '../../../../components/components/GenericDataTable/GenericDataTable';
import './AddConnectionDetails.scss';
import { useDebounce } from '../../../../components/components/DataTableCard/DataTableCard';
import { useApiState } from '../../../../hooks/useApiState';

function AddConnection1() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState(null);
  const [myList, setMyList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotalCount, setPageTotalCount] = useState(0);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const fetchCustomers = useCallback(() => {
    const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

    axiosInstance
      .get(`/Customer/all`, {
        params: { page, size: pageSize, search: debounceSearchTerm, status: 'Active', sort: sortParam }
      })
      .then((response) => {
        setMyList(response.data.data);
        setPageTotalCount(response.data.totalCount);
        setisLoading(false);
      })
      .catch((error) => {});
  }, [page, pageSize, debounceSearchTerm, sortField, sortOrder]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  // const fetchData = useCallback(
  //   async (params = { page, size: pageSize, search: debounceSearchTerm, sortField, sortOrder }) => {
  // const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

  //     const response = await axiosInstance.get(`/Customer/all`, {
  //       params: { ...params, status: 'Active', sort: sortParam }
  //     });
  //     return response.data;
  //   },
  //   [page, pageSize, debounceSearchTerm, sortField, sortOrder]
  // );

  // const {
  //   lazyApiCall: fetchTabData,
  //   state: { isLoading, error, data }
  // } = useApiState({
  //   isLazy: true,
  //   apiCall: fetchData
  // });

  // useEffect(() => {
  //   fetchTabData();
  // }, [page, pageSize, sortOrder, sortField, debounceSearchTerm]);

  // useEffect(() => {
  //   if (data) {
  //     setMyList(data?.data ?? []);
  // setPageTotalCount(data?.data?.totalCount);
  //   }
  // }, [data]);

  useEffect(() => {
    setPage(0);
    setPageSize(10);
    setSortField(undefined);
    setSortOrder(undefined);
  }, [searchTerm]);

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const allowExpansion = (rowData) => {
    return rowData.customersDevices.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className='p-3'>
        <DataTable value={data.customersDevices}>
          <Column field='device.name' header='الجهاز'></Column>
          <Column field='membraneSerialNb' header='رقم ممبرين'></Column>
          <Column field='motorSerialNb' header='رقم الموتور'></Column>
          <Column field='type.name' header='نوع الجهاز'></Column>
          <Column field='installationDate' header='تاريخ التركيب'></Column>
          <Column field='expiryDate' header='صلاحية الضمان'></Column>
          <Column
            field='device.remainingTime'
            header='مؤشر التغيير'
            body={(rowData) => {
              return rowData.device.remainingTime > 1 ? (
                <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#EEBA00' />
              ) : (
                <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#FF0000' />
              );
            }}
          ></Column>
          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`/Activites/Add/${data.id}/${rowData.id}`}>
                <Button label={`اضافة اتصال`} icon='pi pi-plus' text className='primary__data--link text-blue-600' />
              </Link>
            )}
          />
        </DataTable>
      </div>
    );
  };

  const columns = [
    { field: 'fullName', header: 'العميل' },
    { field: 'phoneNb', header: 'رقم الهاتف' },
    { field: 'defaultAddress.name', header: 'العنوان الرئيسي' },
    { field: 'numberOfDevices', header: 'عدد الأجهزة' },
    { field: 'numberOfStages', header: 'عدد المراحل المستحقة' },
    { field: 'numberOfComplaints', header: 'شكاوى العميل' },
    { field: 'preferredContactMethod.name', header: 'طريقة التواصل' },
    { field: 'status.name', header: 'الحالة', isChip: true }
  ];

  return (
    <div className='Connection'>
      <div>
        <div className='Note ml-2 mb-4 mr-3'>
          <li className='pi pi-info-circle'></li>
          يجب اضافة تفاصيل الاتصال اولاً حتى يكون بأمكانك اضافة الزيارة
          <br />
          قم بالبحث عن العميل او بأمكانك اضافة عميل جديد
        </div>
        <div className='tbl ml-3 mb-4 mr-3'>
          <div className='tbl__visit__header'>
            <div className='flex flex-row gap-4'>
              <span className='header__title'> قائمة البحث بالعملاء </span>
            </div>

            <div className='header__visit__tools'>
              <span className='p-input-icon-left'>
                <i className='pi pi-search' />
                <InputText
                  value={searchTerm}
                  className='header__tools__search w-38rem'
                  placeholder='ابحث بأسم العميل'
                  onChange={handleSearchNameChange}
                  type='search'
                />
              </span>
            </div>
            <button className='tbl__visit__addbtn' onClick={() => navigate('/customers/addCustomer')}>
              <img src='/images/plus.svg' alt={'إضافة عميل '} />
              إضافة عميل
            </button>
          </div>
          <div className='tbl__table'>
            <GenericDataTable
              data={myList}
              columns={columns}
              page={page}
              pageSize={pageSize}
              totalRecords={pageTotalCount}
              isLoading={isLoading}
              sortField={sortField}
              sortOrder={sortOrder}
              onPageChange={(e) => {
                if (e.page !== undefined) {
                  setPage(e.page);
                }
                if (e.rows !== undefined) {
                  setPageSize(e.rows);
                }
              }}
              onSort={(e) => {
                setSortField(e.sortField);
                const order = e.sortOrder === 1 ? 'asc' : 'desc';
                setSortOrder(order);
                setPage(0);
              }}
              onRowClick={(rowData) => {
                navigate(`/customers/details/${rowData.id}`);
              }}
              edit={false}
              entityName='العميل'
              emptyStateUrl='/add-customer'
              allowExpansion={allowExpansion}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConnection1;

import React, { useEffect, useState } from 'react';
import './AddSupplierDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';

import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { Card } from 'primereact/card';

function AddSupplier1({ userId, supplier, setSupplier, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [countries, setCountries] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [deliveryTerms, setDeliveryTerms] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [selectedDeliveryTerm, setSelectedDeliveryTerm] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`/Country/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setCountries(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/PaymentTerm/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setPaymentTerms(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/DeliveryTerm/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setDeliveryTerms(tempList);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // if (Object.values(supplier).filter((item) => item === '' || !item).length) {
    //   setCheck(false);
    // } else {
    //   setCheck(true);
    // }

    const isValid =
      Object.entries(supplier).filter(([key, val]) => key !== 'remarks' && (val === '' || !val)).length === 0;
    setCheck(isValid);
  }, [supplier]);

  function handleAddInputChange(e) {
    setSupplier({ ...supplier, [e.target.name]: e.target.value || e.value });
  }

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <form className='supplier'>
        {!deliveryTerms || !paymentTerms || !countries ? (
          <ProgressSpinner />
        ) : (
          <div className='supplier__content'>
            <div className='supplier__content__right'>
              <span className='Title'>البيانات الاساسية للمورد</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  اسم المورد<span style={Style}>*</span>
                </label>
                <InputText name='name' value={supplier.name} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  بلد المورد<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='countryId'
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e.value);
                    handleAddInputChange(e);
                  }}
                  options={countries}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر بلد المورد'
                />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  عنوان المورد<span style={Style}>*</span>
                </label>
                <InputText name='address' value={supplier.address} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  شروط الدفع<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='paymentTermId'
                  value={selectedPaymentTerm}
                  onChange={(e) => {
                    setSelectedPaymentTerm(e.value);
                    handleAddInputChange(e);
                  }}
                  options={paymentTerms}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر شروط الدفع'
                />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  طريقة الشحن<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='deliveryTermId'
                  value={selectedDeliveryTerm}
                  onChange={(e) => {
                    setSelectedDeliveryTerm(e.value);
                    handleAddInputChange(e);
                  }}
                  options={deliveryTerms}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر طريقة الشحن'
                />
              </div>
              <span className='Title'>بيانات التواصل مع المورد</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  رقم التواصل<span style={Style}>*</span>
                </label>
                <InputText name='phone' value={supplier.phone} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  البريد الالكتروني<span style={Style}>*</span>
                </label>
                <InputText name='email' value={supplier.email} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  الموقع الالكتروني<span style={Style}>*</span>
                </label>
                <InputText name='website' value={supplier.website} onChange={handleAddInputChange} />
              </div>
            </div>
            <div className='supplier__content__left'>
              <span className='Title'>بيانات الموفوض</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  اسم المفوض<span style={Style}>*</span>
                </label>
                <InputText name='contactPerson' value={supplier.contactPerson} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>
                  رقم التواصل مع المفوض<span style={Style}>*</span>
                </label>
                <InputText
                  name='contactPersonMobile'
                  value={supplier.contactPersonMobile}
                  onChange={handleAddInputChange}
                />
              </div>
              <span className='Title'>اخرى</span>
              <div className='flex w-full flex-column gap-4'>
                <label className='addsupplier__label'>ملاحظات</label>
                <InputTextarea
                  placeholder='ملاحظات عن المورد بشكل عام'
                  name='remarks'
                  value={supplier.remarks}
                  onChange={handleAddInputChange}
                  rows={3}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier1);

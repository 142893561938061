import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import SupplierExpandedTable from './SupplierExpandedTable';

interface SupplierDetailsProps {
  supplierID: string | undefined;
  types: any;
}

function SuppliersDetailsTables({ supplierID, types }: SupplierDetailsProps) {
  const navigate = useNavigate();

  const SupplierTransColumns = [
    { header: 'رقم العملية', field: 'transNb', isChip: false },
    { header: 'نوع اﻻذن', field: 'transTypeName', isChip: false },
    { header: 'نوع المصدر', field: 'destinationSourceName', isChip: false },
    { header: 'المصدر', field: 'supplierName', isChip: false },
    { header: 'نوع المستقبل', field: 'destinationSourceName', isChip: false },
    { header: 'المستقبل', field: 'warehouseName', isChip: false }
  ];

  return (
    <>
      {types === 1 ? (
        <GenericPage
          tabs={[
            {
              isHidden: false,
              label: 'حركات المورد',
              tableHeader: 'حركات المورد',
              dataKey: 'tab1',
              emptyStateButtonClick: () => {},
              apiUrl: `/WarehouseTransactionsGen/allBySupplier/${supplierID}`,
              edit: false,
              columns: SupplierTransColumns,
              emptyStateUrl: '',
              entityName: 'الاذن',
              rowOnClick: (rowData) => {
                navigate(`/warehouses/transactions/details/${rowData.id}`);
              },
              headerButtons: []
            }
          ]}
          withHeader={false}
          headerTitle={''}
          headerIcon={''}
        />
      ) : (
        <GenericPage
          tabs={[
            {
              label: 'جدول فواتير الموردين',
              dataKey: 'WorkingTable',
              tabContent: <SupplierExpandedTable supplierId={supplierID} />,
              rowOnClick: function (rowData: any): void {
                throw new Error('Function not implemented.');
              },
              tableHeader: '',
              entityName: '',
              columns: [],
              apiUrl: '',
              emptyStateUrl: '',
              edit: false,
              headerButtons: [],
              withoutSearchBar: true
            }
          ]}
          withHeader={false}
          headerTitle={''}
          headerIcon={''}
        />
      )}
    </>
  );
}

export default SuppliersDetailsTables;

import { Card } from 'primereact/card';
// import { InputText } from 'primereact/inputtext';
import { Button } from 'antd';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '../../../context/ToastContext';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { Input } from 'antd';
import useFetch from '../../../hooks/useFetch';

const CustomerChooserStep = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [externalSearchTerm, setExternalSearchTerm] = useState('');

  const { data: PointOfSaleData, isLoading: PointOfSaleIsLoading } = useFetch(`/PointOfSale/dropdown`);

  const handleSearchClick = () => {
    setExternalSearchTerm(searchText);
  };

  // Memoize the selectedTab object
  const selectedTab = useMemo(
    () => ({
      rowOnClick: (rowData: any) => {
        setValue('customer', rowData);
      },
      emptyStateButtonClick: () => {},
      label: 'customer Table',
      tableHeader: 'customer Table',
      entityName: 'العميل',
      dataKey: 'customer',
      columns: [
        { header: 'العميل', field: 'fullName', isChip: false },
        { header: 'رقم الهاتف', field: 'mobile', isChip: false },
        { header: 'العنوان الرئيسي', field: 'defaultAddress', isChip: false },
        { header: 'عدد الاجهزة', field: 'numberOfDevices', isChip: false },
        { header: 'عدد المراحل المستحقة', field: 'numberOfStages', isChip: false },
        { header: 'شكاوى العميل', field: 'numberOfComplaints', isChip: false },
        { header: 'طريقة التواصل', field: 'preferredContactMethod', isChip: false },
        { header: 'الحالة', field: 'statusName', isChip: true }
      ],
      apiUrl: 'Customer/list',
      emptyStateUrl: '',
      edit: false,
      extraParams: {
        status: 'Active'
      },
      rowClickable: true
    }),
    []
  );

  return (
    <main>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        {/* <div style={{ padding: '1rem' }}> */}
        {/* <div style={{ marginBottom: '1rem' }}>
            <label style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'IBM Plex Sans Arabic' }}>
              بحث عن العميل
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            </label>
          </div> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px'
          }}
        >
          <CustomFormField
            control={control}
            name={'pointOfSalesId'}
            label={'اختر منفذ البيع'}
            errors={errors}
            type={'dropdown'}
            options={PointOfSaleData?.data ?? []}
            required
          />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '0.8rem' }}>
            <label style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'IBM Plex Sans Arabic' }}>
              بحث عن العميل
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            </label>
            <Input style={{ width: '100%' }} onChange={(e) => setSearchText(e.target.value)} />
          </div>
          <Button
            style={{
              backgroundColor: '#A2FB5D',
              width: '122px',
              border: '1px solid #333',
              borderRadius: '6px',
              marginTop: '30px'
            }}
            onClick={handleSearchClick}
          >
            بحث
          </Button>
        </div>
        <label
          style={{
            fontSize: '20px',
            fontFamily: 'IBM Plex Sans Arabic',
            fontWeight: 'bold',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            display: 'block'
          }}
        >
          اختر عميل من قائمة العملاء
        </label>

        <DataTableCard selectedTab={selectedTab} withoutHeader={true} externalSearchTerm={externalSearchTerm} />
        {/* </div> */}
      </Card>
    </main>
  );
};

export default CustomerChooserStep;

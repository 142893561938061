import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function DirectSalesScreen() {
  const navigate = useNavigate();
  const SalesOrderColumns = [
    { header: ' كود امر البيع', field: 'code', isChip: false },
    { header: 'البائع', field: 'employeeName', isChip: false },
    { header: 'تاريخ البيع', field: 'creationDate', isDate: true },
    { header: 'بيانات العميل', field: 'customerInfo', isChip: false },
    { header: 'الكمية المباعة', field: 'nbofItems', isChip: false },
    { header: 'اجمالى المبلغ', field: 'totalNetAmount', isChip: false }
  ];
  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'اوامر البيع',
          tableHeader: 'قائمة اوامر البيع',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/SalesOrder/list/byTypeId/2',
          edit: false,
          columns: SalesOrderColumns,
          emptyStateUrl: '',
          entityName: 'أمر البيع',
          rowOnClick: (rowData) => {

            navigate('/Sales/SalesOrder/details', {
              state: { SalesOrderIds: rowData.id }
            });
          },
          headerButtons: [
            {
              label: 'إضافة أمر بيع جديد',
              onClick: () => {
                navigate('/Sales/SalesOrder/add');
              }
            }
          ]
        }
      ]}
      headerTitle={'المبيعات'}
      headerIcon={'../images/shoppingbag.svg'}
    />
  );
}

export default DirectSalesScreen;

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'antd';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { axiosInstance } from '../../../global';
import moment from 'moment';

const ContractChooserStep = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const customer = watch('customer');
  const [searchText, setSearchText] = useState('');
  const [externalSearchTerm, setExternalSearchTerm] = useState('');

  const handleSearchClick = () => {
    setExternalSearchTerm(searchText);
  };

  const product = watch('product');

  // Memoize the selectedTab object
  const selectedTab = useMemo(
    () => ({
      rowOnClick: async (rowData: any) => {
        const response = await axiosInstance.get(`Contract/new/${rowData.contractID}`);
        const contractData = response.data.data;
        const productResponse = await axiosInstance.get(
          `Product/new/${contractData.productId ?? contractData.customerDeviceId}`
        );
        const productData = productResponse?.data?.data;
        setValue('id', null);
        setValue('product', productData);

        setValue('contractDate', moment(new Date(contractData.contractDate)).toDate());
        setValue('expiryDate', moment(new Date(contractData.expiryDate)).toDate());
        setValue('freeInstallation', contractData.freeInstallation);
        setValue('sellingPrice', contractData.sellingPrice);
        setValue('installmentsTypeId', contractData.installmentsTypeId);
        setValue('installmentAmount', contractData.installmentAmount);
        setValue('downPayment', contractData.downPayment);
        setValue('nbOfInstallments', contractData.nbOfInstallments);
        setValue('beneficiaryEmployeeId', contractData.beneficiaryEmployeeId);
        setValue('statusId', contractData.statusId);
        setValue('remarks', contractData.remarks);
        setValue('isEdit', true);
        setValue(
          'deviceStages',
          contractData.deviceStages?.map(
            (stage: {
              stageNb: any;
              productStageId: any;
              stageItemName: any;
              stageItemId: any;
              installedItemId: any;
              mandatoryReplacement: any;
              replacementMonths: any;
              lastReplacementDate: moment.MomentInput;
              remarks: any;
            }) => ({
              stageNb: stage.stageNb,
              productStageId: stage.productStageId,
              stageItemName: stage.stageItemName,
              stageItemId: stage.stageItemId,
              installedItemId: stage.installedItemId,
              mandatoryReplacement: stage.mandatoryReplacement,
              replacementMonths: stage.replacementMonths,
              lastReplacementDate: stage.lastReplacementDate ? moment(new Date(stage.lastReplacementDate.toString())).toDate() : null,
              remarks: stage.remarks
            })
          )
        );
        setValue(
          'productsStock',
          contractData.productsStock?.map((product: { itemId: any; qty: any; remainingQty: any; remarks: any }) => ({
            itemId: product.itemId,
            qty: product.qty,
            remainingQty: product.remainingQty,
            remarks: product.remarks
          }))
        );
        setValue(
          'servicesStock',
          contractData.servicesStock?.map((service: { serviceId: any; qty: any; remainingQty: any; remarks: any }) => ({
            serviceId: service.serviceId,
            qty: service.qty,
            remainingQty: service.remainingQty,
            remarks: service.remarks
          }))
        );
      },
      emptyStateButtonClick: () => {

      },
      label: 'Contract Table',
      tableHeader: 'Contract Inventory',
      entityName: 'Contract',
      dataKey: 'sku',
      columns: [
        { header: 'الجهاز', field: 'productName', isChip: false },
        { header: 'المرجع', field: 'reference', isChip: false },
        { header: 'حالة العقد', field: 'statusName', isChip: true }
      ],
      apiUrl: 'Contract/list',
      emptyStateUrl: '/no-contract',
      edit: false,
      extraParams: {
        contractType: 'Company',
        productId: product.deviceID
      },
      rowClickable: true
    }),
    []
  );

  return (
    <main>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <div style={{ padding: '1rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <label style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'IBM Plex Sans Arabic' }}>
              بحث عن العقد
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            </label>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <InputText style={{ width: '100%' }} onChange={(e) => setSearchText(e.target.value)} />
            <Button
              style={{
                backgroundColor: '#A2FB5D',
                width: '122px',
                border: '1px solid #333',
                borderRadius: '6px'
              }}
              onClick={handleSearchClick}
            >
              بحث
            </Button>
          </div>
          <label
            style={{
              fontSize: '20px',
              fontFamily: 'IBM Plex Sans Arabic',
              fontWeight: 'bold',
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
              display: 'block'
            }}
          >
            اختر العقد من قائمة القود
          </label>

          <DataTableCard selectedTab={selectedTab} withoutHeader={true} externalSearchTerm={externalSearchTerm} />
        </div>
      </Card>
    </main>
  );
};

export default ContractChooserStep;

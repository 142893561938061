import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import GenericModal from '../../../components/components/Modal/GenericModal';
import ServiceMasterForm from './ServiceMasterForm';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

const addServicePriceListScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  governorateId: yup.number().required().default(null),
  currencyId: yup.number().required().default(null),
  date: yup.date().required('Date is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().default('')
});

function ServicesList() {
  const navigate = useNavigate();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const toast = useToast();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, '/ServicesCostsListMaster', fetchTabData, addServicePriceListScheme, toast, (data) => {
      return {
        ...data,
        date: moment(new Date(data.date)).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      };
    });

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: '',
            emptyStateButtonClick: () => {
              // navigate('/product/services/add');
              openModal();
            },
            dataKey: '',
            columns: [
              { header: 'اسم المحافظه', field: 'governorateName', isChip: false },
              { header: 'اسم الدوله', field: 'countryName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'تاريخ الادخال', field: 'date', isChip: false },
              { header: 'ملاحظات', field: 'remarks', isChip: true }
            ],
            rowOnClick: (rowData) => {
              // navigate(`/product/services/edit/${rowData.id}`);
              navigate(`/product/services/${rowData.id}`);
            },
            emptyStateUrl: '',
            edit: false,
            isHidden: true,
            apiUrl: 'ServicesCostsListMaster/list',
            entityName: 'قائمة خدمات',
            headerButtons: [
              {
                label: 'إضافة قائمة خدمات جديدة',
                onClick: () => {
                  // navigate('/product/services/add');
                  openModal();
                }
              }
            ],
            tableHeader: 'قائمة اسعار خدمات'
          }
        ]}
        headerTitle={'المنتجات'}
        headerIcon={'../images/productbox.svg'}
      />
      <GenericModal
        headerTitle={'إضافة قائمة الأسعار'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <ServiceMasterForm watch={watch} control={control} errors={errors} setValue={setValue} />
      </GenericModal>
    </>
    // <div className='w-full'>
    //   <span className='main__title'>
    //     <i className='pi pi-database main__title--icon'></i>المنتجات
    //   </span>
    //
    //   <div className='mt-5 pr-4 pl-4'>
    //     <Tabs
    //       headers={['قائمة الخدمات']}
    //       contents={[
    //         <ProductServicesListTable
    //           urlName='ServicesCostsListMaster'
    //           headerTitle='قائمة خدمات المنتج'
    //           addBtnText='إضافة خدمة جديدة'
    //         />
    //       ]}
    //     />
    //   </div>
    // </div>
  );
}

export default ServicesList;

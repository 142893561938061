import React, { useState, useEffect, useMemo, useRef } from 'react';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { Button } from 'primereact/button';
import { axiosInstance } from '../../global';
import { Calendar as PrimeCalender } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Badge, Calendar, Card, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/ar';
import ar_EG from 'antd/lib/locale/ar_EG';
import '../../style/Tables.scss';
import { useToast } from '../../context/ToastContext';

function EmployeeDailyAssignmentTable() {
  const toast = useToast();
  const style1 = useMemo(() => ({ width: '100px', border: '1px solid black', fontSize: '1rem' }), []);
  const Style2 = { color: 'red', padding: '0.7rem' };
  const [tableData, setTableData] = useState([]);
  const [dropdownData, setDropdownData] = useState({});
  const [teamDropdownData, setTeamDropdownData] = useState({});
  const [formState, setFormState] = useState({
    workingGroupId: null,
    EmployeeID: '',
    LocationsID: '',
    FromDate: null
  });

  useEffect(() => {
    moment.locale('ar');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/Team/dropdown');
        const tempTeams = response.data.data.map((team) => ({
          id: team.id,
          name: team.name
        }));
        setTeamDropdownData(tempTeams);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { workingGroupId, FromDate } = formState;
      if (workingGroupId && FromDate) {
        try {
          const formattedDate = FromDate.toISOString().split('T')[0];
          const [EmployeeRes, LocationsRes] = await Promise.all([
            axiosInstance.get(
              `/EmployeesDailyLocationsAssignment/Employee/dropdown/${workingGroupId}/${formattedDate}`
            ),
            axiosInstance.get(`/EmployeesDailyLocationsAssignment/Location/dropdown/${workingGroupId}/${formattedDate}`)
          ]);
          setDropdownData({
            EmployeeDd: EmployeeRes.data.data,
            LocationDd: LocationsRes.data.data
          });
        } catch (error) {
          console.error('Error fetching dropdown data:', error);
        }
      }
    };

    fetchDropdownData();
  }, [formState.workingGroupId, formState.FromDate]);

  const handelSearch = async () => {
    const { workingGroupId, FromDate, EmployeeID, LocationsID } = formState;
    if (workingGroupId && FromDate) {
      try {
        const formattedDate = FromDate.toISOString().split('T')[0];
        const response = await axiosInstance.get(
          `/EmployeesDailyLocationsAssignment/employeeTeamAssignmentTable/${workingGroupId}/${formattedDate}?employeeIDs=${EmployeeID}&locationIDs=${LocationsID}`
        );
        setTableData(response.data.data);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
    }
  };

  const handleDropDownChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.value || e.target.value
    }));
  };

  const cardData = useMemo(
    () => (location, employee, team) => (
      <div className='flex flex-column gap-8 border-3 border-solid border-blue-600 border-round-lg p-2 text-base bold bg-blue-50 mb-2 ml-2'>
        <div className='upper'>
          <span
            style={{
              flexDirection: 'row',
              gap: '10px',
              color: '#4D5E80',
              fontWeight: '700',
              display: 'inline-block'
            }}
          >
            <img src='/images/location.svg' alt='location' style={{}} />
            {location}
          </span>
        </div>
        <div className='lower'>
          <span style={{ color: '#4D5E80', fontWeight: '700' }}>{employee}</span>
          <br />
          <br />
          <span
            style={{
              border: '1px solid #FFB200',
              borderRadius: '1.375rem',
              padding: '0.125rem 0.75rem',
              marginTop: '5px',
              backgroundColor: 'rgba(242, 201, 76, 0.12)',
              fontWeight: '700',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {team}
          </span>
        </div>
      </div>
    ),
    []
  );

  const getListData = useMemo(
    () => (value) => {
      const currentDate = value.format('YYYY-MM-DD');
      return (
        tableData
          ?.filter((data) => data.date === currentDate)
          .map((item) => ({
            type: '',
            content: cardData(`${item.locationName} - ${item.areaName}`, item.employeeName, item.teamName)
          })) || []
      );
    },
    [tableData, cardData]
  );

  const CalenderTable = () => {
    const defaultValue = useMemo(() => {
      return formState.FromDate
        ? dayjs(formState.FromDate.toISOString().split('T')[0], 'YYYY-MM')
        : dayjs(new Date().toISOString().split('T')[0], 'YYYY-MM');
    }, [formState.FromDate]);

    const dateCellRender = (value) => {
      const listData = getListData(value);
      return (
        <div className='events'>
          {listData.map((item, index) => (
            <span key={index}>{item.content}</span>
          ))}
        </div>
      );
    };

    return (
      // <div className='custom-calendar'>
      <ConfigProvider locale={ar_EG} direction='rtl'>
        <Calendar
          cellRender={(current, info) => (info.type === 'date' ? dateCellRender(current) : info.originNode)}
          value={defaultValue}
        />
      </ConfigProvider>
      // </div>
    );
  };

  const SearchFields = () => (
    <div className='grid mb-6'>
      <div className='col'>
        <div className='flex flex-row gap-2 w-full mb-4'>
          <div className='flex flex-column gap-2 w-full'>
            <span className='text-lg font-bold'>
              بحث بمجموعة العمل<span style={Style2}>*</span>
            </span>
            <CustomDropdown
              name='workingGroupId'
              value={formState.workingGroupId}
              onChange={(e) => setFormState({ ...formState, workingGroupId: e.value })}
              options={teamDropdownData}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر مجموعة العمل'
              className='w-full'
            />
          </div>
          <div className='flex flex-column gap-2 w-full'>
            <span className='text-lg font-bold'>
              بحث بالتاريخ<span style={Style2}>*</span>
            </span>
            <PrimeCalender
              name='FromDate'
              dateFormat='mm/dd/yy'
              value={formState.FromDate}
              onChange={(e) => setFormState({ ...formState, FromDate: e.value })}
              showIcon
              className='w-full text-base'
            />
          </div>
        </div>

        <div className='flex flex-row gap-2 w-full mb-4'>
          <div className='flex flex-column gap-2 w-full'>
            <span className='text-lg font-bold'>
              بحث عن الموظف بالاسم<span style={Style2}>*</span>
            </span>
            <MultiSelect
              name='EmployeeID'
              value={formState.EmployeeID}
              options={dropdownData.EmployeeDd}
              onChange={handleDropDownChange}
              optionValue='id'
              optionLabel='name'
              className='w-full'
              placeholder='اختر الموظف'
              disabled={!formState.workingGroupId || !formState.FromDate}
            />
          </div>
          <div className='flex flex-column gap-2 w-full'>
            <span className='text-lg font-bold'>
              اختر المواقع للفنى<span style={Style2}>*</span>
            </span>
            <MultiSelect
              name='LocationsID'
              value={formState.LocationsID}
              options={dropdownData.LocationDd}
              onChange={handleDropDownChange}
              optionValue='id'
              optionLabel='name'
              className='w-full'
              placeholder='اختر الموقع'
              disabled={!formState.workingGroupId || !formState.FromDate}
            />
          </div>
        </div>
      </div>
      <div className='col-fixed'>
        <Button
          className='tbl__visit__addbtn mr-6 mt-8'
          style={style1}
          onClick={handelSearch}
          disabled={!formState.workingGroupId || !formState.FromDate || !formState.EmployeeID || !formState.LocationsID}
        >
          بحث
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Card style={{ width: 'auto', margin: '1.5rem', borderRadius: '1.5rem' }} className={'tableCard'}>
        <SearchFields />
        <CalenderTable />
      </Card>
    </>
  );
}

export default EmployeeDailyAssignmentTable;

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiState } from '../../../hooks/useApiState';
import { useToast } from '../../../context/ToastContext';
import { axiosInstance } from '../../../global';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import GenericDataTable, {
  GenericDataTableHandle
} from '../../../components/components/GenericDataTable/GenericDataTable';
import SalarySearchSection from './SalarySearchSection';
import { Card } from 'antd';
import { TableHeader } from '../../../components/components/GenericPage/TableHeader';
import { Column } from 'primereact/column';
import * as XLSX from 'xlsx';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

const covenantSchema = yup.object({
  employeeId: yup.number().notRequired().nullable(),
  month: yup.string().required(''),
  data: yup.array()
});

function Salaries() {
  const toast = useToast();
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setSearchData] = useState('');

  const methods = useForm({
    resolver: yupResolver(covenantSchema),
    mode: 'all',
    defaultValues: {}
  });

  const {
    trigger,
    watch,
    setValue,
    control,
    formState: { errors }
  } = methods;

  const fetchSalaries = () => {
    const date = new Date(watch('month'));
    const convertedData = moment(date).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]');
    const year = moment(convertedData).year();
    const month = moment(convertedData).month() + 1;

    return axiosInstance.get(
      'FinanceEmployeePayroll/employeePayrolls', {
        params: {
          year: year,
          month: month,
          employeeId: watch('employeeId')
        }
      });
  };

  const { lazyApiCall: getSalaryApiCall, state: salaryState } = useApiState({
    apiCall: fetchSalaries,
    isLazy: true
  });
  const handleSearchOnClick = async () => {
    const isValid = await trigger(['month']);
    const validationCollection = watch('month') && isValid;
    if (validationCollection) {
      await getSalaryApiCall();
    } else {
      toast.current.show({
        type: 'error',
        summary: 'خطأ في التحقق',
        detail: 'يرجى التأكد من إدخال جميع الحقول المطلوبة',
        life: 5000
      });
    }
  };

  useEffect(() => {
    if (salaryState.error) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: salaryState.error.message ?? 'حدث خطأ أثناء البحث، يرجى المحاولة مرة أخرى',
        life: 5000
      });
    }

  }, [salaryState.error]);


  const tableRef = useRef<GenericDataTableHandle>(null);

  useEffect(() => {
    if (salaryState?.data?.data) {
      setFilteredData(salaryState.data.data);
      setValue('data', salaryState.data.data);
      if (tableRef.current) {
        tableRef.current.updateData(salaryState.data.data);
      }
    }
  }, [salaryState?.data?.data, filteredData]);

  const handleRowToggle = (rowData: any) => {
    if (tableRef.current) {
      tableRef.current.toggleRowExpansion(rowData);
    }
  };

  const allowSalaryExpansion = useCallback((rowData: any) => {
    return Array.isArray(rowData?.payrollItems) && rowData.payrollItems.length > 0;
  }, []);

  const salaryRowExpansionTemplate = useCallback((rowData: any) => {
    const payrollItems = (rowData.payrollItems || []);
    return (
      <div className="p-3">
        <DataTable value={payrollItems}>
          <Column field="payrollItemName" header="نوع الراتب" />
          <Column field="amount" header="المبلغ" />
          <Column field="remarks" header="ملاحظات" />
        </DataTable>
      </div>
    );
  }, []);


  const handleSearch = (searchTerm: string) => {
    setSearchData(searchTerm);
    const data = salaryState?.data?.data || [];
    const filtered = data.filter((item: {
        employeeName: string;
        branchName: string;
        positionName: string;
        employeeCode: string
      }) =>
        item.employeeName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.employeeCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.branchName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.positionName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleDownload = () => {
    try {
      // Prepare the data for export
      const dataToExport = filteredData.flatMap((item: any) => {
        // Flatten the payrollItems for each employee
        return item.payrollItems.map((payrollItem: any) => ({
          Branch: item.branchName,
          'Employee Code': item.employeeCode,
          'Employee Name': item.employeeName,
          'Position Name': item.positionName,
          Salary: item.salary,
          'Payroll Item Name': payrollItem.payrollItemName,
          'Amount': payrollItem.amount,
          'Remarks': payrollItem.remarks
        }));
      });

      // Create a new workbook and add the data
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Salaries');

      // Generate and trigger the download
      XLSX.writeFile(workbook, 'Salaries.xlsx');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'خطأ في التصدير',
        detail: 'حدث خطأ أثناء تصدير البيانات، يرجى المحاولة مرة أخرى',
        life: 5000
      });
    }
  };

  useEffect(() => {
    if(watch('month')){
      setFilteredData([]);
      setValue('data', []);
      if (tableRef.current) {
        tableRef.current.updateData([]);
      }
    }
  }, [watch('month')]);

  const saveSalaries = async () => {
    const date = new Date(watch('month'));
    const convertedData = moment(date).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]');
    const year = moment(convertedData).year();
    const month = moment(convertedData).month() + 1;
    const data = watch('data') ?? [];
    debugger;
    if (data?.length > 0) {
      await axiosInstance.post(`FinanceEmployeePayroll/monthlyEmployeesSalary?year=${year}&month=${month}`).then(() => {
        setFilteredData([]);
        setValue('data', undefined);
        if (tableRef.current) {
          tableRef.current.updateData([]);
        }
        toast.current.show({
          type: 'success',
          summary: 'تم الحفظ',
          detail: 'تم حفظ البيانات بنجاح',
          life: 5000
        });
      }).catch((error) => {
        toast.current.show({
          type: 'error',
          summary: 'خطأ',
          detail: error.message || 'حدث خطأ أثناء الحفظ',
          life: 5000
        });
      });
    } else {
      toast.current.show({
        type: 'error',
        summary: 'خطأ',
        detail: 'لا توجد بيانات لحفظها',
        life: 5000
      });
    }
  };

  return (
    <GenericPage
      tabs={[{
        tableHeader: 'الرواتب',
        columns: [],
        label: 'الرواتب',
        tabContent: <>
          <SalarySearchSection
            control={control}
            errors={errors}
            handleSearchOnClick={handleSearchOnClick}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <TableHeader
              title={'رواتب الموظفين'}
              onSearch={handleSearch}
              searchTerm={searchData}
              onDownload={handleDownload}
            />
            <GenericDataTable
              ref={tableRef}
              paginator={false}
              key={'Salaries'}
              data={filteredData}
              columns={[
                { field: 'branchName', header: 'الفرع', sortable: false },
                { field: 'employeeCode', header: 'كود الموظف', sortable: false },
                { field: 'employeeName', header: 'الموظف', sortable: false },
                { field: 'positionName', header: 'الدرجة الوظيفيه', sortable: false },
                { field: 'salary', header: 'الراتب', sortable: false }
              ]}
              page={1}
              pageSize={filteredData.length}
              totalRecords={filteredData.length}
              sortField={undefined}
              sortOrder={undefined}
              isLoading={salaryState.isLoading}
              onPageChange={() => {
              }}
              onSort={() => {
              }}
              onRowClick={() => {
              }}
              edit={false}
              entityName={''}
              emptyStateButtonClick={() => {
              }}
              emptyStateUrl={''}
              rowClickable={false}
              isColumnHidden={true}
              allowExpansion={allowSalaryExpansion}
              rowExpansionTemplate={salaryRowExpansionTemplate}
              onRowToggle={handleRowToggle}
            />
          </Card>

        </>,
        headerButtons: [{
          label: 'حفظ واغلاق',
          onClick: () => {
            saveSalaries();
          }
        }],

        withoutSearchBar: true,
        emptyStateButtonClick: () => {
        },
        entityName: 'salaries',
        emptyStateUrl: '',
        dataKey: 'WorkingTable',
        apiUrl: '',
        rowOnClick: () => {
        },
        edit: false
      }]}
      headerIcon={'/images/coins.svg'}
      headerTitle={'رواتب الموظفين'}
    />
  );
}

export default Salaries;


import React, { useState, useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';
import useFetch from '../../../../hooks/useFetch';
import { axiosInstance } from '../../../../global';
import { useApiState } from '../../../../hooks/useApiState';

const CustomerDeviceForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const customerID = watch('customerId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Product');

  const { data: customerData, isLoading: isCustomerLoading } = useFetch('/Customer/dropdown');
  const { data: deviceTypeData, isLoading: isDeviceTypeLoading } = useFetch('/CustomersDevicesType/dropdown');
  const { data: customerDeviceData, isLoading: isCustomerDeviceLoading } = useFetch(
    '/Product/allByProductTypeDropdown/2'
  );

  const fetchCustomerAddress = async () => {
    const response = await axiosInstance.get(`/CustomersAddress/allByCustomerDropdown/${customerID}`);
    return response.data;
  };

  const { lazyApiCall, state } = useApiState({ apiCall: fetchCustomerAddress, isLazy: true });

  useEffect(() => {
    if (customerID) {
      lazyApiCall();
    }
  }, [customerID]);

  useEffect(() => {
    if (!isCustomerLoading) {
      setValue('customerId', customerID);
    }
  }, [isCustomerLoading]);

  return (
    <>
      <CustomFormField
        name={'customerId'}
        label={isEditMode ? 'اسم العميل' : 'اختر العميل'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={customerData?.data ?? []}
        disabled={extraProps?.isDisabled}
        loading={isCustomerLoading}
      />
      <CustomFormField
        name={'typeId'}
        label={'اختر نوع الجهاز'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={deviceTypeData?.data ?? []}
        loading={isDeviceTypeLoading}
      />
      <CustomFormField
        name={'deviceId'}
        label={'اختر الجهاز'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={customerDeviceData?.data ?? []}
        loading={isCustomerDeviceLoading}
      />
      <CustomFormField
        name={'customerAddressId'}
        label={'اختر موقع العميل'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={state?.data?.data ?? []}
        disabled={!customerID}
        loading={state?.isLoading}
      />
      <CustomFormField
        name={'membraneSerialNb'}
        label={'رقم الممبرين'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='رقم الممبرين'
      />
      <CustomFormField
        name={'motorSerialNb'}
        label={'رقم المتور'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='رقم المتور'
      />
      <CustomFormField
        name={'installationDate'}
        label={'تاريخ التركيب'}
        control={control}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField
        name={'warrantyMonths'}
        label={'عدد شهور الضمان'}
        control={control}
        errors={errors}
        type={'decimal'}
        placeholder='عدد شهور الضمان'
      />
      <CustomFormField
        name={'expiryDate'}
        label={'تاريخ الانتهاء'}
        control={control}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField
        name={'statusId'}
        label={`اختر حالة المنتج`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        options={statusData?.data ?? []}
        loading={isStatusLoading}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default CustomerDeviceForm;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function InvoiceScreen() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'اجمالي المديونية',
      num: '1528888 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#F9D8D433'
    },
    {
      title: 'اجمالي الايرادات الكلية',
      num: '10000000 ج.م',
      imageUrl: '/images/coins.svg',
      color: '#6D54DE33'
    },
    {
      title: 'الفرع',
      num: 'فرع 1',
      imageUrl: '/images/branch.svg',
      color: '#FFEDD0'
    }
  ];

  const generateColumnsTab2 = (headerName: string, entityName: string) => [
    {
      header: 'رقم الفاتورة',
      field: 'invoiceNb',
      isChip: false
    },
    {
      header: 'تاريخ الفاتورة',
      field: 'creationDate',
      isDate: true
    },
    {
      header: headerName,
      field: entityName,
      isChip: false
    },
    {
      header: 'المبلغ الكلى للفتورة',
      field: 'totalNetAmount',
      isChip: false
    },
    {
      header: 'الكمية',
      field: 'totalItemsCount',
      isChip: false
    },
    {
      header: 'نسبة الخصم',
      field: 'totalDiscountPercent',
      isChip: false
    },
    {
      header: 'المبلغ المتبقى',
      field: 'totalRemainingAmount',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    }
  ];
  const columnsTab1 = [
    {
      header: 'رقم الفاتورة',
      field: 'invoiceNb',
      isChip: false
    },
    {
      header: 'نقطة البيع',
      field: 'pointOfSalesName',
      isChip: false
    },
    {
      header: 'تاريخ الفاتورة',
      field: 'creationDate',
      isDate: true
    },
    {
      header: 'العميل',
      field: 'customerName',
      isChip: false
    },
    {
      header: 'المرجع',
      field: 'reference',
      isChip: false
    },
    {
      header: 'المبلغ الكلى للفتورة',
      field: 'totalNetAmount',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    }
    // {
    //   header: 'منشئ الفاتورة',
    //   field: 'creatorUserName',
    //   isChip: true
    // }
  ];
  const columnsTab2 = [
    {
      header: 'رقم الفاتورة',
      field: 'invoiceNb',
      isChip: false
    },
    {
      header: 'تاريخ الفاتورة',
      field: 'creationDate',
      isDate: true
    },
    {
      header: 'العميل',
      field: 'customerName',
      isChip: false
    },
    {
      header: 'المرجع',
      field: 'reference',
      isChip: false
    },
    {
      header: 'المبلغ الكلى للفتورة',
      field: 'totalNetAmount',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    }
    // {
    //   header: 'منشئ الفاتورة',
    //   field: 'creatorUserName',
    //   isChip: true
    // }
  ];
  return (
    <GenericPage
      tabs={[
        {
          isHidden: false,
          label: 'فواتير الموردين',
          tableHeader: 'قائمة الفواتير',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/3',
          edit: false,
          columns: generateColumnsTab2('المورد', 'supplierName'),
          emptyStateUrl: '',
          entityName: 'الفاتورة',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, supplierId: rowData.supplierID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة موردين جدد',
              onClick: () => {
                navigate('/finance/supplier/add');
              }
            },
            {
              label: 'اضافة فاتورة للمورد',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 3
                  }
                });
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'فواتير المصروفات',
          tableHeader: 'قائمة الفواتير',
          dataKey: 'tab2',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/4',
          edit: false,
          columns: generateColumnsTab2('المورد', 'supplierName'),
          emptyStateUrl: '',
          entityName: 'الفاتورة',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, supplierId: rowData.supplierID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة فاتورة مصروفات',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 4
                  }
                });
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'فواتير الزيارات',
          tableHeader: 'قائمة الفواتير',
          dataKey: 'tab3',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/1',
          edit: false,
          columns: columnsTab2,
          emptyStateUrl: '',
          entityName: 'الفاتورة',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, customerId: rowData.customerID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة فاتورة مصروفات',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 4
                  }
                });
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'فواتير الفروع',
          tableHeader: 'قائمة الفواتير',
          dataKey: 'tab4',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/5',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'الفاتورة',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, customerId: rowData.customerID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة فاتورة مصروفات',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 4
                  }
                });
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'فواتير البيع المباشر',
          tableHeader: 'قائمة الفواتير',
          dataKey: 'tab5',
          emptyStateButtonClick: () => {},
          apiUrl: '/Invoice/list/byTypeId/2',
          edit: false,
          columns: columnsTab2,
          emptyStateUrl: '',
          entityName: 'الفاتورة',
          rowOnClick: (rowData) => {
            navigate('/finance/invoice/details', {
              state: { invoiceId: rowData.id, customerId: rowData.customerID }
            });
          },
          headerButtons: [
            {
              label: 'اضافة فاتورة مصروفات',
              onClick: () => {
                navigate('/finance/invoice/add', {
                  state: {
                    type: 4
                  }
                });
              }
            }
          ]
        }
      ]}
      headerTitle={'الحسابات'}
      headerIcon={'../images/coins.svg'}
      analyticsCards={headerDetails}
      cardsNum='3'
    />
  );
}

export default InvoiceScreen;

import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { useToast } from '../../../context/ToastContext';
// import styles from './AddEditInvoice.module.scss';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';

interface POSBasicInfoStepProps {
  isEditing: boolean;
}

const POSBasicInfoStep = ({ isEditing }: POSBasicInfoStepProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const _ = useToast();
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Sales');
  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');
  const { data: warehouseData, isLoading: isWarehouseLoading } = useFetch('/Warehouse/dropdown/forPointOfSales');
  const { data: cashBoxData, isLoading: isCashBoxLoading } = useFetch('/CashBox/dropdown');

  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Right side - Basic Invoice Info */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>بيانات منفذ البيع</p>}
              style={{
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <CustomFormField
              control={control}
              name={'name'}
              customStyle={'pt-4'}
              label={'اسم منفذ البيع'}
              errors={errors}
              type={'text'}
              required
            />
            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'responsibleEmployeeId'}
              label={'اختر الموظف المسؤول'}
              errors={errors}
              type={'dropdown'}
              options={employeeData?.data ?? []}
              disabled={isEmployeeLoading}
              required
            />

            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}> الخزنة</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'cashBoxId'}
              label={'اختر الخزنة'}
              errors={errors}
              type={'dropdown'}
              options={cashBoxData?.data ?? []}
              disabled={isCashBoxLoading}
              required
            />

            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>المخازن التابعة</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'warehouseId'}
              label={'اختر المخزن التابعة لمنفذ البيع'}
              errors={errors}
              type={'dropdown'}
              options={warehouseData?.data ?? []}
              disabled={isWarehouseLoading}
              required
            />
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>بيانات التواصل</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <CustomFormField
              control={control}
              name={'mobile'}
              customStyle={'pt-4'}
              label={'رقم الهاتف المحمول'}
              errors={errors}
              type={'text'}
              required
            />
            <CustomFormField
              control={control}
              name={'phone'}
              customStyle={'pt-4'}
              label={'رقم الهاتف الارضى'}
              errors={errors}
              type={'text'}
            />
          </div>

          {/* Left side */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold '}>اخرى</p>}
              style={{
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'remarks'}
              label={'ملاحظات اخرى'}
              errors={errors}
              type={'textarea'}
            />

            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold '}>حالة منفذ البيع</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />
            <CustomFormField
              name={'statusId'}
              label={`حالة الحساب`}
              required={true}
              control={control}
              errors={errors}
              type={'dropdown'}
              placeholder={'اختر الحاله'}
              options={statusData?.data ?? []}
              loading={isStatusLoading}
              customStyle='pt-4'
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default POSBasicInfoStep;

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './style/reset.scss';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UsersAndEmployees from './pages/Lists/UsersAndEmployees/UsersAndEmployees';
import Location from './pages/Lists/Locations/Location';
import Products from './pages/Lists/Products/Products';
import General from './pages/Lists/General/General';
import Client from './pages/Lists/Client/Client';
import VacationRequest from './pages/Employees/vacationRequest/VacationRequest';
import Details from './pages/Employees/Details/Details';
import Employees from './pages/Employees/employeeScreen/Employees';
import ProductsScreen from './pages/Products/ProductScreen/ProductsScreen';
import WarehousesLists from './pages/Lists/Warehouses/WarehousesLists';
import SpareScreen from './pages/Products/SpareScreen/SpareScreen';
import ProductsDetails from './pages/Products/Details/ProductsDetails';
import StagesFromDetails from './components/Stages/StagesFromDetails';
import PriceList from './pages/Products/PriceList/PriceList';
import InstallmentList from './pages/Products/Installment/InstallmentList';
import ServicesList from './pages/Products/Services/ServicesList';
import Warehouses from './pages/Warehouses/Warehouses';
import WarehouseDetails from './pages/Warehouses/Details/WarehouseDetails';
import PayrollList from './pages/Employees/Payroll/PayrollList';
import PriceListEditDetails from './pages/Products/PriceList/PriceListEditDetails';
import Transactions from './pages/Warehouses/Transactions';
import ActivitesScreen from './pages/Visits/ActivitesScreen/ActivitesScreen';
import AddConnection from './pages/Visits/AddVisit/AddConnection/AddConnection';
import AddConnection1 from './pages/Visits/AddVisit/AddConnection/AddConnection1';
import AddEditVisit from './pages/Visits/AddVisit/AddRealVisit/AddEditVisit';
import { WarehouseTransactionsMatrixList } from './pages/AdminLists/Warehouse/WarehouseTransactionsMatrixList';
import { SystemParamsList } from './pages/AdminLists/SystemParams/SystemParamsList';
import './style/custom.scss';
import ServicesListDetails from './pages/Products/Services/ServicesListDetails';
import ServicesListEditDetails from './pages/Products/Services/ServicesListEditDetails';
import CustomerScreen from './pages/Customers/CustomerScreen/CustomerScreen';
import AddCustomer from './pages/Customers/AddCustomers/AddCustomer';
import CustomersDetails from './pages/Customers/Details/CustomersDetails';
import EmployeesManagement from './pages/Employees/EmployeesManagement/EmployeesManagement';
import AddIssuing from './pages/Warehouses/AddTransaction/AddIssuing';
import AddReceiving from './pages/Warehouses/AddTransaction/AddReceiving';
import Suppliers from './pages/Warehouses/Suppliers';
import AddSupplier from './pages/Warehouses/AddSupplier/AddSupplier';
import CustomerGeoLocation from './pages/Customers/CustomerLists/CustomerGeoLocation';
import CustomerDevices from './pages/Customers/CustomerLists/CustomerDevices';
import CustomerComplaints from './pages/Customers/CustomerLists/CustomerComplaints';
import SupplierDetails from './pages/Warehouses/SupplierDetails/SupplierDetails';
import EditSupplier from './pages/Warehouses/EditSupplier/EditSupplier';
import TransactionDetails from './pages/Warehouses/TransactionDetails/TransactionDetails';
import DetailsSheet from './components/DetailsSheet/DetailsSheet';
import AddEditTransaction from './pages/Warehouses/EditTransaction/AddEditTransaction';
import VisitsGeneralList from './pages/Lists/VisitsGeneralList/VisitsGeneralList';
import FollowUpScreen from './pages/Visits/FollowUpScreen/FollowUpScreen';
import VisitsScreen from './pages/Visits/VisitsScreen/VisitsScreen';
import AddEditEmployee from './pages/Employees/AddEditEmployee/AddEditEmployee';
import EmployeesWorkingGroupDetails from './pages/Employees/EmployeesManagement/EmployeesWorkingGroupDetails';
import NotFound from './pages/NotFound/NotFound';
import ContractInstallments from './pages/Contracts/ContractLists/ContractInstallments';
import ContractScreen from './pages/Contracts/ContractScreen/ContractScreen';
import AddActivity from './pages/Contracts/AddEditContract/AddActivity/AddActivity';
import AddEditContract from './pages/Contracts/AddEditContractTs/AddEditContract';
import MainLayout from './components/Layout/MainLayout';
import ServiceListDetails from './pages/Products/Services/ServiceListDetails';
import ProductListDetails from './pages/Products/PriceList/ProductListDetails';
import AddEditProduct from './pages/Products/AddEditProduct/AddEditProduct';
import ResetPassword from './pages/Login/ResetPassword';
import WaitingCustomrList from './pages/Customers/CustomerScreen/WaitingCustomerList';
import AdminLists from './pages/AdminLists/AdminLists';
import StageScreen from './pages/Products/StageScreen/StageScreen';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Loan from './pages/Employees/EmployeeLoan/Loan';
import { ToastProvider } from './context/ToastContext';
import MaintenanceContractScreen from './pages/Contracts/ContractScreen/MaintenanceContractScreen';
import ContractDetails from './pages/Contracts/ContractDetails/ContractDetails';
import AddEditWarehouse from './pages/Warehouses/AddEditWarehouse/AddEditWarehouse';
import CustomerServiceList from './pages/Lists/CustomerService/CustomerServiceList';
import EvaluationScreen from './pages/CustomerService/EvaluationScreen/EvaluationScreen';
import EvaluationScreenHistory from './pages/CustomerService/EvaluationScreenHistory/EvaluationScreenHistory';
import CustomerServiceDetails from './pages/CustomerService/CustomerServiceDetails/CustomerServiceDetails';
import CustomerStatistics from './pages/Customers/Statistices/Statistics';
import AccountingList from './pages/Lists/Accounting/AccountingList';
import InvoiceScreen from './pages/Finance/InvoiceScreen/InvoiceScreen';
import SuppliersTransactionScreen from './pages/Finance/SuppliersTransactionScreen/SuppliersTransactionScreen';
import AddEditInvoice from './pages/Finance/AddEditInvoice/AddEditInvoice';
import InvoiceDetails from './pages/Finance/Details/InvoiceDetails';
import AccountingTransactionScreen from './pages/Finance/AccountingTransactionScreen/AccountingTransactionScreen';
import AccountTransactionDetails from './pages/Finance/Details/AccountTransactionDetails';
import ExpensesScreen from './pages/Finance/ExpensesScreen/ExpensesScreen';
import AddEditAccountTransaction from './pages/Finance/AddEditAccountTransaction/AddEditAccountTransaction';
import PendingCashBoxTrans from './pages/Finance/PendingCashBoxTrans/PendingCashBoxTrans';
import PointofSales from './pages/Sales/PointofSalesScreen/PointofSalesScreen';
import AddEditPointOfSales from './pages/Sales/AddEditPointOfSales/AddEditPointOfSales';
import POSDetails from './pages/Sales/Details/POSDetails';
import SalesOrderScreen from './pages/Sales/SalesOrderScreen/SalesOrderScreen';
import SalesOrderDetails from './pages/Sales/Details/SalesOrderDetails';
import AddEditSalesOrder from './pages/Sales/AddEditSalesOrder/AddEditSalesOrder';
import AddCovenant from './pages/Warehouses/AddConvenant/AddCovenant';
import CustodyScreen from './pages/Finance/CustodyScreen/CustodyScreen';
import DirectSalesScreen from './pages/Sales/DirectSalesScreen/DirectSalesScreen';
import RecieveCovenant from './pages/Warehouses/RecieveCovenant/RecieveCovenant';
import MenuAccessScreen from './pages/AdminLists/MenuAccess/MenuAccessScreen';
import Unauthorized from './pages/NotFound/Unauthorized';
import Salaries from './pages/Finance/Salaries/Salaries';
import LoansScreen from './pages/Finance/LoansScreen/LoansScreen';

function App() {
  return (
    <ToastProvider>
      <ConfirmDialog />
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;

function MainRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route element={<MainLayout />}>
        <Route
          exact
          path='/'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        {/************** Lists Module  **************/}
        <Route
          path='/lists/users-and-employees'
          element={
            <ProtectedRoute>
              <UsersAndEmployees />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/client'
          element={
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/locations'
          element={
            <ProtectedRoute>
              <Location />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/products'
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/warehouses'
          element={
            <ProtectedRoute>
              <WarehousesLists />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/general'
          element={
            <ProtectedRoute>
              <General />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lists/CustomerService'
          element={
            <ProtectedRoute>
              <CustomerServiceList />
            </ProtectedRoute>
          }
        />
        {/************** Employee Module  **************/}
        <Route
          path='/employees/vacationRequest'
          element={
            <ProtectedRoute>
              <VacationRequest />
            </ProtectedRoute>
          }
        />

        <Route
          path='/employees/payroll'
          element={
            <ProtectedRoute>
              <PayrollList />
            </ProtectedRoute>
          }
        />

        <Route
          path='/employees/loan'
          element={
            <ProtectedRoute>
              <Loan />
            </ProtectedRoute>
          }
        />

        <Route
          path='/employees/details/:id'
          element={
            <ProtectedRoute>
              <Details />
            </ProtectedRoute>
          }
        />
        <Route
          path='/employees/edit/:id'
          element={
            <ProtectedRoute>
              <AddEditEmployee />
            </ProtectedRoute>
          }
        />
        <Route
          path='/employees/'
          element={
            <ProtectedRoute>
              <Employees />
            </ProtectedRoute>
          }
        />
        <Route
          path='/employees/add'
          element={
            <ProtectedRoute>
              <AddEditEmployee />
            </ProtectedRoute>
          }
        />
        <Route
          path='/employees/management'
          element={
            <ProtectedRoute>
              <EmployeesManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path='/employees/management/working-groups/details/:id'
          element={
            <ProtectedRoute>
              <EmployeesWorkingGroupDetails />
            </ProtectedRoute>
          }
        />
        {/************** Customers Module  **************/}
        <Route
          path='/customers/'
          element={
            <ProtectedRoute>
              <CustomerScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/customers/Statistics'
          element={
            <ProtectedRoute>
              <CustomerStatistics />
            </ProtectedRoute>
          }
        />

        <Route
          path='/customers/awating'
          element={
            <ProtectedRoute>
              <WaitingCustomrList status={'Awaiting'} />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/addCustomer'
          element={
            <ProtectedRoute>
              <AddCustomer />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/editCustomer/:id'
          element={
            <ProtectedRoute>
              <AddCustomer />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/details/:id'
          element={
            <ProtectedRoute>
              <CustomersDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/GeoLocation'
          element={
            <ProtectedRoute>
              <CustomerGeoLocation />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/Devices'
          element={
            <ProtectedRoute>
              <CustomerDevices />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customers/Complaints'
          element={
            <ProtectedRoute>
              <CustomerComplaints />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customer/Contract/details/:id/:customerId/:type'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customer/activity/details/:id/:customerId/:type'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customer/complaint/details/:id/:customerId/:type'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customer/followUp/details/:id/:customerId/:type'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />
        {/************** Products Module  **************/}
        <Route
          path='/products/'
          element={
            <ProtectedRoute>
              <ProductsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/product/details/:id/:custId/:type'
          element={
            <ProtectedRoute>
              <ProductsDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/product/details/addStage/:id/:stgNb'
          element={
            <ProtectedRoute>
              <StagesFromDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/products/addProduct'
          element={
            <ProtectedRoute>
              <AddEditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path='/products/addProduct/:id'
          element={
            <ProtectedRoute>
              <AddEditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path='/productSpares/'
          element={
            <ProtectedRoute>
              <SpareScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/products/addSpareParts'
          element={
            <ProtectedRoute>
              <AddEditProduct productType={'spare'} />
            </ProtectedRoute>
          }
        />
        <Route
          path='/productSpares/editSpareParts/:id'
          element={
            <ProtectedRoute>
              <AddEditProduct productType={'spare'} />
            </ProtectedRoute>
          }
        />
        <Route
          path='/products/addStages/'
          element={
            <ProtectedRoute>
              <AddEditProduct productType={'stages'} />
            </ProtectedRoute>
          }
        />

        <Route
          path='/products/stages'
          element={
            <ProtectedRoute>
              <StageScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='product/installments'
          element={
            <ProtectedRoute>
              <InstallmentList />
            </ProtectedRoute>
          }
        />
        <Route
          path='product/priceList'
          element={
            <ProtectedRoute>
              <PriceList />
            </ProtectedRoute>
          }
        />

        <Route
          path='/product/priceList/:id'
          element={
            <ProtectedRoute>
              <ProductListDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/product/priceList/edit/:id'
          element={
            <ProtectedRoute>
              <PriceListEditDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='product/services'
          element={
            <ProtectedRoute>
              <ServicesList />
            </ProtectedRoute>
          }
        />

        <Route
          path='/product/services/add'
          element={
            <ProtectedRoute>
              <ServicesListDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/product/services/:id'
          element={
            <ProtectedRoute>
              <ServiceListDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/product/services/edit/:id'
          element={
            <ProtectedRoute>
              <ServicesListEditDetails />
            </ProtectedRoute>
          }
        />
        {/************** Warehouses Module  **************/}
        <Route
          path='/warehouse/details/:id'
          element={
            <ProtectedRoute>
              <WarehouseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouse/edit'
          element={
            <ProtectedRoute>
              <AddEditWarehouse />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/'
          element={
            <ProtectedRoute>
              <Warehouses />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouse/add'
          element={
            <ProtectedRoute>
              <AddEditWarehouse />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/'
          element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/:type/add'
          element={
            <ProtectedRoute>
              <AddIssuing />
              {/* <AddEditTransaction /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/receiving/add'
          element={
            <ProtectedRoute>
              <AddReceiving />
              {/* <AddEditTransaction /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/covenant/add'
          element={
            <ProtectedRoute>
              <AddCovenant />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/covenant/recieve'
          element={
            <ProtectedRoute>
              <RecieveCovenant />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/details/:id'
          element={
            <ProtectedRoute>
              <TransactionDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/transactions/edit/:id'
          element={
            <ProtectedRoute>
              <AddEditTransaction />
            </ProtectedRoute>
          }
        />
        {/* <Route
              path='/warehouses/payment-terms/'
              element={
                <ProtectedRoute>
                  <PaymentTerms />
                </ProtectedRoute>
              }
            /> */}
        <Route
          path='/warehouses/suppliers/'
          element={
            <ProtectedRoute>
              <Suppliers />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/supplier/add'
          element={
            <ProtectedRoute>
              <AddSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/supplier/edit/:id'
          element={
            <ProtectedRoute>
              <EditSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path='/warehouses/supplier/details'
          element={
            <ProtectedRoute>
              <SupplierDetails />
            </ProtectedRoute>
          }
        />
        {/************** contract Module  **************/}
        <Route
          path='/Contract/Customer/Installments'
          element={
            <ProtectedRoute>
              <ContractInstallments />
            </ProtectedRoute>
          }
        />

        <Route
          path='/MaintenanceContract/'
          element={
            <ProtectedRoute>
              <MaintenanceContractScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Contract/'
          element={
            <ProtectedRoute>
              <ContractScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/:type/details/:id/:customerId'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />

        <Route
          path='/:type/AddConnection'
          element={
            <ProtectedRoute>
              <AddConnection1 />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Contract/:Contractstype/:contractId/:customerId/:deviceId'
          element={
            <ProtectedRoute>
              <AddEditContract />
            </ProtectedRoute>
          }
        />

        <Route
          path='/addContract'
          element={
            <ProtectedRoute>
              <AddEditContract />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/contract/details/'}
          element={
            <ProtectedRoute>
              <ContractDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Contract/Activity/Add/:contractId/:customerId/:deviceId'
          element={
            <ProtectedRoute>
              <AddActivity />
            </ProtectedRoute>
          }
        />
        {/************** visits Module  **************/}
        <Route
          path='/Activites/'
          element={
            <ProtectedRoute>
              <ActivitesScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Visits/'
          element={
            <ProtectedRoute>
              <VisitsScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Activites/addVisitConnection'
          element={
            <ProtectedRoute>
              <AddConnection />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Activites/:type/:customerId/:deviceId'
          element={
            <ProtectedRoute>
              <AddEditVisit />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Activites/:type/:activityId'
          element={
            <ProtectedRoute>
              <AddEditVisit />
            </ProtectedRoute>
          }
        />

        <Route
          path='/visit/:type/:visitId/:customerId/:deviceId'
          element={
            <ProtectedRoute>
              <AddEditVisit />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Visits/GeneralLists'
          element={
            <ProtectedRoute>
              <VisitsGeneralList />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Visits/followUp'
          element={
            <ProtectedRoute>
              <FollowUpScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path='/visits/details/:type/:id/:customerId'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />

        <Route
          path='/activities/details/:type/:id/:customerId'
          element={
            <ProtectedRoute>
              <DetailsSheet />
            </ProtectedRoute>
          }
        />
        {/************** customerService Module  **************/}
        <Route
          path='/customerService/EvaluationHistory'
          element={
            <ProtectedRoute>
              <EvaluationScreenHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customerService/Evaluation'
          element={
            <ProtectedRoute>
              <EvaluationScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/customerService/details'
          element={
            <ProtectedRoute>
              <CustomerServiceDetails />
            </ProtectedRoute>
          }
        />
        {/************** finance Module  **************/}
        <Route
          path='/Lists/Accounting'
          element={
            <ProtectedRoute>
              <AccountingList />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/suppliers'
          element={
            <ProtectedRoute>
              <SuppliersTransactionScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/invoices'
          element={
            <ProtectedRoute>
              <InvoiceScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/Expenses'
          element={
            <ProtectedRoute>
              <ExpensesScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/Accounting/salaries'}
          element={
            <ProtectedRoute>
              <Salaries />
            </ProtectedRoute>
          }
        />

        <Route
          path='/finance/supplier/add'
          element={
            <ProtectedRoute>
              <AddSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path='/finance/invoice/add'
          element={
            <ProtectedRoute>
              <AddEditInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path='/finance/invoice/edit'
          element={
            <ProtectedRoute>
              <AddEditInvoice />
            </ProtectedRoute>
          }
        />

        <Route
          path='/finance/transaction/add'
          element={
            <ProtectedRoute>
              <AddEditAccountTransaction />
            </ProtectedRoute>
          }
        />

        <Route
          path='/finance/transaction/pay'
          element={
            <ProtectedRoute>
              <AddEditAccountTransaction />
            </ProtectedRoute>
          }
        />

        <Route
          path='/finance/transaction/edit'
          element={
            <ProtectedRoute>
              <AddEditAccountTransaction />
            </ProtectedRoute>
          }
        />
        <Route
          path='/finance/supplier/details'
          element={
            <ProtectedRoute>
              <SupplierDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/finance/invoice/details'
          element={
            <ProtectedRoute>
              <InvoiceDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Accounting/dailyTransactions'
          element={
            <ProtectedRoute>
              <AccountingTransactionScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/dailyTransactions/details'
          element={
            <ProtectedRoute>
              <AccountTransactionDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Accounting/ApproveTransactions'
          element={
            <ProtectedRoute>
              <PendingCashBoxTrans />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/custody'
          element={
            <ProtectedRoute>
              <CustodyScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Accounting/Loans'
          element={
            <ProtectedRoute>
              <LoansScreen />
            </ProtectedRoute>
          }
        />

        {/************** Sales Module  **************/}
        <Route
          path='/Sales/PointofSales'
          element={
            <ProtectedRoute>
              <PointofSales />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/pointOfSales/add'
          element={
            <ProtectedRoute>
              <AddEditPointOfSales />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/pointOfSales/edit'
          element={
            <ProtectedRoute>
              <AddEditPointOfSales />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/pointOfSales/details'
          element={
            <ProtectedRoute>
              <POSDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path='/Sales/SalesOrder'
          element={
            <ProtectedRoute>
              <SalesOrderScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/SalesOrder/details'
          element={
            <ProtectedRoute>
              <SalesOrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/SalesOrder/Add'
          element={
            <ProtectedRoute>
              <AddEditSalesOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/SalesOrder/edit'
          element={
            <ProtectedRoute>
              <AddEditSalesOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Sales/DirectSales'
          element={
            <ProtectedRoute>
              <DirectSalesScreen />
            </ProtectedRoute>
          }
        />

        {/************** Admin Module  **************/}
        <Route
          path='/admin/systemParams'
          element={
            <ProtectedRoute>
              <SystemParamsList />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/warehouse'
          element={
            <ProtectedRoute>
              <WarehouseTransactionsMatrixList />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/list'
          element={
            <ProtectedRoute>
              <AdminLists />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/Admin/MenusAccess'}
          element={
            <ProtectedRoute>
              <MenuAccessScreen />
            </ProtectedRoute>
          }
        />
        <Route path='/unauthorized' element={<Unauthorized />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, ConfigProvider, Grid } from 'antd';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';

const { Sider, Content } = Layout;
const { useBreakpoint } = Grid;

function MainLayout() {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ConfigProvider direction="rtl">
      <Layout style={{ minHeight: '100vh' }}>
        {/* Navbar */}
        <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />

        <Layout>
          {/* Sidebar */}
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            breakpoint="lg"
            width={220}
            collapsedWidth={screens.lg ? 80 : 0}
            style={{ background: '#fff' }}
          >
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Sider>

          {/* Content */}
          <Content style={{ background: '#f0f2f5' }}>
            <Outlet />
          </Content>
        </Layout>

        {/* Footer */}
        <Footer />
      </Layout>
    </ConfigProvider>
  );
}

export default MainLayout;

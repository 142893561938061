import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import styles from './AddEditInvoice.module.scss';
import GenericEditableDataTable, {
  EditableColumnProps
} from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import useFetch from '../../../hooks/useFetch';
import CustomButton from '../../../components/components/Button/CustomButton';
import useProduct from '../../../hooks/useProduct';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import InvoiceDetailsCard from './components/InvoiceDetailsCard';

interface ProductRow {
  itemId: number | null;
  itemName: string | null;
  discountTypeTable: number | null;
  qty: number | null;
  price: number | null;
  discountPercent: number | null;
  discountAmount: number | null;
  totalPrice: number | null;
}

interface InvoiceItemsProps {
  isEditing: boolean;
  type: number;
}

const InvoiceItems = ({ isEditing, type }: InvoiceItemsProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const discountType = watch('discountType');
  const discountAmount = watch('discountOnProducts');
  const InvoiceCode = watch('invoiceReference');
  const ispayTotalInvoice = watch('payTotalInvoice');
  const totalAmountss = watch('totalAmount');
  const totalDiscountss = watch('mytotalDiscount');
  const reqAmount = watch('requstedAmount');
  const typeID = watch('typeId');
  const isEdit = watch('isEdit');


  const { data: cashBoxData, isLoading: isCashBoxLoading } = useFetch(`/CashBox/dropdown`);
  const { data: ExpensesData, isLoading: isExpensesLoading } = useFetch(`/ExpensesType/dropdown`);

  const { data: stageType, isLoading:isProductLoading } = useFetch('Product/dropdown');

  const [productTableData, setProductTableData] = useState<ProductRow[]>([]);
  const [firstTime, setfirstTime] = useState(false);
  const types = [
    { name: 'نسبة مئوية', id: 1 },
    { name: 'مبلغ', id: 2 }
  ];

  const addNewProductRow = () => {
    const newRow: ProductRow = {
      itemId: null,
      itemName: null,
      discountTypeTable: discountType,
      qty: null,
      price: null,
      discountPercent: discountType === 1 ? discountAmount : null,
      discountAmount: discountType === 2 ? discountAmount : null,
      totalPrice: null
    };
    setProductTableData((prevData) => [...prevData, newRow]);
  };

  const calculateTotalPrice = (row: ProductRow): number => {
    const totalPrice = (row.qty || 0) * (row.price || 0);
    if (row.discountTypeTable) {
      if (row.discountTypeTable === 1) {
        const percentage = row.discountPercent ? row.discountPercent / 100 : 0;
        const minusedAmount = totalPrice * percentage;
        return totalPrice - minusedAmount;
      } else {
        return totalPrice - (row.discountAmount ? row.discountAmount : 0);
      }
    } else {
      return totalPrice;
    }
  };

  const handleProductRowRemove = (index: number) => {
    setProductTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleProductRowUpdate = (updatedData: ProductRow[]) => {
    const updatedRows = updatedData.map((row) => {
      const options = type === 3 || typeID === 3 ? stageType?.data : ExpensesData?.data ?? [];
      const selectedItem = options.find((option: any) => option.id === row.itemId);

      return {
        ...row,
        itemName: selectedItem ? selectedItem.name : null, // Assign label to itemName
        totalPrice: calculateTotalPrice(row) // Calculate total price
      };
    });
    const totalRequestedAmount = updatedRows.reduce((sum, row) => sum + row.totalPrice, 0);
    const totalAmount = updatedRows.reduce((sum, row) => sum + (row.qty || 0) * (row.price || 0), 0);
    setProductTableData(updatedRows);
    setValue('items', updatedRows);
    setValue('requstedAmount', totalRequestedAmount);
    setValue('totalAmount', totalAmount);
    setValue('mytotalDiscount', totalAmount - totalRequestedAmount);
  };

  useEffect(() => {
    if (ispayTotalInvoice) {
      setValue('paidAmount', reqAmount);
    } else {
      setValue('paidAmount', 0);
    }
  }, [ispayTotalInvoice, reqAmount]);

  useEffect(() => {
    const productsStock = watch('items');
    if (productsStock) {
      setProductTableData(productsStock);
    }
  }, [watch('items')]);

  const productsColumn: EditableColumnProps[] = [
    {
      field: 'itemId',
      header: type === 3 || typeID === 3 ? ' المنتج' : 'مصروفات',
      editable: true,
      type: 'dropdown',
      options: type === 3 || typeID === 3 ? stageType?.data : ExpensesData?.data ?? [],
      idField: 'id',
      labelField: 'name'
    },

    // { field: 'itemName', header: 'المنتج', editable: false, type: 'text' },
    { field: 'qty', header: 'الكمية', editable: true, type: 'number' },
    { field: 'price', header: 'السعر', editable: true, type: 'number' },
    {
      field: 'discountPercent',
      header: 'الخصم المخصص (نسبة)',
      editable: true,
      type: 'number'
    },
    {
      field: 'discountAmount',
      header: 'الخصم المخصص (مبلغ)',
      editable: true,
      type: 'number'
    },
    {
      field: 'discountTypeTable',
      header: 'نوع الخصم',
      editable: true,
      type: 'dropdown',
      options: types ?? [],
      idField: 'id',
      labelField: 'name'
    },
    {
      field: 'totalPrice',
      header: 'السعر الكلى',
      editable: false,
      type: 'number'
    }
  ];

  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <Message
          severity='success'
          icon={null}
          content={
            <p className={'text-black-alpha-90 font-semibold'}>
              {type === 3 || typeID === 3 ? 'منتجات الفاتورة' : 'مصروفات الفاتورة'}
            </p>
          }
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />

        <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
          <label className={styles.detailsTitle}>
            {type === 3 || typeID === 3 ? `بيان منتجات الفاتورة` : `بيان مصروفات الفاتورة`}
          </label>
          <CustomButton
            label={type === 3 || typeID === 3 ? 'إضف منتج' : 'إضف مصروفات'}
            type={'button'}
            onClick={addNewProductRow}
          />
        </div>

        <GenericEditableDataTable<ProductRow>
          data={productTableData}
          columns={productsColumn}
          isLoading={isProductLoading}
          onRowUpdate={handleProductRowUpdate}
          onRowRemove={handleProductRowRemove}
        />

        <InvoiceDetailsCard
          code={InvoiceCode ?? InvoiceCode}
          date={new Date()}
          InvoiceReqAmount={reqAmount ? reqAmount : 0}
          InvoiceTotalAmount={totalAmountss ? totalAmountss : 0}
          totalDiscountAmount={totalDiscountss ? totalDiscountss : 0}
        />

        <Message
          severity='success'
          icon={null}
          content={<p className={'text-black-alpha-90 font-semibold'}>الدفع</p>}
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />
        <CustomFormField
          control={control}
          customStyle={'pt-4'}
          name={'paidAmount'}
          label={'المبلغ المراد دفعه للمورد'}
          errors={errors}
          type={'decimal'}
          max={reqAmount}
          required
          disabled={ispayTotalInvoice}
        />
        <CustomFormField
          control={control}
          customStyle={'pt-4'}
          name={'payTotalInvoice'}
          label={'دفع المبلغ بالكامل'}
          errors={errors}
          type={'checkbox'}
        />
        <CustomFormField
          control={control}
          customStyle={'pt-4'}
          name={'cashBoxId'}
          label={'اختر الخزنة'}
          errors={errors}
          type={'dropdown'}
          options={cashBoxData?.data ?? []}
          required
        />
        <CustomFormField
          control={control}
          name={'paidReference'}
          customStyle={'pt-4'}
          label={'الرقم المرجعي'}
          errors={errors}
          type={'text'}
          required
        />
      </Card>
    </>
  );
};

export default InvoiceItems;

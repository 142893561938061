import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Space, Tooltip, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Title } = Typography;

interface TableHeaderProps {
  title?: string;
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
  onDownload?: () => void;
  downloadLoading?: boolean;
  button?: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
}

export const TableHeader: React.FC<TableHeaderProps> = ({
                                                          title,
                                                          onSearch,
                                                          searchTerm,
                                                          onDownload,
                                                          button,
                                                          downloadLoading
                                                        }) => {
  const [searchCondition, setSearchCondition] = useState(searchTerm);

  useEffect(() => {
    setSearchCondition(searchTerm);
  }, [searchTerm]);

  const handleSearchChange = (value: string) => {
    setSearchCondition(value);
    onSearch(value);
  };

  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        direction: 'rtl', // Set the direction to RTL if needed
        borderBottom: '1px solid #EEE',
        paddingBottom: 10,
        marginBottom: 15
      }}
    >
      {/* Title */}
      {title && (
        <Col xs={24} md={12}>
          <Title level={4} style={{ margin: 0, fontWeight: 'bold', color: '#313131' }}>
            {title}
          </Title>
        </Col>
      )}

      {/* Search and Buttons */}
      <Col xs={24} md={12}>
        <Space
          size="middle"
          align="center"
          style={{ width: '100%', justifyContent: 'flex-end' }}
        >
          {/* Search Input */}
          <Search
            placeholder={`البحث في ${title} ..`}
            allowClear
            value={searchCondition}
            onChange={(e) => handleSearchChange(e.target.value)}
            onSearch={handleSearchChange}
            style={{ width: 232, direction: 'rtl' }}
          />

          {/* Action Buttons */}
          <Tooltip placement="topLeft" title={'Download'}>
            <Button
              icon={<DownloadOutlined />}
              onClick={onDownload}
              loading={downloadLoading}
              className="p-button-text p-button-plain"
            />
          </Tooltip>

          {button &&
            button.map((btn, index) => (
              <Button
                key={index}
                type="primary"
                onClick={btn.onClick}
                icon={<i className="pi pi-plus" />}
                style={{
                  background: '#A2FB5D',
                  borderColor: '#333',
                  color: '#333',
                  fontWeight: 'bold',
                  direction: 'rtl',
                  minWidth: 180
                }}
              >
                {btn.label}
              </Button>
            ))}
        </Space>
      </Col>
    </Row>
  );
};

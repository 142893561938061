import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import { ConfigProvider } from 'antd';
import { theme } from './theme/theme';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found. Please check your index.html file.');
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider direction={'rtl'} componentSize={'middle'} theme={{
        token: {
          fontFamily:'IBM Plex Sans Arabic',
          colorPrimary: theme.colors.logoBlue,
          colorSuccess: theme.colors.primary,
        }
      }}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

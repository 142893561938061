import React, { useRef, useState } from 'react';

import './EditSupplier.scss';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../global';

import EditSupplier1 from './EditSupplier1';
import { useToast } from '../../../context/ToastContext';
import PageHeader from '../../../components/components/Header/PageHeader';

const EditSupplier = ({ token }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({});
  const [isFormOk, setIsFormOk] = useState(false);

  function handleِEditOnClick() {
    try {
      delete supplier.userId;
      delete supplier.transDateTime;
      axiosInstance
        .put('/Supplier', supplier)
        .then((res) => {
          toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل مورد بنجاح' });
          setTimeout(() => navigate('/warehouses/suppliers/'), 1500);
        })
        .catch((error) => {
          toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل مورد' });
        });
    } catch (errorEditSupplier) {
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل مورد' });
    }
  }

  return (
    <div className='screen'>
      <PageHeader
        icon={'/images/back-arrow.svg'}
        buttons={[]}
        headerTitle={'تعديل مورد '}
        iconOnClick={() => navigate(-1)}
      />
      <EditSupplier1 setCheck={setIsFormOk} supplier={supplier} setSupplier={setSupplier} />
      <div className='btn mb-5'>
        <Button className='btn__nextbtn' onClick={handleِEditOnClick} disabled={!isFormOk}>
          حفظ تعديل المورد
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSupplier);

import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Button, Checkbox, DatePicker, Input, InputNumber, Radio, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import { PickerMode } from 'rc-picker/lib/interface';
import { getFormat } from '../../../utils/Utils';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

interface FormFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  errors: FieldErrors;
  type:
    | 'text'
    | 'textarea'
    | 'dropdown'
    | 'calendar'
    | 'multi'
    | 'decimal'
    | 'toggle'
    | 'multi-select'
    | 'checkbox'
    | string;
  options?: any[];
  optionValue?: string;
  optionLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  timeOnly?: boolean;
  rows?: number;
  required?: boolean;
  calendarType?: 'single' | 'range';
  customStyle?: string;
  calenderPicker?: PickerMode;
  max?: number;
  loading?: boolean;
  customeToggle?: any[];
}

const CustomFormField: React.FC<FormFieldProps> = React.memo(
  ({
     name,
     label,
     control,
     errors,
     type,
     options = [],
     customeToggle = [],
     optionValue = 'id',
     optionLabel = 'name',
     placeholder = '',
     disabled = false,
     timeOnly = false,
     loading = false,
     rows = 3,
     required,
     calendarType = 'single',
     customStyle,
     max,
     calenderPicker
   }) => {
    const errorMessage = errors[name]?.message as string;
    const toggleOptions = [
      { label: 'نعم', value: true },
      { label: 'لا', value: false }
    ];


    return (
      <div className={`w-full flex flex-column gap-3 ${customStyle}`}>
        {type !== 'checkbox' && (
          <label style={{ fontSize: '1em', fontWeight: '700', color: '#313131', lineHeight: 'normal' }}>
            {label} {required && <span className="text-red-500">*</span>}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const [inputValue, setInputValue] = React.useState('');
            const handleAddInput = () => {
              if (inputValue.trim()) {
                const currentValue = Array.isArray(field.value) ? field.value : [];
                const updatedList = [...currentValue, inputValue.trim()];
                field.onChange(updatedList);
                setInputValue('');
              }
            };

            const removeInput = (indexToRemove: number) => {
              const currentValue = Array.isArray(field.value) ? field.value : [];
              const updatedList = currentValue.filter((_: string, index: number) => index !== indexToRemove);
              field.onChange(updatedList);
            };

            switch (type) {
              case 'text':
                return (
                  <>
                    <Input
                      {...field}
                      size="large"
                      status={errorMessage ? 'error' : ''}
                      placeholder={placeholder}
                      disabled={disabled}
                      value={field.value || ''}
                      autoComplete="off"
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'textarea':
                return (
                  <>
                    <TextArea {...field} disabled={disabled} autoComplete="off" rows={rows} placeholder={placeholder} />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'dropdown':
                return (
                  <>
                    <Select
                      showSearch
                      {...field}
                      placeholder={placeholder}
                      optionFilterProp={'label'}
                      disabled={disabled}
                      allowClear={true}
                      onChange={(value) => field.onChange(value)}
                      loading={loading}
                      options={options.map((option) => ({
                        label: option[optionLabel],
                        value: option[optionValue]
                      }))}
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'calendar':
                return (
                  <>
                    {calendarType === 'single' ? (
                      <DatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        format={getFormat(calenderPicker)}
                        disabled={disabled}
                        onChange={(date) => field.onChange(date)}
                        showTime={timeOnly}
                        picker={calenderPicker}
                      />
                    ) : (
                      <RangePicker
                        {...field}
                        value={
                          field.value && field.value.length === 2
                            ? [dayjs(field.value[0]), dayjs(field.value[1])]
                            : null
                        }
                        format="DD/MM/YYYY"
                        disabled={disabled}
                        picker={calenderPicker}
                        onChange={(dates) => field.onChange(dates)}
                        showTime={timeOnly}
                      />
                    )}
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'multi':
                return (
                  <>
                    {/* Input Field for Adding Items */}
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder={placeholder || 'Enter value'}
                      suffix={
                        <Button
                          type="link"
                          onClick={handleAddInput}
                          style={{ color: '#006599' }}
                          disabled={!inputValue.trim()}
                        >
                          اضافه
                        </Button>
                      }
                      disabled={disabled}
                    />

                    {/* Tag List */}
                    <div>
                      {Array.isArray(field.value) &&
                        field.value.map((dept: string, index: number) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() => removeInput(index)}
                            style={{
                              backgroundColor: '#FFF3E8',
                              border: '1px solid #FFE4CC',
                              borderRadius: '16px',
                              padding: '4px 12px',
                              marginBottom: '8px'
                            }}
                          >
                            {dept}
                          </Tag>
                        ))}
                    </div>

                    {/* Error Message */}
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'decimal':
                return (
                  <>
                    <InputNumber
                      {...field}
                      style={{ width: '100%' }}
                      disabled={disabled}
                      onChange={(value) => field.onChange(value)}
                      max={max}
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'toggle':
                const dynamicToggleOptions =
                  options.length > 0
                    ? options.map((option) => ({
                      label: option[optionLabel],
                      value: option[optionValue]
                    }))
                    : customeToggle
                      ? customeToggle
                      : toggleOptions;
                return (
                  <>
                    <Radio.Group
                      {...field}
                      disabled={disabled}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value !== undefined ? field.value : false}
                      options={dynamicToggleOptions}
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'multi-select':
                return (
                  <>
                    <Select
                      {...field}
                      mode="multiple"
                      placeholder={placeholder}
                      optionFilterProp={'label'}
                      disabled={disabled}
                      onChange={(value) => field.onChange(value)}
                      value={field.value || []}
                      loading={loading}
                      options={options.map((option) => ({
                        label: option[optionLabel],
                        value: option[optionValue]
                      }))}
                      showSearch
                      allowClear
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              case 'checkbox':
                return (
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value || false}
                      disabled={disabled}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      {label}
                    </Checkbox>
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
              default:
                return (
                  <>
                    <Input
                      {...field}
                      placeholder={placeholder}
                      disabled={disabled}
                      value={field.value || ''}
                      autoComplete="off"
                    />
                    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                  </>
                );
            }
          }}
        />
      </div>
    );
  }
);

export default CustomFormField;

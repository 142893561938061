import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function PointofSales() {
  const navigate = useNavigate();
  const columnsTab1 = [
    {
      header: 'منافذ البيع',
      field: 'name',
      isChip: false
    },
    {
      header: 'الموظف المسؤول',
      field: 'responsibleEmployeName',
      isChip: false
    },
    {
      header: 'الحالة',
      field: 'statusName',
      isChip: true
    },
    {
      header: 'المحافظة',
      field: 'governorateName',
      isChip: false
    },
    {
      header: 'ارقام التواصل',
      field: 'phone',
      isChip: false
    }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'منافذ البيع',
          tableHeader: 'قائمة منافذ البيع',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: '/PointOfSale/list',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'منافذ البيع',
          rowOnClick: (rowData) => {
            navigate('/Sales/pointOfSales/details', {
              state: { pointSalesId: rowData.id }
            });
          },
          headerButtons: [
            {
              label: 'إضافة منافذ بيع جديدة',
              onClick: () => {
                navigate('/Sales/pointOfSales/add');
              }
            }
          ]
        }
      ]}
      headerTitle={'المبيعات'}
      headerIcon={'../images/shoppingbag.svg'}
    />
  );
}

export default PointofSales;

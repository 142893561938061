import React, { useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import useGeoLocation from '../../../../hooks/useGeoLocation';
import useStatus from '../../../../hooks/useStatus';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useFetch from '../../../../hooks/useFetch';

const BranchForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const geoLocation = useGeoLocation(true);
  const isEditMode = watch('id');
  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const selectedArea = watch('areaId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('GeoLocation');
  const { data: cashBoxData, isLoading: isCashBoxLoading } = useFetch('/CashBox/dropdown');
  useEffect(() => {
    if (selectedCountry) {
      geoLocation.handleCountrySelect({ id: selectedCountry, name: selectedCountry });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) {
      geoLocation.handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
    }
  }, [selectedGovernorate]);

  useEffect(() => {
    if (selectedArea) {
      geoLocation.handleAreaSelect({ id: selectedArea, name: selectedArea });
    }
  }, [selectedArea]);

  return (
    <div className="flex flex-column gap-5">
      <CustomFormField
        name={'countryId'}
        label={'اختر الدولة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        required={true}
        options={geoLocation.countryApiState.state.data || []}
        placeholder="اختر الدولة"
      />
      <CustomFormField
        name="governorateId"
        label="اختر المحافظة"
        control={control}
        errors={errors}
        type="dropdown"
        options={geoLocation.governorateApiState.state.data || []}
        required={true}
        placeholder="اختر المحافظة"
        disabled={!selectedCountry}
      />
      <CustomFormField
        name="areaId"
        label="اختر المدينة"
        control={control}
        errors={errors}
        type="dropdown"
        options={geoLocation.areaApiState.state.data || []}
        required={true}
        placeholder="اختر المدينة"
        disabled={!selectedGovernorate}
      />

      <CustomFormField
        name="locationId"
        label="اختر المنطقه"
        control={control}
        errors={errors}
        type="dropdown"
        options={geoLocation.locationApiState.state.data || []}
        required={true}
        placeholder="اختر المدينة"
        disabled={!selectedGovernorate}
      />

      <CustomFormField
        name="cashBoxId"
        label="اختر الخزينة"
        control={control}
        errors={errors}
        type="dropdown"
        options={cashBoxData?.data || []}
        required={true}
        placeholder="اختر الخزينة"
        disabled={isCashBoxLoading}
      />

      <CustomFormField name={'code'} label={'الكود'} control={control} errors={errors} type={'text'} />

      <CustomFormField
        name={'name'}
        label={'اضف اسم الفرع الجديدة'}
        control={control}
        errors={errors}
        type={'text'}
        required={true}
        placeholder="اضف اسم الفرع الجديدة"
      />

      <CustomFormField
        name={'installmentCollectionCommission'}
        label={'عمولة تحصيل القسط'}
        control={control}
        errors={errors}
        type={'decimal'}
        required />

      <CustomFormField
        name={'repeatedInstallmentCollectionCommission'}
        label={'عمولة تحصيل القسط المتكرر'}
        control={control}
        errors={errors}
        type={'decimal'}
        required />

      <CustomFormField
        name={'address'}
        label={'ادخل العنوان'}
        control={control}
        errors={errors}
        type={'text'}
        required={true}
        placeholder="ادخل العنوان"
      />

      <CustomFormField
        name={'statusId'}
        label={'اختر حالة المنطقة'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default BranchForm;

import React, { useEffect } from 'react';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { useToast } from '../../../context/ToastContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import styles from '../AddEditPointOfSales/AddEditPOS.module.scss';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import POSDetailsTables from './POSDetailsTables';

const POSDetails = () => {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };
  const toast = useToast();
  const navigate = useNavigate();

  const location = useLocation();
  const { pointSalesId } = location.state || {};

  const fetchPOSsVisit = async () => {
    const response = await axiosInstance.get(`/PointOfSale/${pointSalesId}`);
    return response.data;
  };
  const { lazyApiCall, state } = useApiState({ apiCall: fetchPOSsVisit, isLazy: true });
  useEffect(() => {
    if (pointSalesId) {
      lazyApiCall();
    }
  }, [pointSalesId]);

  function showConfirmDeletePOS(e: any) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف منفذ البيع؟',
      header: 'تأكيد حذف منفذ البيع',
      accept: () => {
        handleDeletePOS();
      },
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold',
      reject: () => {
        // Logic for rejection, if needed
      },
      rejectLabel: 'إلغاء',
      draggable: false,
      resizable: false,
      tagKey: '1'
    });
  }

  function handleDeletePOS() {
    axiosInstance
      .delete(`/PointOfSale/${pointSalesId}`)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'تم حذف منفذ البيع', detail: '', life: 1500 });
        setTimeout(() => navigate('/Sales/PointofSales'), 1500);
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف منفذ البيع', detail: '', life: 1500 });
      });
  }

  return (
    <div className={styles.screen}>
      <div className={styles.detailsHeader}>
        <ConfirmDialog tagKey={'1'} />
        <div className={styles.detailsHeaderRght}>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='supplier-details__header1__right--text'>تفاصيل منفذ البيع "{state?.data?.data.name}"</span>
        </div>

        <div>
          <Button
            className='supplier-details__header1__left__btn'
            onClick={() => navigate(`/Sales/PointofSales/edit`, { state: { pointSalesId: pointSalesId } })}
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            تعديل بيانات منفذ البيع
          </Button>

          <Button
            className='supplier-details__header1__left__btn'
            onClick={showConfirmDeletePOS}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف منفذ البيع
          </Button>
        </div>
      </div>

      <div className={styles.detailsContainer}>
        <div className={styles.detailsCards}>
          <HeaderCardTS
            title={'مبيعات منفذ البيع'}
            num={'1400000'}
            imageUrl={'/images/coins.svg'}
            color={'#F9D8D4'}
            txtColor={'red'}
          />
          <HeaderCardTS
            title={'رصيد المنتجات داخل منفذ البيع'}
            num={'1500'}
            imageUrl={'/images/dropbox.svg'}
            color={'#6D54DE33'}
          />
        </div>
        <div className='customerdetails__content customerdetails__content--wrapper'>
          {/*=========================================== right content ================================================================= */}
          <div className='customerdetails__content__right'>
            <div className='customerdetails__content__right__customer__name'>{state?.data?.data?.name}</div>

            <div className={styles.detailsData}>
              <span className='primary__data--label'> كود منفذ البيع: </span>
              <span className='primary__data--link'>{state?.data?.data?.code}</span>
            </div>

            <Chip label={state?.data?.data?.statusName} />

            <span className={styles.detailsDataTitle}>
              بيانات منفذ البيع<img src='/images/2.svg'></img>
            </span>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>الموظف السؤول : </span>
              <span className='primary__data--text'>{state?.data?.data?.responsibleEmployeeName}</span>
            </div>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>المحافظة : </span>
              <span className='primary__data--text'>{state?.data?.data?.governorateName}</span>
            </div>

            <span className={styles.detailsDataTitle}>
              مواقع منفذ البيع<img src='/images/2.svg'></img>
            </span>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>المحافظة : </span>
              <span className='primary__data--text '>{state?.data?.data?.governorateName}</span>
            </div>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>الموقع : </span>
              <span className='primary__data--text flex flex-row gap-2'>
                {state?.data?.data?.address} <img src='/images/map1.svg'></img>
              </span>
            </div>
          </div>

          {/*=========================================== left content ================================================================= */}
          <div className='customerdetails__content__left'>
            <span className={styles.detailsDataTitle}>
              المخازن التابعة لمنفذ البيع <img src='/images/2.svg'></img>
            </span>
            <div className={styles.detailsData}>
              <span className='primary__data--label'>المخزن : </span>
              <span className='primary__data--text'>{state?.data?.data?.warehouseName}</span>
            </div>

            <span className={styles.detailsDataTitle}>
              بيانات التواصل<img src='/images/2.svg'></img>
            </span>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>رقم الهاتف المحمول : </span>
              <span className='primary__data--text flex flex-row gap-2'>
                {state?.data?.data?.mobile}
                <img src='/images/copy.svg'></img>
              </span>
            </div>

            <div className={styles.detailsData}>
              <span className='primary__data--label'>رقم الهاتف الارضى : </span>
              <span className='primary__data--text flex flex-row gap-2'>
                {state?.data?.data?.phone}
                <img src='/images/copy.svg'></img>
              </span>
            </div>

            <span className={styles.detailsDataTitle}>
              الملاحظات <img src='/images/2.svg'></img>
            </span>
            <span className='primary__data--text'>{state?.data?.data?.remarks}</span>
          </div>
        </div>
        <POSDetailsTables PosId={pointSalesId} />
      </div>
    </div>
  );
};

export default POSDetails;

import React from 'react';
import { Card, Image } from 'antd';
import type { CSSProperties } from 'react';

interface BodyCardProps {
  ImageUrl: string;
  ImageAlt: string;
  Description: string;
  color?: string;
}

const BodyCard: React.FC<BodyCardProps> = ({ ImageUrl, ImageAlt, Description, color }) => {
  const imageStyle: CSSProperties = {
    backgroundColor: color || '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    width: 150,
    height: 150,
    overflow: 'hidden'
  };

  const descriptionStyle: CSSProperties = {
    textAlign: 'center',
    color: '#000',
    fontSize: '1.5rem',
    fontWeight: 600
  };

  return (
    <Card
      style={{
        height: 500,
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        direction: 'rtl',
        border: '2px solid #f3f3f3',
        background: '#fff'
      }}
    >
      <div style={imageStyle}>
        <Image src={ImageUrl} alt={ImageAlt} preview={false} style={{ margin: 'auto' }} />
      </div>
      <div style={descriptionStyle}>{Description}</div>
    </Card>
  );
};

export default BodyCard;
